import * as React from "react";
import { RadioStyle } from './Radio.style';


interface Props{
    options?: string[][];
    id?: number | string;
    group: string;
    checked?: boolean;
    value: string;
    disabled?: boolean;
    onChange?: (e:string) => void;
    className?: string;
    children?: React.ReactNode;
}



const Radio:React.FC<Props> = (props) =>
{
    const change = (e:React.SyntheticEvent<HTMLInputElement>) =>
    {
        if(props.onChange)
        {            
            props.onChange(e.currentTarget.value);
        }
    }

    return (
        <RadioStyle key={"p" + props.id} className={props.className ? props.className : ""}>
                <label style={{opacity: props.disabled ? .5 : 1}} htmlFor={`g${props.group}_r${props.id}`}>
                        {props.children}
                </label>
                <input disabled={props.disabled ? true : false} id={`g${props.group}_r${props.id}`} type="radio" onChange={change}  onClick={change} value={props.value} checked={props.checked} name={`g${props.group}`} />
        </RadioStyle>
    );


}

export default Radio;