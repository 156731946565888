import styled from "styled-components";
import {SecondaryColor, TertiaryColor, BackgroundColor, PrimaryColor} from 'global.styles';


export const PanelStyle = styled.div`    
    background: white;
    transition: all .3s ease-in-out;
    .panel__content{
        background: white;
        color: black;
        overflow-y: auto;
        overflow-x: hidden;
        //-webkit-overflow-scrolling: auto;
        max-height: 0;
        text-align: left;
        transition: all .3s  ease-in-out;
        box-sizing: border-box;
        min-height: 0;
        > div:not(.no-border):not(:last-child){
            border-bottom: 1px solid rgba(0,0,0,.1);
        }
        
    }
    h2{
        box-sizing: border-box;
        margin: 0;
        padding: 20px 30px;
        font-size: 16px;
        text-align: left;
        span:first-child{
            opacity: .4;  
            min-width: 16px;
            margin-right: 13px;          
        }
        span{
           // text-transform: capitalize;
           pointer-events: none;
           user-select: none;
        }
        background: ${SecondaryColor};
        color: ${BackgroundColor};
        border-left: 2px solid transparent;
        border-bottom: 1px solid ${TertiaryColor};
        border-bottom: 1px solid ${TertiaryColor};
        cursor: pointer;
        display: flex;
        align-items: center;
        svg{
            opacity: .4;   
            fill: ${BackgroundColor};
            margin-left: -4px;
            margin-right: 17px;
            width: 16px;
            height: 16px;

        }
    }
    span{
        font-size: 15px;
    }
    .single-text{
        margin: 0;
        line-height: 16px;
        padding: 0px 0px;    
    }
    &.active{
        h2{
            border-left: 2px solid ${PrimaryColor};
            color: ${PrimaryColor};
            span{
                opacity: 1;         
            }
            svg{
                opacity: 1;
                fill: ${PrimaryColor};
            }
        }
        .panel__content{
            max-height: 1000px;
        }
    }
    .tabs{
        display:none;
    }
    h3{
        font-size: 1em;
    }
    @media(max-width: 769px){
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        /* max-height: 70px !important; */
        /* min-height: 70px !important; */
        top: 70px;
        background: transparent;
        &.active{
            z-index: 1;
            h2{
                background: ${PrimaryColor};
                color: white;
                svg{
                    fill: white !important;
                   
                }
            }
        }
        h2{
            width: 20vw;
            margin-top: -70px;
            span:nth-child(1){
                margin-left: auto;
                margin-right: auto;    
                min-width: auto;        
            }
            span:nth-child(2){
                display: none;
            }
            svg{
                margin-left: auto;                
                margin-right: auto;
            }

        }
        .panel_content{

        }
        .tabs{
            display:flex;
        }
    }

    .product-details{
        hr{
            border: 1px solid #E5E5E5;
        }
        > div:first-child > div:first-child hr{
            display: none;
        }
    }
`;