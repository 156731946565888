export class BaseApi
{
    private fetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>;

    constructor(fetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>)
    {
        this.fetch = fetch;
    }

    public httpGet(url: string): Promise<any>
    {
        return this.http("GET", url);
    }

    public httpPost(url: string, body?: any): Promise<any>
    {
        return this.http("POST", url, body);
    }

    public httpPut(url: string, body?: any): Promise<any>
    {
        return this.http("PUT", url, body);
    }

    public httpDelete(url: string): Promise<any>
    {
        return this.http("DELETE", url);
    }

    private http(method: string, url: string, body?: any): Promise<any>
    {
        if (this.fetch)
        {
            return (
                this.fetch(url, {
                    method,
                    headers: { "Accept": "application/json" },
                    body: body ? JSON.stringify(body) : undefined,
                }).then(res => res.ok ? res.json() : Promise.reject(res.status))
            );
        }

        return (
            fetch(url, {
                method,
                headers: { "Accept": "application/json" },
                body: body ? JSON.stringify(body) : undefined,
            }).then(res => res.ok ? res.json() : Promise.reject(res.status))
        );
    }
}