import * as React from "react";
import { RangeStyle } from "./Range.style";

interface Props
{
    start: number;
    total: number;
    title: string;
    step: number;
    value: number;
    disabled?: boolean;
    onChange: (e: number) => void;
};



const Slider = (props: Props) =>
{
    const [change, updateChange] = React.useState(props.value);
    const [moved, updateMove] = React.useState(false);
    const changeNumber = (e: React.ChangeEvent<HTMLInputElement>) => 
    {
        if (matchMedia('(hover:none)').matches) {
            // Device has touch
            updateChange(parseInt(e.target.value));            
        }
        else
        {
            //updateChange(parseInt(e.target.value));
            props.onChange(parseInt(e.target.value));
        }
        //props.onChange(parseInt(e.target.value));
    }
    React.useEffect(() => 
        {
            updateChange(props.value)
        }
    ,[props.value]);
    const percent = (change - props.start) / (props.total - props.start) * 100;
    const slideIt = (e: React.TouchEvent<HTMLInputElement>) =>
    {
        props.onChange(change);
    }
    const setIt = (e: React.MouseEvent<HTMLInputElement>) =>
    {
        props.onChange(change);
    }
    const iosFallback = () =>
    {
        let arr = [];
        for(let i = 0; i < props.total + 1; i++)
        {
            const num = i;
            arr.push(<div key={num} onTouchEnd={() => { 
                if(!moved)
                {
                    updateChange(num); 
                    props.onChange(num);    
                }                
            }}>{num}</div>);
        }
        return arr;
    }
    const onSlide = (e:React.TouchEvent) =>
    {
        const divElement:HTMLDivElement = e.touches[0].target as HTMLDivElement;
        const parent:HTMLDivElement = divElement.parentElement as HTMLDivElement;
        const width = parent.offsetLeft + parent.clientWidth;
        const p = Math.round((e.touches[0].clientX / width) * 100);
        const currentCount = Math.floor((props.total / 100) * p); 
        updateMove(true);       
        if(currentCount < 0){
            updateChange(0);
        }else if(currentCount > props.total)
        {
            updateChange(props.total);

        }else{
            updateChange(currentCount);
        }
    }
    const onTouchUp = (e:React.TouchEvent) =>
    {
        if(moved)
        {
            props.onChange(change);
        }
        updateMove(false);       
    }

    return (
        <RangeStyle className={`${props.disabled ? "disabled" : null}`}>
            <h3><span>{props.title}</span><span>{change}</span></h3>
            <div className="iosfix" onTouchMove={onSlide} onTouchEnd={onTouchUp}>
                {iosFallback()}
            </div>
            <div className="slider">
                <div className="slider-track" style={{ width: `${percent}%` }}></div>
                <div className="slider-thumb" style={{ left: `${percent}%`, marginLeft: `-${change > 10 ? 20 : (change * 2)}px` }}></div>
                <input type="range" min={props.start} defaultValue={change} step={props.step} onChange={changeNumber} max={props.total} 
                onTouchEnd={slideIt}
                onMouseUp={setIt}                
                />
            </div>
            <div>
                <span>{props.start}</span><span>{props.total}</span>
            </div>
        </RangeStyle>
    );
}

export default Slider;