import * as React from "react";
import styled from "styled-components";
import MenuDropDown from "../../elements/MenuDropDown";
import { Tokens } from "typings/translation";
import { Language } from "typings/repo";

interface Props{
    changeLanguage?: (e:Language) => void;
    languages: Language[];
    current: string;
    tokens: Tokens;
}

const StyledLanguage = styled.div`
    //position: absolute;

    top: 10px;
    right: 10px;

    > div{
        margin-top: 10px;
    }
    > p{
        text-align: center;
        padding: 0;
        margin: 0;
        color: rgba(255,255,255,.5);
        margin: 8px 0;
        font-size: .9em;
    }
`;

const LanguageChooser = (props: Props) =>
{
    const click = (lang:Language) =>
    {
        if(props.changeLanguage)
        {   
            props.changeLanguage(lang);
        }
    }

    const getCurrent = () =>
    {
        return props.languages.find(lang => lang.code === props.current)?.name;
    }

    return (
        <StyledLanguage> 
            <MenuDropDown className="white" icon="arrow-down" title={props.tokens.LANGUAGE}>
                {/* temporarily disable DE and NO languages */ }
                {props.languages.map(lang => (<p key={lang.code} onClick={() => click(lang)}>{lang.name}</p>))}
            </MenuDropDown>
            <p>{getCurrent()}</p>
        </StyledLanguage>
    );
}

export default LanguageChooser;