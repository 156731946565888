import * as React from "react";
import { Product } from 'typings/repo';
import { PartType } from 'typings/globals';
import Radio from "../../elements/Radio";
import { getImage } from 'utils/utils';

interface Props
{
    title: string;
    list: Product[];
    checkedId?: string;
    type: PartType;
    onSetPart: (type: PartType, partId?: string) => void;
    onShowPreview: (url?: string) => void;
    noProduct?: string;
}

const DetailList = (props: Props) =>
{
    return (
        <div >
            <hr />
            <strong style={{textTransform: "capitalize"}}>{props.title}</strong>
            {props.noProduct ? (<Radio group={`group-${props.type}`} onChange={() => props.onSetPart(props.type, undefined)} checked={props.checkedId === undefined} id={"wc-1"} key={"wc-1"} value="0">
                <div>
                    <h4 style={{ marginLeft: "70px", textTransform: "capitalize" }}>{props.noProduct}</h4>
                </div>
            </Radio>)
                : null}
            {props.list.map((product, index) =>
                <Radio group={`group-${props.type}`} onChange={() => props.onSetPart(props.type, product.id)} checked={props.checkedId === product.id} id={"wc" + index} key={"wc" + index} value={product.id}>
                    <img src={getImage(product.imageUrl, "THUMBNAIL")} onClick={() => props.onShowPreview(product.imageUrl)} width="60" key={"i" + index} height="60" alt="test" style={{ marginRight: "10px", minWidth: "60px" }} />
                    <div key={"d" + index} >
                        <h6 style={{color: " #D8DADD", lineHeight: "20px"}}>{product.id}</h6>
                        <h4 >{product.title}</h4>
                        <p style={{margin: 0, fontSize: "13px", lineHeight: "13px", marginTop: "5px", paddingRight: "5px"}}>{product.subtitle}</p>
                    </div>
                </Radio>)}
           
        </div>
    );
}

export default DetailList;