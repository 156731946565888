import img from "../icons/no-image.svg";
import img_da from "images/flexline_logo.png";
import img_no from "images/flexline_logo_no.png";
import img_de from "images/flexline_logo_de.png";
import img_en from "images/flexline_logo_en.png";
import img_se from "images/flexline_logo_se.png";
import img_ee from "images/flexline_logo_ee.png";

import img_hd_da from "images/flexline_hd_logo.png";
import img_hd_no from "images/flexline_hd_logo_no.png";
import img_hd_de from "images/flexline_hd_logo_de.png";
import img_hd_en from "images/flexline_hd_logo_en.png";
import img_hd_se from "images/flexline_hd_logo_se.png";
import img_hd_ee from "images/flexline_hd_logo_ee.png";

import img_black_da from "images/flexline_black_logo.png";
import img_black_no from "images/flexline_black_logo_no.png";
import img_black_de from "images/flexline_black_logo_de.png";
import img_black_en from "images/flexline_black_logo_en.png";
import img_black_se from "images/flexline_black_logo_se.png";
import img_black_ee from "images/flexline_black_logo_ee.png";

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);


export const getImage = (url: string | undefined, type: "LARGE" | "MEDIUM" | "THUMBNAIL" | "ORIGINAL") =>
{
  let newUrl = url;

  if (url)
  {
    const cleanUrl = url.split("?")[0];
    switch (type)
    {
      case "THUMBNAIL":
        return `${cleanUrl}?width=80&height=80&format=jpg&bgcolor=fff`;
      case "MEDIUM":
        return `${cleanUrl}?width=465&height=465&format=jpg`;
      case "LARGE":
        return `${cleanUrl}?width=1100&format=jpg`;
      case "ORIGINAL":
        return `${cleanUrl}`;
    }
  } else
  {
    newUrl = img;
  }
  return newUrl;
}

interface Config
{
  id: string;
  language: string;
    currency: string;
    project: string;
}

export const getUrlConfiguration = (initialang?: boolean): Config | null =>
{
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  let lang = urlParams.get('lang') ? urlParams.get('lang') : getLanguageByHostname();
  const curr = urlParams.get('currency');
    const lang_fallback = navigator.language.substring(0, 2).toLowerCase();
    const project = window.location.pathname;
    
  if (initialang)
  {
    const l = lang !== null ? lang : lang_fallback;
    return {
      language: l,
      id: "",
        currency: "",
        project: project === "/HeavyDuty" ? "hd" : "",
    }
  }
  if (id)
  {
    return {
      id: id,
      language: lang ? lang : lang_fallback,
      currency: curr ? curr : "",
      project: project === "/HeavyDuty" ? "hd" : "",
    }
    }
    if (project) {
        return {
            id: "id",
            language: lang ? lang : lang_fallback,
            currency: "",
            project: project === "/HeavyDuty" ? "hd" : "",
        }
    }
  return null;
}

export const getProductLine = () => {
  const project = window.location.pathname;
  let productLine = "";

  if (project.includes("/HeavyDuty")) {
    productLine = "hd";
  } else if (project.includes("/FLEXLINE-GREY")) {
    productLine = "grey";
  } else if (project.includes("/FLEXLINE-BLACK")) {
    productLine = "black";
  } else {
    productLine = "grey"; // Default value
  }
  return productLine;
}

export const GetProductLineURl = () => {
  const currentProductLine = getProductLine();
  if (currentProductLine === "hd") {
    return "/HeavyDuty"
  } else if (currentProductLine === "grey") {
    return "/FLEXLINE-GREY";
  } else if (currentProductLine === "black") {
    return "/FLEXLINE-BLACK"
  } else {
    return "/FLEXLINE-GREY";
  }
}

export const validateEmail = (val: string) =>
{
  if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(val))
  {
    return (true)
  }
  //alert("You have entered an invalid email address!")
  return (false)
}


export const getLogo = (langCode: string, productLine?: string) =>
{
  var url = "";

  if(productLine === "hd")
  {
    switch (langCode)
    {
      case "de":
        url = img_hd_de;
        break;
      case "en":
        url = img_hd_en;
        break;
      case "no":
        url =  img_hd_no;
        break;
      case "se":
        url =  img_hd_se;
        break;
        case "ee":
        url =  img_hd_ee;
        break;
      default:
        url =  img_hd_da;
        break;
    }
  }
  else if(productLine === "black"){
    switch (langCode)
    {
      case "de":
        url = img_black_de;
        break;
      case "en":
        url = img_black_en;
        break;
      case "no":
        url =  img_black_no;
        break;
      case "se":
        url =  img_black_se;
        break;
        case "ee":
        url =  img_black_ee;
        break;
      default:
        url =  img_black_da;
        break;
    }
  }
  else{
    switch (langCode)
    {
      case "de":
        url =  img_de;
        break;
      case "en":
        url =  img_en;
        break;
      case "no":
        url =  img_no;
        break;
      case "se":
        url =  img_se;
        break;
        case "ee":
          url =  img_ee;
          break;
      default:
        url =  img_da;
        break;
    }
  }

  return url;
}

const getLanguageByHostname = (): string =>
{
  const hostname = window.location.hostname;
  const domainEnding = hostname.split('.').pop();
  switch (domainEnding)
  {
    case "se":
      return "se";
    case "de":
      return "de";
    case "com":
      return "en";
    case "no":
      return "no";
    default:
      return "da";
  }
}

export const isMobile = ():boolean => {
    return window.innerWidth < 769 ? true : false;
}
 
//export const siteUrl = "https://localhost:44371";
export const siteUrl = "";
export const shareUrl = window.location.protocol + "//" + window.location.host;

export const DEBUG_MODE = false;