import { BaseApi } from "./BaseApi";
import { StorageTemplate } from "../typings/configuration"

export class ConfigurationApi
{
    private httpClient: BaseApi;
    
    constructor(httpClient: BaseApi)
    {
        this.httpClient = httpClient;
    }

    public loadConfiguration(id: string): Promise<StorageTemplate> {
        return this.httpClient.httpGet('/api/configuration/load/' + id);
    }

    public saveConfiguration(configuration: StorageTemplate): Promise<string> {
        return this.httpClient.httpPost('/api/configuration/save/', configuration);
    }
}

