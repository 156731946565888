import ReactGA from "react-ga";

export const UA_ID:string = "UA-12900889-4"; 

type CategoryType = "FRONTPAGE" | "STEP" | "NAVIGATION" | "COOKIE" | "CART";


export const TrackEvent = (category:CategoryType, action:string, label?:string) => {
    const obj = {
        category: category,
        action: action
    }
    if(label){
        const obj2 = {
            category: category,
            action: action,
            label: label
        }
        ReactGA.event(obj2);
        //console.log(obj2);
    }else{
        ReactGA.event(obj);
        //console.log(obj);

    }

  };