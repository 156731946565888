import * as React from "react";
import styled from "styled-components";
import { SecondaryBackgroundColor } from "../../../global.styles";
import { Tokens } from "typings/translation";
import { ConfigurationState } from 'typings/configuration';
import { CAMERAORIENTATION } from 'typings/globals';
import { setCamera } from 'state/cart/actions';
import { useDispatch } from 'react-redux';

const StyledPan = styled.div`
    background: ${SecondaryBackgroundColor};
    background: lightgrey;
    display: flex;
    border-radius: 20px;
    padding: 5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    z-index: 1;
    > button{
        outline: none;
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 5px 14px;
        border-radius: 20px;
        transition: background .2s linear;
        &.active{
            background: white;
        }
        &:disabled{
            opacity: .5;
            cursor: no-drop;
        }
    }    
    @media(max-width: 769px){
        left: 10px;
        bottom: 10px;
        transform: none;
    }
`;

interface Props
{
    tokens: Tokens;
    configuration: ConfigurationState;
    currentCamera: string;
}

const Pan = (props: Props) =>
{
    const dispatch = useDispatch();

    const updateView = (e: CAMERAORIENTATION) =>
    {
        dispatch(setCamera(e));
    }
    
    return <StyledPan className="pan-component">
        <button className={props.currentCamera === "LEFT" ? "active" : ""} disabled={props.configuration.left.length === 0} onClick={() => updateView("LEFT")}>{props.tokens.PAN_LEFT}</button>
        <button className={props.currentCamera === "OVERVIEW" ? "active" : ""} disabled={!props.configuration.corner ? true : false} onClick={() => updateView("OVERVIEW")}>{props.tokens.PAN_OVERVIEW}</button>
        <button className={props.currentCamera === "RIGHT" ? "active" : ""} disabled={props.configuration.right.length === 0} onClick={() => updateView("RIGHT")}>{props.tokens.PAN_RIGHT}</button>
    </StyledPan>;
}

export default Pan;
