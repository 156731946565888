import styled from "styled-components";

export const ConfiguratorStyle = styled.div`

position: relative;
/*&:before{
    content: "";
    display: block;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.1) 100%);
    height: 8px;
    width: 100%;
    position: absolute;
    z-index: 1;
}*/

img{
    max-width: 100%;
}
#data{
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 0px;
    textarea{
        height: 134px;
        width: 211px;
    }
}

canvas {
    @media(min-width: 770px) {
        width: calc(100vw - 300px) !important;
        height: calc(100vh - 70px) !important;

    }    
}

canvas {
    @media(max-width:  769px) {
        width: 100% !important;
        height: 100% !important;
    }
}

&.toggled{
    left: -300px;
    overflow: hidden;
    width: calc(100% + 300px);

    @media(min-width:  769px){
        #__ReactUnityWebGL_1__{
            width: calc(100vw) !important;
        }
    }
}


.notification{
    position: absolute;
    top: 30px;
    background: rgba(255,255,255,.7);
    left: 30px;
    width: 200px;
    height: 100px;
    z-index: 2;
    animation: showNotification .5s both;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 20px;
    box-sizing: border-box;

    > button{
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 5px;
        svg{
            margin-right: auto;
            width: 8px;
            height: 8px;
        }
    }    
    svg{
        stroke: rgba(255,255,255,.11);
        width: 12px !important;
        height: 12px !important;
        stroke-width: 5px;
    }

    @media(max-width: 769px){
        span.notify--desktop{
            display: none;        
        }   
    }
    @media(min-width: 769px){
        span.notify--mobile{
            display: none;
        }   
        font-size: 14px;
    }
}

@keyframes showNotification{
    0%{
        opacity: 0;
        transform: scale(.5);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}

#__ReactUnityWebGL_1__{
    animation: showConfigurator .5s both;
    animation-delay: .5s;
}
@keyframes showConfigurator{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
`;



export const HeavyLogo = styled.img`
    position: absolute;
    left: 56px;
    top: 17px;
    z-index: 1;
    height: 50px;

    @media(max-width: 768px){
        left: 9px;
        top: 38px;
        height: 30px;
    }
`;