import { Action } from "redux"

export interface UserState {
    firmname: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    comment: string;

    seller: string;
    dealer: string;
    dealerFirmname: string;
    dealerDepartment: string;
    dealerEmail: string;
    dealerPhone: string;
    dealerAddress: string;
    dealerZip: string;
    dealerCity: string;
    dealerCountry: string;
    dealerComment: string;

    delivery: string;
    order: string;
}

export interface CustomAction extends Action {
    payload: any;
}


const initialUser: UserState = {
    firmname: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    zip: "",
    city: "",
    country: "",
    comment: "",
    seller: "",
    dealer: "",

    dealerFirmname: "",
    dealerDepartment: "",
    dealerEmail: "",
    dealerPhone: "",
    dealerAddress: "",
    dealerZip: "",
    dealerCity: "",
    dealerCountry: "",
    dealerComment: "",

    delivery: "",
    order: "",
}

const userReducer = (state = initialUser, action: CustomAction):UserState =>
{
    switch(action.type){
        case "UPDATE_USER":
            //console.log(action.payload);
            //const update = state[action.payload.field];
            switch(action.payload.field){
                case "firmname":
                    return {...state, firmname: action.payload.value};
                case "firstname":
                    return {...state, firstname: action.payload.value};
                case "lastname":
                    return {...state, lastname: action.payload.value};
                case "email":
                    return {...state, email: action.payload.value};
                case "phone":
                    return {...state, phone: action.payload.value};
                case "address":
                    return {...state, address: action.payload.value};
                case "zip":
                    return {...state, zip: action.payload.value};
                case "city":
                    return {...state, city: action.payload.value};
                case "country":
                    return {...state, country: action.payload.value};
                case "comment":
                    return {...state, comment: action.payload.value};

                case "seller":
                    return {...state, seller: action.payload.value};
                case "dealer":
                    return {...state, dealer: action.payload.value};
                case "dealerFirmname":
                    return {...state, dealerFirmname: action.payload.value};
                case "dealerDepartment":
                    return {...state, dealerDepartment: action.payload.value};
                case "dealerEmail":
                    return {...state, dealerEmail: action.payload.value};
                case "dealerPhone":
                    return {...state, dealerPhone: action.payload.value};
                case "dealerAddress":
                    return {...state, dealerAddress: action.payload.value};
                case "dealerZip":
                    return {...state, dealerZip: action.payload.value};
                case "dealerCity":
                    return {...state, dealerCity: action.payload.value};
                case "dealerCountry":
                    return {...state, dealerCountry: action.payload.value};                    
                case "dealerComment":
                    return {...state, dealerComment: action.payload.value};
                case "delivery":
                    return {...state, delivery: action.payload.value};
                case "order":
                    return {...state, order: action.payload.value};    
            }            
            return {...state, firstname: action.payload.value};
        case "CLEAR_USER":
            return { ...state, ...initialUser };        
        default:
            return state;

    }
}

export default userReducer;