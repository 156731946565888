import styled from "styled-components";


export const StyledLogo = styled.div`
    user-select: none;
    h5{
        color: #FCB131;
        font-size: 25px;
        font-weight: bold;
        letter-spacing: 3px;
        margin: 0;
        margin-top: 17px;
        margin-left: 30px;  
    }

    h6{
        color: #000;
        font-size: 7px;
        font-weight: bold;
        letter-spacing: 1px;
        margin: 0;
        margin-left: 29px;
        text-transform: uppercase;
    }

    p{
        margin: 0;
        font-size: 13px;
    }
    strong{
        font-size: 26px;
    }

    &.large{
        h5{
            font-size: 7em;
        }
        h6{
            font-size: 1.5em;
        }
        @media(max-width: 562px){
            h5{
                font-size: 3em;
            }
            h6{
                font-size: 1em;
            }
        }
        img{
            width: 40vh;
        }
    }

    &.white{
        h6{
            color: white;
        }
    }
    &:not(.large){
        position: relative;
        height: 70px;
        img{
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            /* top: 0; */
            /* bottom: 0; */
            padding: 10px;
            box-sizing: border-box;
        }
    }
`;  