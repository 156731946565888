

export const updateUser = (value:string, field:string) => {
    return {
        type: "UPDATE_USER",
        payload: {value, field}
    }
}


export const clearCartUser = () =>
{
    return {
        type: "CLEAR_USER"
    } 
}