import * as React from "react";
import styled from "styled-components";
import Icon from "../../elements/Icon";
import { Tokens } from "typings/translation";

interface Props{
    width: number;
    width2?: number;
    height: number;
    depth: number;
    tokens: Tokens;
}

const StyledSize = styled.div`
    position: absolute; 
    text-align: right;
    right: 20px;
    top: 15px;
    z-index: 1;
    p{
        font-size: 14px;
        margin: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        //text-shadow: 0px 2px rgba(0,0,0,.4);
        color: black;
        svg{
            width: 16px;
            height: 16px;
            margin-left: 5px;
            fill: black;
        }
        &.horizontal svg{
            transform: rotate(90deg);
        }
        &.diagonal svg{
            transform: rotate(45deg);
        }
    }
    @media(max-width: 769px){

        right: 5px;
        //top: 15px;
        //bottom: auto;
        position: absolute !important;
        p{
            font-size: 12px;
            margin: 5px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    @media(max-width: 769px) {
        p{
            text-shadow: 0px 1px 1px rgba(255,255,255,.5);
            color: black;
            font-weight: 700;            
        }
        svg{
            fill: black !important;
        }
        
        display: flex;
        left: 0;
        width: 100%
        > p:first-child{
            margin-left: auto;
        }
        > p:last-child{
            margin-right: auto;
        }
        p.vertical{
            border-left: 1px solid rgba(0,0,0,.9);
            border-right: 1px solid rgba(0,0,0,.9);
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 0;
            margin-right: 0;
        }
        p{
            margin-top: 0 !important;
            margin-bottom: 0;
        }
        svg{
            display: none;
        }
    }
`;

const Size = (props:Props) =>
{
    return <StyledSize className="size-component">
        <p className="horizontal">{props.tokens.WIDTH_SHORT}&nbsp;{props.width}&nbsp;{props.width2 !== 0 ? "x " + props.width2 + " " : ""}{props.tokens.UNITS}<Icon name="double-arrows" /></p>
        <p className="vertical">{props.tokens.HEIGHT_SHORT}&nbsp;{props.height}&nbsp;{props.tokens.UNITS}<Icon name="double-arrows" /></p>
        <p className="diagonal">{props.tokens.DEPTH_SHORT}&nbsp;{props.depth}&nbsp;{props.tokens.UNITS}<Icon name="double-arrows" /></p>
    </StyledSize>;
}

export default Size;