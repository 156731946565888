import * as React from "react";
import { CartItemHeader, CartItemStyle } from "../Product.style";
import Content from "../Content";
import { getConfigurationProducts } from "helpers/configurationHelper";
import { Tokens } from "typings/translation";
import { getImage } from "utils/utils";
import {
  getItemsCount,
  getCurrency,
  getPrice,
  getItemPrice,
} from "helpers/priceHelper";
import { getProduct } from "state/repo";
import { useDispatch } from "react-redux";
import { showPreview } from "state/cart/actions";
import { CartState } from "state/cart";
import { RepoState, Size, Dictionary, Product } from "typings/repo";
import { ConfigurationState } from "typings/configuration";

interface Props {
  cart: CartState;
  repo: RepoState;
  configuration: ConfigurationState;
  activeTab: number;
  activeMenuTab: number;
  tokens: Tokens;
  togglePageContent: string;
  size: Size;
}

const CartPage: React.FC<Props> = (props) => {
  const {
    tokens,
    cart,
    configuration,
    repo,
    activeTab,
    activeMenuTab,
    togglePageContent,
    size,
  } = props;
  const products = getConfigurationProducts(configuration, repo);
  const dispatch = useDispatch();
  const [partslist, togglePartsList] = React.useState(false);
  const renderImage = () => {
    const imgString = `data:image/png;base64, ${cart.configimg}`;
    return (
      <img
        className="cart-config-image"
        src={imgString}
        alt={tokens.YOUR_FLEXLINE}
        width="500"
      />
    );
  };

  const renderTotalPrice = () => {
    return (
      <CartItemStyle>
        <div className="cart-total-amount">
          <span>{tokens.TOTAL_EX_MVA}</span>
          <strong>
            {getCurrency(cart.currency, repo).replace(
              "{0}",
              getPrice(
                configuration,
                repo,
                cart.currency,
                repo.langCode,
                cart.accessories,
                cart.additional
              )
            )}
          </strong>
        </div>
      </CartItemStyle>
    );
  };

  const buildCartItem = (
    product: Product,
    price: string,
    amount: number,
    keyPrefix: string,
    key: string
  ) => {
    if (product) {
      return (
        <CartItemStyle key={keyPrefix + key}>
          <img
            src={getImage(product.imageUrl, "THUMBNAIL")}
            width="80"
            key={"i" + key}
            height="80"
            alt="test"
            style={{
              marginRight: "10px",
              cursor: "pointer",
              minWidth: "80px",
              minHeight: "80px",
            }}
            onClick={() =>
              dispatch(showPreview(getImage(product.imageUrl, "MEDIUM")))
            }
          />
          <div className="desc">
            <h6>{product.id}</h6>
            <h4>{product.title}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: product.subtitle ? product.subtitle : "",
              }}
            >
              {}
            </p>
          </div>
          <div className="amount">{amount}</div>
          <div className="price">{price}</div>
        </CartItemStyle>
      );
    }
  };

  const renderCartLines = (
    keyPrefix: string,
    elements?: Dictionary<number>
  ) => {
    if (elements) {
      const productArray = Object.keys(elements).map((key) => {
        const product = getProduct(repo, key);
        if (product && products[key] !== 0) {
          const amount = elements[key];
          const price = getItemPrice(
            product,
            cart.currency,
            repo.langCode,
            amount
          );
          return { product, key, price, amount };
        }
        return null;
      });

      // Sort the array alphabetic based on product.title
      productArray.sort((a, b) => {
        if (a && b && a.product.id && b.product.id) {
          const idA = a.product.id.toLowerCase();
          const idB = b.product.id.toLowerCase();
          if (idA < idB) return -1;
          if (idA > idB) return 1;
          return 0;
        }
        return 0;
      });

      // Mapping sorted array
      return (
        <div style={{ padding: "0 25px" }}>
          {productArray.map((item) => {
            if (item) {
              return buildCartItem(
                item.product,
                item.price,
                item.amount,
                keyPrefix,
                item.key
              );
            }
            return null;
          })}
        </div>
      );
    }
  };

  const renderCartPage = () => {
    if (cart.page === "CART" && togglePageContent !== "CART") {
      return (
        <Content
          size={"large"}
          tabbed={activeTab === 1 || activeMenuTab === 1 ? true : false}
        >
          {renderImage()}
          <CartItemHeader>{tokens.YOUR_CONFIGURATION}</CartItemHeader>
          <div
            className="toggle-btn"
            onClick={() => togglePartsList(!partslist)}
          >
            <span>
              {partslist ? tokens.HIDE_PARTS_LIST : tokens.SHOW_PARTS_LIST}
            </span>
          </div>
          {partslist ? renderCartLines("a", products) : null}
          {cart.accessories && Object.keys(cart.accessories).length > 0 ? (
            <CartItemHeader>{tokens.ACCESSORIES}</CartItemHeader>
          ) : null}
          {renderCartLines("b", cart.accessories)}
          {cart.additional && Object.keys(cart.additional).length > 0 ? (
            <CartItemHeader>{tokens.ADDITIONAL}</CartItemHeader>
          ) : null}
          {renderCartLines("c", cart.additional)}
          {renderTotalPrice()}
        </Content>
      );
    }
  };

  return <>{renderCartPage()}</>;
};

export default CartPage;
