import * as React from "react";
import { StyledContent } from "./Content.style";
export type CONTENT_SIZE = "large" | "medium" | "small" | void;

interface Props
{
    size?: CONTENT_SIZE;
    tabbed?: boolean;
    style?: React.CSSProperties;
    className?: string;
    children?: React.ReactNode;
}

const Content: React.FC<Props> = (props) =>
{
    return (
        <StyledContent className={`${props.size ? props.size : ""} ${props.className ? props.className : ""} ${props.tabbed ? "tabbed" : ""}`} style={props.style}>
            {props.children}
        </StyledContent>
    );
}

export default Content;