import { BaseApi } from "./BaseApi";

export class MailApi
{
    private httpClient: BaseApi;
    
    constructor(httpClient: BaseApi)
    {
        this.httpClient = httpClient;
    }

    public sendMail(obj: any): Promise<string> {
        return this.httpClient.httpPost(`/Mail/SendMail`, obj);
    }
}

