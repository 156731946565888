import React, { useEffect } from "react";
import IconButton from 'components/elements/IconButton';

interface Props
{
    warningMessage: string;
    condition: boolean;
}

const Warning : React.FC<Props> = (props) =>
{
    const [shouldShow, setShouldShow] = React.useState(false);

    useEffect(() => {
        if (!shouldShow && props.condition)
        {
            setShouldShow(true)
        }
    },[props.condition]);

    const renderWarning = () =>
    {
        if (shouldShow)
        {
            return (<div className="constraint-notification">
                    <p>{props.warningMessage}</p>
                    <IconButton onClick={() => setShouldShow(false)} className="close" icon="close" />
                </div>);
        }
        return null;
    }

    return renderWarning();
}

export default Warning;