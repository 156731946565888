import styled from "styled-components";
import { SecondaryColor } from 'global.styles';

export const StyledList = styled.div`
padding-top: 10px;
position: relative;
select{
    padding: 10px 10px;
    display: block;
    width: 100%;
    outline: none;
    border: 1px solid #84888C;
    padding-right: 30px;
    cursor: pointer;
    background-clip: padding-box !important;
    box-shadow: none;
    background: white;
    option{
        font-family: 'Montserrat', sans-serif;
        font-size: 11px;
        /* prevent zoom on ios input fields*/
        
    }
    //prevent ios zoom
    @media(max-width: 769px) and (hover: none){
        font-size: 16px !important;
    }

}
label{
    margin-bottom: 5px;     
    display: block; 
    span{
        color: red;
        margin-left: 5px;
    }
}
&:after{
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -5px;
    border-bottom: 2px solid ${SecondaryColor};
    border-right: 2px solid  ${SecondaryColor};
    transform: rotate(45deg);
    pointer-events: none;
}
&:before{
    content: "";
    width: 24px;
    height: 20px;
    display: block;
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -6px;
    background: linear-gradient(90deg, transparent 0%, white 37%);
    pointer-events: none;
    @media(hover: none){
        background: white;
    }
}
&.no-border{
    select{
        border: none;
        background: transparent;
    }
}
&.titled{
    &:before{
        top: 100%;
        margin-top: -28px;
    }
    &:after{
        top: 100%;
        margin-top: -28px;
    }
}
&.white{
    select{
        color: white;
    }
    select:focus{
        color: black;
    }
    &:after{
        border-bottom-color: white;
        border-right-color: white;
    }

}

@media(max-width: 769px){
    &:before{
        display: none;
    }
}

select[disabled]:hover + .message{
    display: flex;
}

.message{
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg,rgba(255,255,255,.1) 7%,rgba(255,255,255,.95) 50%);
    //padding: 10px;
    z-index: 1;
    display: none;
    font-size: 14px;
    user-select: none;
    animation: showM .2s  linear;
    pointer-events: none;
    top: 0;
    align-items: flex-end;
    width: 100%;
    color: red;
    box-sizing: border-box;
}   

@media(max-width: 768px){
    .message{
        display: flex;        
        padding-bottom: 10px;
    }
}

@keyframes showM{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
`;