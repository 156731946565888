import * as React from "react";
import Icon, { ICON_TYPE } from "../elements/Icon";
import { StyledMenu } from "./MenuDropDown.style";

type alignment = "left" | "right" | void;

interface Props{
    title?: string;
    icon?: ICON_TYPE;
    align?: alignment;
    className?: string;
    children?: React.ReactNode;
}




const MenuDropDown:React.FC<Props> = (props) =>
{

    const [showMenu, toggleMenu] = React.useState(false);

    const showHideMenu = (e:MouseEvent) =>
    {
        toggleMenu(false);
    }

    React.useEffect(() =>
    {
        if(showMenu)
        {
            window.addEventListener("click",showHideMenu);        
        }
        return () =>
        {
            window.removeEventListener("click",showHideMenu);
        }
    },[showMenu]);

    return (
        <StyledMenu className={`${props.className ? props.className : ""} ${props.align ? props.align : ""}`}>
            <h4 onClick={() => toggleMenu(!showMenu)}>{props.title ? <span style={{marginRight: props.icon ? "5px" : "0px"}}>{props.title}</span> : null } {props.icon ? <Icon name={props.icon} /> : null } </h4>
            {showMenu ? <div>
                {props.children}
            </div> : null }
        </StyledMenu>
    );
}

export default MenuDropDown