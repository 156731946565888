import styled from "styled-components";
import { PrimaryColor, BorderColor } from 'global.styles';


export const RangeStyle = styled.div`
padding: 20px 25px;
transition: opacity .2s linear;
h3{ 
    display: flex;
    font-weight: bold;
    margin: 0;
    margin-bottom: 5px;
    span:nth-child(2){
        margin-left: auto;
        color: ${PrimaryColor};
    }
}
div > input{
    width: 100%;
    opacity: 1;
    cursor: pointer;
    &:before{
        content: "";
        display: none;
        background: ${PrimaryColor};
        border-radius: 30px;            
        width: 20px;
        height: 20px;
        
    }
    opacity: 0;

}
.slider{
    &:after{
        content: "";
        display: block;
        height: 3px;
        width: inherit;
        background: ${BorderColor};
        width: 100%;
        margin-top: 9px;
        position: absolute;
        pointer-events: none;
    }
}
.slider-track{
    position: absolute;
    background: ${PrimaryColor};
    width: 71%;
    height: 3px;
    pointer-events: none;
    margin-top: 9px;
    z-index: 2;
}
.slider .slider-thumb{
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: -10px;
    background: ${PrimaryColor};
    pointer-events: none;
    z-index: 2;
}
> div{
    display: flex;
    position: relative;   
    span{
        color: ${BorderColor};
    }    
    span:nth-child(2){
        margin-left: auto;
    }
}
&.disabled{
    opacity: .3;
    pointer-events: none;
}

.iosfix{
    display: none;

    width: 100%;
    box-sizing: border-box;
    transform: translateY(100%);
    z-index: 3;
    height: 40px;
    margin-top: -40px;  
    opacity: 0;
    > div{
        border: 1px solid teal;
        flex: 1;
    }
}

@supports (-webkit-touch-callout: none) {
    .iosfix{
        display: flex;   
    }
}
`;