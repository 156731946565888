import * as React from "react";
import {StyledLoader} from "./Preloader.style";
import Loader from "../../elements/Loader";
import { getLogo } from 'utils/utils';

interface Props{
    title?: string;
    active?: boolean;
    isdone?: boolean;
    langCode: string;
    productLine?: string;
}

const Preloader = (props:Props) =>
{
    const [done, updateDone] = React.useState(false);
    if(props.isdone){
        setTimeout(() => updateDone(true), 500);
    }
    
    return (
    <StyledLoader style={{display: (done ? "none" : "block")}} className={props.active ? "active" : "in-active"}>
        <div>
            {/*{props.title}<br/>*/}
            <img src={getLogo(props.langCode, props.productLine)} width={"90%"} style={{maxWidth: "300px", marginBottom: "30px"}} />
            <Loader />
        </div>
    </StyledLoader>   
    );
    
}


export default Preloader;