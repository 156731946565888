import { Action } from "redux";
import { PAGES } from "../../typings/cart";
import { Product, Dictionary, ProductType, ProductLine } from '../../typings/repo';
import { Module } from 'typings/configuration';
import uuidv1  from "uuid/v1";
import { CAMERAORIENTATION } from 'typings/globals';
import { DEBUG_MODE } from "utils/utils";

export interface SizeConstraint {
    width: number;
    depth: number;
}
export type StatusType =  "NONE" | "SCREENSHOT" | "STORAGE_ID" | "EMAIL" | "SUCCESS";
export interface CartState
{
    language: string;
    currency: string;
    page: PAGES;
    notified: boolean;
    notified_module: boolean,
    notified_module_dismissed: boolean;
    notified_sizes: boolean,
    notified_sizes_dismissed: boolean;
    product?: Module;
    preview?: string;
    sidebar_preview?: Product;
    configimg: string;
    preloader: boolean;
    accessories?: Dictionary<number>;
    additional?: Dictionary<number>;
    storageId?: string;
    id: string;
    constraint?:  SizeConstraint;
    camera: CAMERAORIENTATION;
    status: StatusType;
    productType?: ProductType;
    productLine?: ProductLine | "";
}

interface CustomAction extends Action
{
    payload: string;
    currency_code: string;
    sidebar: Product;
    id: string;
    amount: number;
    item: Module;
    accessories?: Dictionary<number>;
    additional?: Dictionary<number>;
    show: boolean;
    dismissed: boolean;
    constraint: SizeConstraint;
    status: StatusType;
    productType?: ProductType;
    productLine?: ProductLine;
}

const initialCart: CartState = {
    language: "",
    currency: "",
    page: "SIZES",
    notified: false,
    notified_module: false,
    notified_module_dismissed: false,
    notified_sizes: true,
    notified_sizes_dismissed: false,
    configimg: "",
    preloader: !DEBUG_MODE, //dont show preloader when debug is toggled   
    id: uuidv1(),
    camera: "OVERVIEW",
    status: "NONE",
    productLine: ""
}

const cartReducer = (state = initialCart, action: CustomAction): CartState =>
{
    switch (action.type)
    {
        case "FOCUS_SIDEBAR_MODULE":
            return { ...state, product: action.item };
        case "FOCUS_PRODUCT":
            return { ...state };
        case "SHOW_PREVIEW":
            return { ...state, preview: action.payload };
        case "SHOW_SIDEBAR_PREVIEW":
            return { ...state, sidebar_preview: action.sidebar, productType: action.productType };
        case "UPDATE_SIDEBAR_PREVIEW":
            return { ...state, sidebar_preview: action.sidebar};
        case "CLOSE_SIDEBAR_PREVIEW":
            return { ...state, sidebar_preview: undefined };
        case "CLOSE_PREVIEW":
            return { ...state, preview: undefined };
        case "CLOSE_PRELOADER":
            return { ...state, preloader: false };
        case "UN_FOCUS_PRODUCT":
            return { ...state, product: undefined };
        case "CHANGE_CURRENCY":
            return { ...state, currency: action.payload };
        case "CHANGE_PAGE":
            return { ...state, page: action.payload as PAGES };
        case "CHANGE_PAGE_HIDE_NOTIFICATION":
            return { ...state, page: action.payload as PAGES, notified: true };
        case "CHANGE_NOTIFICATION_MODULE":
            return { ...state, page: action.payload as PAGES, notified_module: action.show, notified_module_dismissed: action.dismissed};            
        case "CHANGE_NOTIFICATION_SIZES":
            return { ...state, page: action.payload as PAGES, notified_sizes: action.show, notified_sizes_dismissed: action.dismissed};            
        case "ADD_CART_IMAGE":
            return { ...state, configimg: action.payload };            
        case "SET_CAMERA":
            return { ...state, camera: action.payload as CAMERAORIENTATION };
        case "SET_STATUS":
            return { ...state, status: action.status }            
        case "UPDATE_ACCESSORY":
            let access = state.accessories;
            if (access)
            {                
                if(access[action.id] && action.amount === 0)
                {
                    delete access[action.id];
                }else{
                    access[action.id] = action.amount;
                }

            } else
            {
                access = {};
                access[action.id] = action.amount;
            }
            
            return { ...state, accessories: access };
        case "UPDATE_ADDITIONAL":
            let additional = state.additional;
            if (additional)
            {
                if(additional[action.id] && action.amount === 0)
                {
                    delete additional[action.id];
                }else{
                    additional[action.id] = action.amount;
                }
            } else
            {
                additional = {};
                additional[action.id] = action.amount;
            }
            return { ...state, additional: additional };
        case "SET_ACCESSORY_ADDITIONAL":
            return { ...state, additional: action.additional, accessories: action.accessories };
        case "SAVE_CONFIGURATION":
            return { ...state, storageId: action.id };
        case "SET_CONSTRAINT":
            return { ...state, constraint: action.constraint };       
        case "SET_PRODUCT_LINE":
            return { ...state, productLine: action.productLine };            
        default:
            return state;
    }

}

export default cartReducer;
