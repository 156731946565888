import * as React from "react";
import { StyledList } from "./DropDown.style";

export interface Option{
    text: string;
    value: string;
}

interface Props{
    className?: string;
    title?: string;
    options? : Option[];
    selected?: string;
    id?: string;
    selector?: string;
    disabled?: boolean;
    message?: string;
    required?: boolean;
    onChange?: (e:string) => void;
}




const DropDown = (props:Props) =>
{
    const selectRef = React.createRef<HTMLSelectElement>();
    const change = (e:React.SyntheticEvent<HTMLSelectElement>) =>
    {
        if(props.onChange)
        {
            props.onChange(e.currentTarget.value);
        }        
    }

    React.useEffect(() =>{
        if(props.selected && selectRef.current)
        {
            if(props.options)
            {                
                if(props.selector)  
                {
                    let i = 0;
                    props.options.map((x, index) => { if(x.value === props.selected){  i = index  } return null;});
                    selectRef.current.selectedIndex = i + 1;
                    }
                else
                {
                    let i = 0;
                    props.options.map((x, index) => { if(x.value === props.selected){  i = index  } return null;});
                    selectRef.current.selectedIndex = i;    
                }
            }
        }
    },[props.selected, props.options, selectRef]);
    const preventIosScrollJump = (e:React.FocusEvent<HTMLSelectElement>) =>
    {
        if (matchMedia('(hover:none) and (orientation:portrait)').matches) {
            window?.scrollTo(0,0);
            document.querySelector(".sidebar")?.scrollTo(0,0)    
        }
    }
    return (
       <StyledList key={"l" + props.id} className={`${props.className ? props.className : ""} ${props.title ? "titled" : ""}`}> 
           {props.title ? <label htmlFor={"dropddown-" + props.id}>{props.title}{props.required ? <span>*</span> : null}</label> : null}            
           <select onFocus={preventIosScrollJump} required={props.required ? true : false} disabled={props.disabled ? true : false} ref={selectRef} key={"dropdown-" + props.id} id={"dropddown-" + props.id} onChange={change}>
               {props.selector ? <option value="">    {props.selector}</option> : null}
                {props.options?.map((e, i) => <option data-index={!props.selector ? i : i + 1} key={"opt" + !props.selector ? i : i + 1} value={e.value}>{e.text}</option>)}
           </select>
           {props.disabled ? <div className="message">{props.message}</div> : null}
       </StyledList>
    )
}        


export default DropDown;