import * as React from "react";
import Panel from "../Panel";
import Toggle from "components/elements/Toggle";
import Radio from "components/elements/Radio";
import DropDown from "components/elements/DropDown";
import TextField from "components/elements/TextField";
import Checkbox from "components/elements/Checkbox";
import Tabs from "components/layout/Sidebar/Tabs";
import Row from "components/layout/Sidebar/Row";
import { updateUser } from "state/user/actions";
import { TrackEvent } from "helpers/Tracking";
import { useDispatch } from 'react-redux';
import { Tokens } from 'typings/translation';
import { Dealer, Dictionary } from 'typings/repo';
import { UserState } from 'state/user';
import { PAGES } from 'typings/cart';
import {Option} from 'components/elements/DropDown';
interface Props
{
    tokens: Tokens;
    dealers: Dealer[]
    countries: Dictionary<string>;
    user: UserState;
    langCode: string;
    formSuccess: boolean;
    panel: number;
    total: number;
    activeMenuTab: number;
    cartForm: React.RefObject<HTMLFormElement>;
    updateMenuTab: (id: number) => void;
    updatePage: (id: number, page: PAGES) => void;
    previousTab: () => void;
    nextTab: () => void;
    checkOut: () => void;
}

const SidebarCart: React.FC<Props> = (props) =>
{
    const [flexlineuser, updateFlexline] = React.useState(false);
    const [flexlineuserCustomer, updateFlexlineCustomer] = React.useState(false);
    const [flexlineuserCustomer2, updateFlexlineCustomer2] = React.useState(false);
    const [dealerChosen, setChosenDealer] = React.useState(false);
    const dispatch = useDispatch();

    const sortCountries = () =>
    {
        return Object.keys(props.countries).sort((a, b) => new Intl.Collator("da").compare(props.countries[a], props.countries[b]));
    }
    /* TODO, get countrycode form updated repo that has country codes*/
    const countryMatch = [{
        countryCode: "DK",
        country: "Denmark"
    },
    {
        countryCode: "N",
        country: "Norway"
    },
    {
        countryCode: "SE",
        country: "Sweden"
    },
    {
        countryCode: "IS",
        country: "Iceland"
    },
    {
        countryCode: "NL",
        country: "Netherlands"
    },
    {
        countryCode: "BE",
        country: "Belgium"
    },
    {
        countryCode: "LU",
        country: "Luxembourg"
    },
    {
        countryCode: "DE",
        country: "Germany"
    },
    {
        countryCode: "EE",
        country: "Estonia"
    }];

    const renderFlexlineUser = () =>
    {
        const {dealerFirmname, dealerDepartment, seller, dealerEmail, dealerPhone, dealerAddress, dealerZip, dealerCity} = props.user;

        return (
            <div>
                {renderFlexlineUserTrigger()}
                <hr className="cart-separator" />
                {props.tokens.DEALER_INFORMATION}
                <TextField title={props.tokens.FIRM_NAME} min={2} pattern={"^[A-Za-z0-9æÆøØåÅÉéÁáöÖ -.,/\\u0080-\\uFFFF]+$"} required onChange={(e) => dispatch(updateUser(e, "dealerFirmname"))} value={dealerFirmname}/>
                <TextField title={props.tokens.DEPARTMENT} min={2} pattern={"^[A-Za-zæÆøØåÅÉéÁáöÖ -.,\\u0080-\\uFFFF]+$"} onChange={(e) => dispatch(updateUser(e, "dealerDepartment"))} value={dealerDepartment}/>
                <TextField title={props.tokens.SELLER_NAME} required pattern={"^[A-Za-zæÆøØåÅÉéÁáöÖ -.,\\u0080-\\uFFFF]+$"} onChange={(e) => dispatch(updateUser(e, "seller"))} value={seller}/>
                <Row columns={2}>
                    <TextField type="email" title={props.tokens.EMAIL} required onChange={(e) => dispatch(updateUser(e, "dealerEmail"))} value={dealerEmail}/>
                    <TextField type="number" title={props.tokens.PHONE} min={8} onChange={(e) => dispatch(updateUser(e, "dealerPhone"))} value={dealerPhone}/>
                </Row>
                <TextField min={4} title={props.tokens.ADDRESS} onChange={(e) => dispatch(updateUser(e, "dealerAddress"))} value={dealerAddress}/>
                <Row columns={2}>
                    <TextField title={props.tokens.ZIP} min={4} pattern={"^[A-Za-z0-9-]+$"} onChange={(e) => dispatch(updateUser(e, "dealerZip"))} value={dealerZip}/>
                    <TextField title={props.tokens.CITY} min={2} pattern={"^[A-Za-zæÆøØåÅÉéÁáöÖ -.,]+$"} onChange={(e) => dispatch(updateUser(e, "dealerCity"))} value={dealerCity}/>
                </Row>
                {<DropDown key={"flex-cart-country"} id="flex-cart-country" title={props.tokens.COUNTRY} selected={props.user.dealerCountry} onChange={(e) => dispatch(updateUser(e, "dealerCountry"))}
                    //options={Object.keys(state.repo.countries).sort().map(c => ({ text: state.repo.countries[c], value: state.repo.countries[c] }))} />}
                    required={true}
                    selector={props.tokens.CHOOSE_COUNTRY}
                    options={sortCountries().map(c => ({ text: props.countries[c], value: c }))} />}
                <TextField title={props.tokens.COMMENT} onChange={(e) => dispatch(updateUser(e, "dealerComment"))} />
                <br />

                <Radio  className="cart"group="group-order" id={"go0"} key={"go0"} value={"Tilbud"} onChange={(e) => dispatch(updateUser(e, "order"))}>
                    <div key={"order0"}>
                        <h4>{props.tokens.DEALER_SALE}</h4>
                    </div>
                </Radio>
                <Radio  className="cart"group="group-order" id={"g1"} key={"go1"} value={"Ordre"} onChange={(e) => {dispatch(updateUser(e, "order"))}}>
                    <div key={"order1"}>
                        <h4>{props.tokens.DEALER_ORDER}</h4>
                    </div>
                </Radio>
                <hr className="cart-separator" />
                <span><span style={{color: "red"}}>*</span>{props.tokens.DEALER_ADDRESS}<br/><br/></span>
                <Radio className="cart" group="group-delivery" id={"d0"} key={"d0"} value={"DEALER"} onChange={(e) => { dispatch(updateUser(e, "delivery")); updateFlexlineCustomer2(false) }}>
                    <div key={"delivery"}>
                        <h4>{props.tokens.DEALER_DELIVERY}</h4>
                    </div>
                </Radio>
                <Radio  className="cart"group="group-delivery" id={"d1"} key={"d1"} value={"CUSTOMER"} onChange={(e) => { dispatch(updateUser(e, "delivery")); updateFlexlineCustomer2(true) }}>
                    <div key={"delivery"}>
                        <h4>{props.tokens.CUSTOMER_DELIVERY}</h4>
                    </div>
                </Radio>
                {/*props.dealers?.length !== 0 ? <DropDown title={props.tokens.PREFERRED_DEALER} selector={props.tokens.CHOOSE_DEALER} onChange={(e) => dispatch(updateUser(e, "dealer"))}
                    options={props.dealers.map(e => { return { text: e.name, value: e.name } })} /> : null*/}
                <br />
                <hr className="cart-separator" />

                <Row padding="no-padding">
                    <Toggle key={"flexuser1"} id="flexuser1" checked={flexlineuserCustomer || flexlineuserCustomer2} onChange={() => { updateFlexlineCustomer(!flexlineuserCustomer); TrackEvent("CART", "flexline-partner-customer", (!flexlineuserCustomer).toString()); }} title={props.tokens.CUSTOMER_INFORMATIONS} />
                </Row>
                {renderDealerCustomerInformation()}
            </div>
        );
    }

    const renderDealerCustomerInformation = () =>
    {
        if (flexlineuserCustomer || flexlineuserCustomer2)
        {
            return (
                <div>
                    {renderUserInformation()}
                </div>
            );
        }
    }

    const renderDealerDropdown = () =>
    {
        if (props.dealers && props.dealers.length > 1)
        {
            const dealers:Option[] = [];
            const current = countryMatch.filter(x => x.country === props.user.country);
            const currentCountryCode = current.length > 0 ? current[0].countryCode : "";
            props.dealers.filter(x => x.country === currentCountryCode).map((e) => {
                dealers.push({ text: e.name, value: e.name });
            })
            if(dealers.length === 0)
            {
                const DEFAULT_DEALER = props.dealers.filter(x => x.country === "DEFAULT");
                if(DEFAULT_DEALER)
                {
                    dealers.push({text: DEFAULT_DEALER[0].name, value: DEFAULT_DEALER[0].name})
                }
            }
            return (
                <DropDown disabled={props.user.country ? false : true} key={"choose-dealer-dropdown"} id="choose-dealer-dropdown"  
                    message={props.tokens.CHOOSE_DEALER_INFO}                    
                    selector={props.tokens.CHOOSE_DEALER} title={props.tokens.DEALER} 
                    selected={props.user.dealer} onChange={(e) => {dispatch(updateUser(e, "dealer")); setChosenDealer(true); }}
                    options={dealers.map(e => {
                        return { text: e.text, value: e.value }
                    })} />
            );
        }
    }

    const renderUserInformation = () =>
    {   const { firmname, firstname, lastname, email, phone, address, zip, city, comment } = props.user;

        return (
            <div> 
                <TextField title={props.tokens.FIRM_NAME} min={2} required={flexlineuser} pattern={"^[A-Za-z0-9æÆøØåÅÉéÁáöÖ -.,/\\u0080-\\uFFFF]+$"} onChange={(e) => dispatch(updateUser(e, "firmname"))} value={firmname} />
                <Row columns={2}>
                    <TextField title={props.tokens.FIRST_NAME} min={2} pattern={"^[A-Za-zæÆøØåÅÉéÁáöÖ -.,/\\u0080-\\uFFFF]+$"} required onChange={(e) => dispatch(updateUser(e, "firstname"))}
                        errorMessage={"Please enter valid text"} value={firstname} />
                    <TextField title={props.tokens.LAST_NAME} min={2} pattern={"^[A-Za-zæÆøØåÅÉéÁáöÖ -.,/\\u0080-\\uFFFF]+$"} required onChange={(e) => dispatch(updateUser(e, "lastname"))} value={lastname} />
                </Row>
                <Row columns={2}>
                    <TextField type="email" title={props.tokens.EMAIL} required={!flexlineuser} onChange={(e) => dispatch(updateUser(e, "email"))} value={email} />
                    <TextField type="number" title={props.tokens.PHONE} min={8} required onChange={(e) => dispatch(updateUser(e, "phone"))} value={phone} />
                </Row>
                <TextField min={4} title={props.tokens.ADDRESS} required onChange={(e) => dispatch(updateUser(e, "address"))} value={address} />
                <Row columns={2}>
                    <TextField title={props.tokens.ZIP} required min={4} pattern={"^[A-Za-z0-9-]+$"} onChange={(e) => dispatch(updateUser(e, "zip"))} value={zip} />
                    <TextField title={props.tokens.CITY} min={2} required pattern={"^[A-Za-zæÆøØåÅÉéÁáöÖ -.,/\\u0080-\\uFFFF]+$"} onChange={(e) => dispatch(updateUser(e, "city"))} value={city} />
                </Row>
                <TextField title={props.tokens.COMMENT} onChange={(e) => dispatch(updateUser(e, "comment"))} value={comment} />
                <DropDown key={"user-cart-country"} id="user-cart-country" title={props.tokens.COUNTRY} 
                    required={true}
                    selector={props.tokens.CHOOSE_COUNTRY} selected={props.user.country} onChange={(e) => {dispatch(updateUser(e, "country")); setChosenDealer(false);}}
                    options={sortCountries().map(c => ({ text: props.countries[c], value: c }))} />
            </div>
        );
    }

    const renderDefaultUser = () =>
    {
        return (
            <div>
                {renderUserInformation()}
                {renderDealerDropdown()}
                <hr className="cart-separator" />
                {renderFlexlineUserTrigger()}
            </div>
        );
    }

    const renderFlexlineUserTrigger = () =>
    {
        return (
            <Row padding="no-padding small-margin">
                <Toggle key={"flexuser0"} id="flexuser0" checked={flexlineuser} onChange={() => { updateFlexline(!flexlineuser); 
                    TrackEvent("CART", "flexline-partner", (!flexlineuser).toString()); 
                    document.querySelector(".panel.active .panel__content")?.scrollTo(0,50);
                    }} title={props.tokens.FLEXLINE_PARTNER} />
            </Row>
        );
    }

    const renderCartUserInformation = () =>
    {
        if (flexlineuser)
        {
            return renderFlexlineUser();
        }
        return renderDefaultUser();
    }

    return (
        <div>
            <Panel total={props.total} title={props.tokens.BASKET} icon="cart" id={5} active={props.panel === 4} onClick={() => props.updatePage(4, "CART")} padding={0}>

                    <Tabs changedTab={(e) => props.updateMenuTab(e)} current={props.activeMenuTab} items={[props.tokens.BASKET, props.tokens.BASKET_CONTENT_MOBILE]} />
                    {!props.formSuccess ?
                    <div style={{ padding: "20px 25px" }}>
                        <form onSubmit={() => false} ref={props.cartForm}>
                            <h3>{props.tokens.BASKET_CONTENT}</h3>
                            {renderCartUserInformation()}
                            <hr className="cart-separator" />
                            <div className="terms-holder"><Checkbox required={true} />
                                <a href="https://flex1one.dk/profil/handelsbetingelser/" target="_blank" rel="noopener noreferrer">{props.tokens.ACCEPT_TERMS}</a>
                            </div>
                            {props.panel === 4 ? <><br />
                            <div className="checkout--mobile div-btn" onClick={() => {props.checkOut(); return false;}}>{!props.formSuccess ? props.tokens.CHECKOUT : ""}</div></> : null}

                        </form>
                    </div> : <p style={{padding: "20px"}}>{dealerChosen ? props.tokens.DEALER_THANKS : props.tokens.REQUEST_THANKS}</p> }

            </Panel>
        </div>
    );

}

export default SidebarCart;