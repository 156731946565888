import { BaseApi } from "./BaseApi";
import { ConfigurationApi } from "./ConfigurationApi";
import { MailApi } from "./MailApi";

const configurationApi = new ConfigurationApi(new BaseApi());
const mailApi = new MailApi(new BaseApi());

class Api
{
    get configurationApi() { return configurationApi; }
    get mailApi() { return mailApi; }
}

export const api = new Api();

export const createApi = (fetch: any) => ({
    configurationApi: new ConfigurationApi(new BaseApi(fetch)),
    mailApi: new MailApi(new BaseApi(fetch))
});