import React from "react";
import "./App.css";
import Header from "./components/layout/Header";
import Sidebar from "./components/layout/Sidebar/Sidebar";
import Configurator from "./components/layout/Configurator/Configurator";

import { sendShareMail } from "./components/billing/CheckoutMail";
import Popup from "./components/dialogue/Popup";
import LandingPage from "./components/layout/Welcome/LandingPage";
import Preloader from "./components/layout/Configurator/Preloader";
import Preview from "./components/layout/Sidebar/Preview";
import TextField from "./components/elements/TextField";
import NumberField from "./components/elements/NumberField";
import Button from "./components/elements/Button";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./state/state";
import { checkCookie, Cookie } from "./components/dialogue/Cookie";
import { getUrlConfiguration, validateEmail, DEBUG_MODE } from "./utils/utils";
import Loader from "./components/elements/Loader";
import { setLanguage } from "./state/repo/actions";
import Debug from "./Debug";
import { setCurrency, setConstraint } from "state/cart/actions";
import ReactGA from "react-ga";
import { UA_ID, TrackEvent } from "./helpers/Tracking";
import { shareUrl } from "./utils/utils";
import Row from "components/layout/Sidebar/Row";
import { ReactComponent as SizeDocument } from "./icons/size-drawing.svg";
import {
  saveConfiguration,
  loadConfiguration,
} from "state/configuration/actions";
import { api } from "api/Api";
import { setModulesFromMeasurements } from "helpers/configurationHelper";
import { SidebarToggle } from "components/layout/Sidebar/Sidebar.style";
import IconButton from "components/elements/IconButton";
import { Cart } from "typings/cart";
import Head from "Head";
import { RepoState } from "./typings/repo";

const App: React.FC = () => {
  const [popup, updatePopup] = React.useState(false);
  const [save, updateSave] = React.useState(false);
  const [share, updateShare] = React.useState(false);
  const [project, updateProject] = React.useState(false);
  const [productLine, updateProductLine] = React.useState(false);
  const [currentProductLine, updateCurrentProductLine] = React.useState("");
  const [productRedirect, updateProductRedirect] = React.useState("");
  const [reset, updateReset] = React.useState(false);
  const [showMain, updateMain] = React.useState(false);
  const [mobileActive, updateMobileActive] = React.useState(false);
  const [showSize, updateSize] = React.useState({
    show: false,
    w: 1000,
    d: 1000,
  });
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const debug = DEBUG_MODE;
  const tokens = state.repo.translations;
  const conf = getUrlConfiguration();
  const [loading, updateLoading] = React.useState(false);
  const [email, updateEmail] = React.useState("");
  const [sent, updateSent] = React.useState(false);
  const [sentNotice, toggleSentNotice] = React.useState(false);
  const [shareNotice, toggleShareNotice] = React.useState(false);
  const [toggled, updateToggled] = React.useState(false);
  const [mobileToggled, updateMobileToggled] = React.useState(false);
  const [shareFields, updateShareFields] = React.useState({
    name: "",
    email: "",
    recipient: "",
    message: "",
  });

  const productLineUrl = window.location.pathname;

  React.useEffect(() => {
    //dev note. chrome and firefox has removed the option to write a custom text message for unload
    //initialize GA for event tracking
    ReactGA.initialize(UA_ID);
  }, [showMain, tokens.NOTIFICATION_EXIT]);

  React.useEffect(() => {
    // Change language to browser language on startup (save in cookie instead?)
    if (state.cart.currency !== "") return;
    const lang = conf
      ? conf.language
      : navigator.language.substring(0, 2).toLowerCase();
    const forceLang = getUrlConfiguration(true);
    if (!conf && forceLang?.language) {
      const action = setLanguage(forceLang.language);
      dispatch({
        type: forceLang?.project ? forceLang.project : "grey",
      });
      dispatch(action);
      const currency = (action.repo as RepoState).languages.find(
        (l) => l.code === action.repo.langCode
      );
      if (currency) {
        dispatch(setCurrency(currency.defaultCurrency));
      }
    } else {
      const action = setLanguage(lang);
      dispatch({
        type: forceLang?.project ? forceLang.project : "grey",
      });
      dispatch(action);
      const currency = (action.repo as RepoState).languages.find(
        (l) => l.code === action.repo.langCode
      );
      if (currency) {
        dispatch(setCurrency(currency.defaultCurrency));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  React.useEffect(() => {
    var path = window.location.pathname;
    path = path.substring(path.lastIndexOf("/"));
    if (path.length === 12) {
      // length of id is 11, plus one for /
      if (
        window.location.href.indexOf("/HeavyDuty") === -1 &&
        window.location.href.indexOf("/FLEXLINE-GREY") === -1 &&
        window.location.href.indexOf("/FLEXLINE-BLACK") === -1
      ) {
        window.location.assign(
          window.location.origin +
            "/FLEXLINE-GREY" +
            window.location.pathname +
            window.location.search
        );
        return;
      }
      path = path.substring(1);
      updateMain(true);
      dispatch<any>(loadConfiguration(state.repo.langCode, path));
    }
    // load configuration from path
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const language = urlParams.get("lang");
    const currency = urlParams.get("currency");
    if (language) dispatch(setLanguage(language));
    if (currency) dispatch(setCurrency(currency));
  }, [dispatch, state.cart.productLine]);

  React.useEffect(() => {
    if (share) {
      dispatch(
        saveConfiguration(
          state.repo.project,
          "",
          state.configuration,
          state.cart.accessories,
          state.cart.additional
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [share, dispatch]);
  const showSent = () => {
    updateSent(true);
    toggleSentNotice(true);
    updateSave(false);
    setTimeout(() => {
      updateSent(false);
      toggleSentNotice(false);
      if (productRedirect !== "") {
        window.location.href = productRedirect;
      }
    }, 3000);
  };
  const showShared = () => {
    toggleShareNotice(true);
    setTimeout(() => {
      toggleShareNotice(false);
    }, 3000);
  };

  return (
    <>
      <Head />
      {state.cart.preview ? <Preview src={state.cart.preview} /> : null}
      <div
        className={`App ${mobileActive ? "mobile-active" : ""}  ${
          mobileToggled ? "mobile-toggled" : ""
        }`}
      >
        <div className={`notification-saved ${sentNotice ? "show" : ""}`}>
          {tokens.SAVED_MESSAGE}
        </div>
        <div className={`notification-saved ${shareNotice ? "show" : ""}`}>
          {tokens.SHARED_MESSAGE}
        </div>
        <Popup show={popup} onClick={() => updatePopup(!popup)} />
        <Popup show={reset}>
          <p>{tokens.RESET_CONFIRM}</p> <br />
          <Button title={tokens.CANCEL} onClick={() => updateReset(false)} />
          &nbsp;
          <Button
            title={tokens.CONFIRM}
            onClick={() => {
              updateMain(false);
              updateReset(false);
            }}
          />
        </Popup>
        <Popup
          show={save && !sent ? true : false}
          onClick={() => {
            updateSave(!save);
            updateLoading(false);
            updateSent(false);
            updateProductRedirect("");
          }}
        >
          <h2>{tokens.SAVE}</h2>
          {sent ? <p>{tokens.SAVED_MESSAGE}</p> : <p>{tokens.SAVE_TEXT}</p>}
          <TextField
            type="email"
            value={email}
            title={tokens.EMAIL}
            onChange={(e) => updateEmail(e)}
          />
          <br />
          <Button
            title={
              loading && !state.cart.storageId ? (
                <Loader size="small" color="secondary" />
              ) : (
                tokens.OK
              )
            }
            onClick={async () => {
              if (validateEmail(email)) {
                TrackEvent("NAVIGATION", "save", "saved");
                await api.configurationApi
                  .saveConfiguration({
                    project: state.repo.project,
                    configuration: state.configuration,
                    accessories: state.cart.accessories,
                    additional: state.cart.additional,
                  })
                  .then((storageId) => {
                    const cartOrder: Cart = {
                      id: storageId,
                      customer: { ...state.user },
                      date: new Date(),
                      configimg: state.cart.configimg,
                      currency: state.cart.currency,
                      language: state.repo.langCode,
                      additional: state.cart.additional,
                      accessories: state.cart.accessories,
                      productLine: state.cart.productLine,
                    };
                    sendShareMail(
                      {
                        configuration: state.configuration,
                        repo: state.repo,
                        cart: cartOrder,
                      },
                      email
                    );
                  });
                updateLoading(false);
                showSent();
              }
            }}
          />
        </Popup>
        <Popup show={share} onClick={() => updateShare(!share)}>
          <h2>{tokens.SHARE}</h2>
          <p>{tokens.SHARE_TEXT}</p>
          {state.cart.storageId ? (
            <p
              style={{ color: "#5e6265", fontSize: ".9em" }}
            >{`${shareUrl}${productLineUrl}/${state.cart.storageId}?lang=${state.repo.langCode}&currency=${state.cart.currency}`}</p>
          ) : (
            <Loader />
          )}
          {state.cart.storageId ? (
            <div>
              <div className="share-form">
                <TextField
                  type="text"
                  id="sf0"
                  value={shareFields.name}
                  title={tokens.SHARE_FIELD_SENDER_NAME}
                  onChange={(e) =>
                    updateShareFields({ ...shareFields, name: e })
                  }
                />
                <TextField
                  type="email"
                  id="sf1"
                  value={shareFields.email}
                  title={tokens.SHARE_FIELD_SENDER_EMAIL}
                  onChange={(e) =>
                    updateShareFields({ ...shareFields, email: e })
                  }
                />
                <TextField
                  type="email"
                  id="sf2"
                  value={shareFields.recipient}
                  title={tokens.SHARE_FIELD_RECIPIENT_EMAIL}
                  onChange={(e) =>
                    updateShareFields({ ...shareFields, recipient: e })
                  }
                />
                <TextField
                  type="text"
                  id="sf3"
                  value={shareFields.message}
                  title={tokens.SHARE_FIELD_MESSAGE}
                  onChange={(e) =>
                    updateShareFields({ ...shareFields, message: e })
                  }
                />
                <br />
              </div>
              <Button
                title={
                  loading && !state.cart.storageId ? (
                    <Loader size="small" color="secondary" />
                  ) : (
                    tokens.SHARE_EMAIL
                  )
                }
                onClick={async () => {
                  if (
                    state.cart.storageId &&
                    validateEmail(shareFields.recipient) &&
                    validateEmail(shareFields.email) &&
                    shareFields.name !== ""
                  ) {
                    const cartOrder: Cart = {
                      id: state.cart.storageId,
                      customer: { ...state.user },
                      date: new Date(),
                      configimg: state.cart.configimg,
                      currency: state.cart.currency,
                      language: state.repo.langCode,
                      additional: state.cart.additional,
                      accessories: state.cart.accessories,
                      productLine: state.cart.productLine,
                    };
                    sendShareMail(
                      {
                        configuration: state.configuration,
                        repo: state.repo,
                        cart: cartOrder,
                      },
                      shareFields.recipient,
                      shareFields.name,
                      shareFields.email,
                      shareFields.message
                    );

                    updateLoading(false);
                    showShared();
                    updateShare(!share);
                  }
                }}
              />
            </div>
          ) : null}
        </Popup>
        <Popup show={project} onClick={() => updateProject(!updateProject)}>
          <h2>{tokens.NEW_CONFIGURATION_START}</h2>
          <p>{tokens.NEW_CONFIGURATION_DESC}</p>
          <br />
          <Button
            title={tokens.SAVE_FIRST}
            onClick={() => {
              TrackEvent("NAVIGATION", "new-project", "save-first");
              updateProject(!updateProject);
              updateSave(true);
            }}
          />
          &nbsp;
          <Button
            title={tokens.RESTART}
            onClick={() => {
              TrackEvent("NAVIGATION", "new-project", "restart");
              window.location.href = "/?lang=" + state.repo.langCode;
            }}
          />
        </Popup>
        <Popup
          show={productLine}
          onClick={() => {
            updateProductLine(!updateProductLine);
            updateProductRedirect("");
          }}
        >
          <h2>{tokens.NEW_CONFIGURATION_START}</h2>
          <p>{tokens.NEW_CONFIGURATION_DESC}</p>
          <br />
          <Button
            title={tokens.SAVE_FIRST}
            onClick={() => {
              updateProductRedirect(
                state.cart.productLine === "FLEXLINE_GREY"
                  ? "/HeavyDuty?lang=" + state.repo.langCode
                  : "/FLEXLINE-GREY?lang=" + state.repo.langCode
              );
              TrackEvent("NAVIGATION", "change-product-line", "save-first");
              updateProductLine(!updateProductLine);
              updateSave(true);
            }}
          />
          &nbsp;
          <Button
            title={tokens.RESTART}
            onClick={() => {
              if (currentProductLine === "HEAVY_DUTY") {
                window.location.href = "/HeavyDuty?lang=" + state.repo.langCode;
              } else if (currentProductLine === "FLEXLINE_GREY") {
                window.location.href =
                  "/FLEXLINE-GREY?lang=" + state.repo.langCode;
              } else if (currentProductLine === "FLEXLINE_BLACK") {
                window.location.href =
                  "/FLEXLINE-BLACK?lang=" + state.repo.langCode;
              } else {
                window.location.href =
                  "/FLEXLINE-GREY?lang=" + state.repo.langCode;
              }
            }}
          />
        </Popup>
        <Popup
          show={showSize.show}
          onClick={() => updateSize({ show: !showSize.show, w: 1000, d: 1000 })}
        >
          <h3>{tokens.CONSTRAINT}</h3>
          <p>{tokens.CONSTRAINT_TEXT}</p>
          <Row className="centered">
            <div style={{ width: "calc(50% - 40px)", marginRight: "40px" }}>
              <NumberField
                type="number"
                value={showSize.w.toString()}
                onChange={(e) => updateSize({ ...showSize, w: parseInt(e) })}
                title={`${tokens.WIDTH} (${tokens.UNITS})`}
              />
            </div>
            <div style={{ position: "relative" }}>
              <span style={{ position: "absolute", bottom: "30px" }}>
                {tokens.WIDTH}
                <br />
                {showSize.w !== 0 && !isNaN(showSize.w) ? `${showSize.w}` : ""}
              </span>
              <SizeDocument />
            </div>
          </Row>{" "}
          <br />
          <br />
          <Button
            title={tokens.OK}
            disabled={showSize.w > 999 && showSize.w < 15001 ? false : true}
            onClick={() => {
              updateSize({ ...showSize, show: false });
              updateMain(true);
              dispatch(
                setModulesFromMeasurements(state.repo, showSize.w, showSize.d)
              );
              dispatch(
                setConstraint(showSize.w === undefined ? 1000 : showSize.w, 0)
              );
            }}
          />
        </Popup>
        <Preloader
          isdone={!state.cart.preloader}
          title={tokens.PRODUCT_NAME}
          active={showMain && state.cart.preloader}
          langCode={state.repo.langCode}
          productLine={state.repo.project}
        />
        {showMain ? (
          <>
            <Header
              showReset={() => (window.location.href = "/")}
              showPopup={() => updatePopup(!popup)}
              showSave={() => {
                updateSave(true);
              }}
              showProject={() => updateProject(true)}
              showShare={() => updateShare(true)}
              showProductLine={() => updateProductLine(true)}
              currentProductLine={(e) => updateCurrentProductLine(e)}
            />
            <Sidebar setMobile={updateMobileActive} toggle={toggled} />
            <SidebarToggle className={toggled ? "active" : ""}>
              <IconButton
                icon="arrow-left"
                onClick={() => {
                  updateToggled(!toggled);
                }}
              />
            </SidebarToggle>
            <SidebarToggle
              className={`${mobileToggled ? "active" : ""} mobile-toggle`}
            >
              <IconButton
                icon="arrow-left"
                onClick={() => {
                  updateMobileToggled(!mobileToggled);
                }}
              />
            </SidebarToggle>

            {debug ? <Debug /> : <Configurator toggle={toggled} />}
          </>
        ) : (
          <LandingPage
            onNavClick={() => {
              updateMain(true);
            }}
            onSizeClick={() => updateSize({ ...showSize, show: true })}
          />
        )}
        {!checkCookie() ? <Cookie text={tokens.COOKIE_DISCLAIMER} /> : null}
      </div>
    </>
  );
};

export default App;
