import { Action } from 'redux'
import { RepoState } from '../../typings/repo';
import { getProductLine } from 'utils/utils';
import { useEffect } from 'react';

interface SetRepoAction extends Action<"SET_REPO">
{
    repo: RepoState;
}

export const setLanguage = (lang: string) => {
  const productLine = getProductLine();
  let repo;
  try {
    repo = require(`../repository-data/${productLine}/repository-${productLine}-${lang}.json`);
  } catch (e) {
    console.log(e)
    repo = require(`../repository-data/grey/repository-grey-en.json`);
  }

  return { type: "SET_REPO", repo }
}

// Extend all repository action interfaces
export interface RepoAction extends SetRepoAction { }