import styled from "styled-components";
import { TertiaryColor, OffColor } from 'global.styles';

export const StyledStep = styled.div`
display: flex;
align-items: center;
margin: 5px 0;
> *:nth-last-child(2){
    margin-right: auto !important;
}
border-bottom: 1px solid rgba(0,0,0,0.05);
.steppers{
    display: flex;
    max-width: 108px;
    margin-left: auto;
    margin-right: auto;
    input{
        //font-family: "Arial";
        max-width: 44px;
        min-width: 44px;
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin-left: 0;
        margin-right: 0;
    }
    button{
        width: 108px;
        span{
            line-height: 15px;
            align-items: center;
            font-size: 12px;           
        }
    }
    span{
        font-size: 15px;
    }
}

.product-item{    
    cursor: pointer;
    h6{
        margin: 0;
        margin-bottom: 8px;
    }
    h4{
        margin: 0;
        margin-bottom: 10px
    }
    p{
        margin: 0;
        font-size: 14px;
        padding-right: 20px;
    }
}

.stepper-holder{
    display: flex;
    flex-direction: column;
    .price{
        display: flex;
        font-size: 13px;
        margin-top: 10px;
        span{
            display: inline-block;
            &:first-child{
                margin-right: auto;
                padding-right: 5px;
            }
        }
    }
}
&:not(.preview-stepper){
    @media(max-width: 769px){
        display: grid;
        grid-template-areas:
            "image content"
            "price price";
        grid-template-columns: 100px 1fr;
        padding-bottom: 10px;
        margin-bottom: 10px;
        > img{
            align-self: flex-start;
        }
        .product-item{
            grid-area: content;
        }
        .stepper-holder{
            display: grid;
            grid-template-areas:
            "amount"
            "price";
            grid-template-columns: 1fr;
            grid-area: price;
            padding-top: 10px;
            
            .steppers{
                grid-area: amount;
                margin-right: 0px;
                margin-left: auto;
                margin-bottom: 10px;
                max-width: 90px;
                .left{
                    //padding-right: 0;
                }
                .right{
                    //padding-right: 0;
                }
                input{
                    min-width: 18px;
                    max-width: 18px;
                }
            }            
        
            .price{
                grid-area: price;
                margin-top: auto;
                margin-bottom: auto;
                span:first-child{
                    margin-right: 10px;
                    margin-left: auto;
                }
            }
        }

    }
}
@media(max-width: 769px){
    &.preview-stepper{
        display: grid;
        grid-template-areas:
            "title close"
            "title amount";
        grid-template-columns: 1fr;
        grid-gap: 20px 10px;
        border-bottom: none;
        > div:not(.stepper-holder){
            grid-area: title;
            align-self: flex-start;
            h6{
                margin-top: 0;
            }
        }
        .stepper-holder{
            grid-area: amount;
            padding-top: 15px;
            align-items: flex-end;
            .steppers{
                margin-right: 0;
                max-width: 90px;
                input{
                    min-width: 18px;
                    max-width: 18px;
                }
            }  
            .price{
                margin-top: 5px;
            }
        }
        & + button.close{
            border-left: none;
            align-items: flex-start;
            position: absolute;
            right: 0;
            top: 14px;
            padding-top: 0;
        }
    }            
}
`;

export const StyledButton = styled.button`
border: 2px solid ${TertiaryColor};
background: ${TertiaryColor};
color: white;
outline: none;
line-height: 20px;
outline: none;
&.left{
    border-radius: 20px 0 0 20px;
    margin-right: auto;
    cursor: pointer;
    padding: 1px 10px;
    &[disabled]{
        border: 2px solid ${OffColor};
        border-right-color: ${TertiaryColor};
        background:  ${OffColor};
    }
}

&.right{
    border-radius: 0px 20px 20px 0px;
    margin-left: auto;
    padding: 1px 10px;
    cursor: pointer;
    &[disabled]{
        border: 2px solid ${OffColor};
        border-left-color: ${TertiaryColor};
        background:  ${OffColor};
    }
}
span{
    font-family: monospace;
    display: flex;
    height: 21px;
    align-items: flex-start;
}
`;

export const StyledInput = styled.input`
border: 2px solid  ${TertiaryColor};
border-left: none;
border-right: none;
text-align: center;
line-height: 20px;
`;