import * as React from "react";
import styled from "styled-components";

interface Props{

    columns?: number;
    padding?: string;
    className?: string;
    children?: React.ReactNode;
}

const StyledRow = styled.div`
    display: flex;    
    border: none !important;
    &.columns--2{
        > *:first-child{
            margin-right: 4px;
            width: 100%;
        }
        > *:last-child{
            margin-left: 4px;
            width: 100%;
        }
    }
    &.columns--3{
        > *:first-child{
            margin-right: 2px;
        }
        > *:nth-child(2){
            margin-left: 2px;
            margin-right: 2px;
        }
        > *:last-child{
            margin-left: 2px;
        }
    }

    &.no-padding > div{
        padding: 0;
        width: 100%;
    }
    &.centered{
        justify-content: center;
    }
    &.small-margin{
        margin-top: 20px;
    }
`;

const Row:React.FC<Props> = (props) =>
{
    return <StyledRow className={`${props.columns ? `columns--${props.columns}` : ""} ${props.padding ? props.padding : ""} ${props.className}` }>
        {props.children}
    </StyledRow>
    ;
}

export default Row;