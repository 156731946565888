import * as React from "react";
import {StyledButton} from "./IconButton.style";
import {ICON_TYPE} from "./Icon";
import Icon from "./Icon";



interface Props{
    additional?: JSX.Element;
    title?: string;
    icon?: ICON_TYPE;
    onClick: (e:React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    tooltip? : string;
    type?: string;
    href?: string;
}

const IconButton = (props:Props) =>
{
    return (        
        <StyledButton as={props.type === "link" ? "a" : "button"} title={props.tooltip} className={`${props.className ? props.className : ""} ${!props.title ? "empty" : ""}`} onClick={props.onClick}>
            {props.icon ? <span ><Icon name={props.icon} /></span> : null }<span>{props.title}</span>
            {props.additional}
        </StyledButton>
    );
    

}

export default IconButton;