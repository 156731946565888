import { Action } from 'redux';
import templatesGrey from 'state/repository-data/grey/template-setups-grey.json';
import templatesBlack from 'state/repository-data/black/template-setups-black.json';
import templatesHD from 'state/repository-data/hd/template-setups-hd.json';
import { TemplateState, ConfigurationState, Template } from 'typings/configuration';
import uuidv1 from "uuid/v1";
import { Material } from 'state/repo';
import { getModuleList } from 'state/configuration';

const initialRepo: TemplateState = templatesGrey;

const templateReducer = (state = initialRepo, action: Action): TemplateState =>
{
    switch (action.type)
    {
        case "hd": return templatesHD as TemplateState;
        case "grey": return templatesGrey as TemplateState;
        case "black": return templatesBlack as TemplateState
        default:
            break;
    }
    return state;
}

export const templateToConfiguration = (template: Template): ConfigurationState =>
{
    var templates = { left: template.left.map(m => ({ ...m, id: uuidv1(), canCart: false, canFull: false, canCupboard: false, })), 
    corner: template.corner ? {...template.corner, id: uuidv1(), canCart: false, canFull: false, canCupboard: false,  defaultLifterModel: "" } : undefined, 
    right: template.right ? template.right.map(m => ({ ...m, id: uuidv1(), canCart: false, canFull: false, canCupboard: false })) : [], 
    defaultTabletop: Material.Multiplex, defaultCupboards: false, defaultLights: false, defaultPanels: false, defaultLifter: false, defaultLifterModel: "" };
    var modules = getModuleList(templates);
    templates.defaultCupboards = modules.find(m => m.cupboard) !== undefined;
    templates.defaultLights = modules.find(m => m.light) !== undefined;
    templates.defaultPanels = modules.find(m => m.panel) !== undefined;
    templates.defaultTabletop = modules.find(m => m.tabletop)?.tabletop || Material.Multiplex;

    return templates;
}

export default templateReducer;