import { ConfigurationAction } from "./actions";
import { Material } from '../repo';
import { ConfigurationState, Module } from 'typings/configuration';
import { PartType } from 'typings/globals';
import { validateState } from 'helpers/configurationHelper';
import { RepoState } from "../../typings/repo";

const initialConfiguration: ConfigurationState = {
    left: [],
    right: [],
    defaultTabletop: Material.Multiplex,
    defaultCupboards: true,
    defaultLifter: false,
    defaultLifterModel: "",
    defaultLights: false,
    defaultPanels: true
};

const configurationReducer = (state = initialConfiguration, repo: RepoState, action: ConfigurationAction): ConfigurationState =>
{
    switch (action.type)
    {
        case "REPLACE_AT":
            switch (action.side)
            {
                case "LEFT": state.left[action.index] = action.module; break;
                case "CORNER": state.corner = action.module; break;
                case "RIGHT": state.right[action.index] = action.module; break;
            }
            break;
        case "SET_CONFIGURATION":
            state = action.state;
            break;
        case "SET_PART":
            let module = getModule(state, action.moduleId);
            if (module == null) break;
            setPart(state, repo, module, action.part_type, action.model);
            if(action.part_type === "TABLETOP" && action.model) {
                state.defaultTabletop = action.model as Material;
            }
            break;
        case "SET_ALL_PARTS":
            state.left.forEach(m => setPart(state, repo, m, action.part_type, action.model));
            state.right.forEach(m => setPart(state, repo, m, action.part_type, action.model));
            if (state.corner)
            {
                setPart(state, repo, state.corner, action.part_type, action.cornerModel);
            }
            switch(action.part_type){
                case "LIGHT": state.defaultLights = action.model !== undefined; break;
                case "LIFTER": state.defaultLifter = action.model !== undefined; break;
                case "LIFTERMODEL": state.defaultLifterModel = action.model !== undefined ? action.model : "";  break;
                case "PANEL": state.defaultPanels = action.model !== undefined; break;
                case "CUPBOARD": state.defaultCupboards = action.model !== undefined; break;
                case "TABLETOP": state.defaultTabletop = action.model as Material; break;
                case "TABLETOP": state.defaultTabletop = action.model as Material; break;
            }
            break;
        case "SELECT_MODULE":
            state.selected = action.moduleId;
            break;
    }
    validateState(repo, state);
    
    return {...state};
}

const setPart = (configuration: ConfigurationState, repo: RepoState, module: Module, part_type: PartType, modelId?: string) =>
{
    if (modelId === undefined) {
        switch (part_type) {
            case "CUPBOARD": module.cupboard = undefined; module.light = false; break;
            case "CABINET": module.cabinet = undefined; break;
            case "PANEL": module.cupboard = undefined; module.light = false; module.panel = undefined; break;
            case "LIGHT": module.light = false; break;
            case "LIFTER": module.isLifted = false; break;
            case "LIFTERMODEL": module.isLifted = false; break;
        }
        return;
    }
    var model = repo.allProducts[modelId];
    var base = repo.allProducts[module.base];
    switch (part_type)
    {
        case "BASE": {
            if (model.isBaseModule) {
                module.cabinet = module.cabinet || repo.basecabinets.find(c => c.isCorner === model.isCorner)?.id;
                module.cupboard = configuration.defaultCupboards ? repo.cupboards.find(c => c.isCorner === model.isCorner)?.id : undefined;
                module.panel = configuration.defaultPanels ? repo.toolboards.find(c => c.isCorner === model.isCorner)?.id : undefined;
                module.light = configuration.defaultLights;
            } else {
                module.cabinet = undefined;
                module.cupboard = undefined;
                module.light = false;
                module.panel = undefined;
            }
            module.base = modelId;
        } 
        break;
        case "CUPBOARD":{ 
            module.cupboard = modelId;
            if (module.cupboard) {
                module.panel = repo.toolboards.find(t => t.isCorner === base.isCorner)?.id;
                module.light = configuration.defaultLights;
            }
        } break;
        case "CABINET": {
            module.cabinet = modelId;
            
        
        } break;
        case "PANEL": 
        {
            module.panel = modelId;
            module.cupboard = !repo.allProducts[module.panel]?.isCorner ? module.cupboard : undefined;
            module.light = module.cupboard !== undefined && module.light;
        }
        break;
        case "TABLETOP": module.tabletop = modelId as Material | undefined; break;
        case "LIGHT": 
        {
            if(module.cupboard){
                module.light = modelId !== undefined; 
            }
        }
        break;
        case "LIFTER": 
        {
            module.isLifted = modelId !== undefined; 
        }
        case "LIFTERMODEL": 
        {
            module.lifter = modelId !== undefined ? modelId : undefined; 
        }
        break;
    }
}

export const getModule = (configuration: ConfigurationState, moduleId?: string): Module | undefined =>
{
    return getModuleList(configuration).find(m => m.id === moduleId);
}

// returns modules in order from left to right
export const getModuleList = (configuration: ConfigurationState): Module[] =>
{
    return configuration.left.slice().reverse().concat(configuration.corner ? [configuration.corner] : []).concat(configuration.right);
}

export default configurationReducer;