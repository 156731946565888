import * as React from "react";
import styled from "styled-components";

import Icon from "../../elements/Icon";
import { useSelector } from 'react-redux';
import { RootState } from 'state/state';

interface Props{
    onSearch?: (e:string) => void;
}

const StyledSearch = styled.div`
    display: flex;
    padding: 0px 10px;
    border: 1px solid #84888C;
    background: white;
    align-items: center;    
    svg{
        width: 20px;
        height: 20px;
        fill: #84888C;
    }
    input{
        width: 100%;
        border: none;
        outline: none;
        @media (hover: none) and (max-width: 769px){
            font-size: 16px !important;
        }
    }
`;


const Search = (props:Props) =>
{
    const state = useSelector((state: RootState) => state);
    const tokens = state.repo.translations;
    const changedSearch = (e:React.ChangeEvent<HTMLInputElement>) =>
    {
        if(props.onSearch)
        {
            props.onSearch(e.target.value);
        }
    };

    return <StyledSearch>
        <Icon name="search" />
        <input type="text" placeholder={tokens.SEARCH} onChange={changedSearch}/>
    </StyledSearch>;
}

export default Search;