import * as React from "react";
import { StyledLogo } from "./Logo.style";

interface Props{
    className?: string;
    src?: string;
    onClick?: () => void;
}

const Logo = (props:Props) => 
{
    return <StyledLogo className={`logo ${props.className}`} onClick={props.onClick}>
        {props.src ? <img alt="logo" src={props.src} />  : null}
    </StyledLogo>
}

export default Logo;



