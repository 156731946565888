import * as React from "react";
import { PanelStyle } from "./Panel.style";
import { ICON_TYPE } from "../../elements/Icon";
import Icon from "../../elements/Icon";

interface Props{
    active?: boolean;
    title: string;
    id: number;
    onClick: () => void;
    total: number;
    padding?: number;
    icon?: ICON_TYPE;
    children?: React.ReactNode;
}

const Panel : React.FC<Props> = (props) =>
{
    const style = {
        minHeight: props.active  ? `calc(100vh - ${(props.total+1) * 57}px - 70px - 60px)`: "0px",
        maxHeight: props.active  ? `calc(100vh - ${(props.total+1) * 57}px - 70px - 60px)`: "0px",
        padding: props.padding && props.active ? props.padding + "px": "0"
    }
    const r = React.createRef<HTMLDivElement>();    
    React.useEffect(() => {
        
        if(r.current)
        {
            r.current.addEventListener("touchmove", function(e){ e.preventDefault()});
        }            
    },[r]);

    return (
        <PanelStyle className={`panel ${props.active ? "active" : ""}`} key={props.id} style={{minHeight: props.active ? `calc(100% - ${props.total * 57}px - 60px)` : "0px"}}>
            <h2 ref={r} onClick={props.onClick} >{props.icon ? <Icon name={props.icon} /> : <span>{props.id}</span>}<span>{props.title}</span></h2>
            <div className="panel__content" style={style}>
                {props.children}
            </div>
        </PanelStyle>
    );


}

export default Panel;