import styled from "styled-components";
import { PrimaryColor, BorderColor } from 'global.styles';

export const RadioStyle = styled.div`
padding: 9px 0px;
// border-bottom: 1px solid rgba(0,0,0,.1);
//clear: both;
display: flex;
align-items: center;
label{
    float: left;
    clear: left;
    line-height: 30px;
    cursor: pointer;
}
position: relative;
label{
    width: calc(100% - 22px);
    display: flex;
}
input{
    cursor: pointer;
   float:right;
   -moz-appearance: none;
    width: 8px;
    height: 8px;
    margin-left: 10px;
   &:after{
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    background: white;
    border: 2px solid ${BorderColor};
    margin-left: -5px;
    margin-top: -1px;
    transition: all .1s linear;
    margin-top: -7px;
   }
   &:checked:after{
    border-color: ${PrimaryColor};
    border-width: 6px;
    width: 10px;
    height: 10px;
   }
   &:disabled{
       opacity: .4;
   }


    &::-ms-check {
        border: 4px solid ${BorderColor};
        color: transparent;
        opacity: 1;
        width: 10px;
        height: 10px;
    }

    &:checked::-ms-check {
        border: 4px solid  ${PrimaryColor};
        //color: green;
        opacity: 1;
    }
    
}
span, div > span{
    font-size: 15px;
}
h4, h5, h6{
   margin: 0;
 
}
h4{
    line-height: 1em;
}
img{
    animation: .5s showImage both;
    animation-delay: .3s;
}

&.cart{
    label h4{
        font-weight: normal;
        font-size: 14px;
    }
}

@keyframes showImage{
   0%{
        opacity: 0;
   }
   100%{
        opacity: 1;
   }
}
`;
