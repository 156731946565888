import styled from "styled-components";


export const PopupStyle = styled.div`
position: fixed;
width: 100vw;
height: 100vh;
//opacity: 0;
background: rgba(0,0,0,.4);
left: 0;
top: 0;
z-index: 99;
animation: showIt .5s both;
h2, h3, h4, h5{
    margin: 5px 0;

}
h2{
    font-size: 1em;
}
> div{
    padding: 20px;
    box-sizing: border-box;
    pointer-event: none;
    background: white;
    max-width: 600px;
    //height: 500px;
    transform: translate(calc(50vw - 50%), calc(50vh - 50%));        
    > button{
        margin-left: auto;
    }
}

&.active{
    > div{
        
    }
}

@keyframes showIt{
    0%{ opacity: 0;} 
    100%{ opacity: 1;} 
}

.share-form{
    


    > *:last-child{
        min-height: 80px;
    }
}

`;