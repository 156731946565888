import * as React from "react";
import SizeComponent from "../Controls/Size";
import Pan from "../Controls/Pan";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../state/state";
import { UnityModule, UnityList } from "../../../typings/unity";
import { closePreloader, addCartImage, changePageHideNotification, changeModuleHideNotification, changeModuleShowNotification, setCamera, changeSizeHideNotification } from "../../../state/cart/actions";
import { Size } from 'typings/repo';
import { Module } from 'typings/configuration';
import { selectModule } from '../../../state/configuration/actions';
import { ConfiguratorStyle, HeavyLogo } from "./Configurator.style";
import { getCustomSize } from 'helpers/configurationHelper';
import IconButton from 'components/elements/IconButton';
import { CAMERAORIENTATION } from 'typings/globals';
import heavylogo from '../../../icons/heavy_logo.svg';
import Unity, { UnityContext } from "react-unity-webgl";

interface Props {
    toggle?: boolean;
}
const unityVersion = "unity302";//document.createElement('canvas').getContext('webgl2') ? "unity" : "unity"; // Can be setup to give different versions
const unityContext = new UnityContext({
    loaderUrl: `/${unityVersion}/Build/${unityVersion}.loader.js`,
    dataUrl: `/${unityVersion}/Build/${unityVersion}.data`,
    frameworkUrl: `/${unityVersion}/Build/${unityVersion}.framework.js`,
    codeUrl: `/${unityVersion}/Build/${unityVersion}.wasm`,
});

const unitySend = (method: string, obj: string | undefined = undefined) => {
    unityContext.send(
        "RunScript",
        method,
        obj
    );
}

export const takeScreenshot = () => {
    unitySend("TakeScreenshot");
}
const Configurator = (props: Props) => {
    const state = useSelector((state: RootState) => state);
    const configuration = state.configuration;
    const repo = state.repo;
    const tokens = state.repo.translations;
    const dispatch = useDispatch();
    const size: Size = getCustomSize(state.configuration, repo);

    unityContext.on("Hover", (id: any) => {
        // console.log("Hover " + id);
        //dispatch(hoverModule(id));
    });

    unityContext.on("Select", (id: any) => {
        setLastSelected(id);
        dispatch(selectModule(id));

        if (state.cart.page === "MODULES" && !state.cart.notified_module && id !== "" && !state.cart.notified_module_dismissed) {
            dispatch(changeModuleShowNotification("MODULES"));
        }

        if (state.cart.page === "MODULES" && state.cart.notified_module && id === "") {
            dispatch(changeModuleHideNotification("MODULES"));
        }

        if (!state.cart.notified) {
            dispatch(changePageHideNotification("MODULES"))
        }
    });

    unityContext.on("ScreenshotTaken", (b64: string) => {
        dispatch(addCartImage(b64));

        const canvas = document.querySelector("canvas") as HTMLCanvasElement;
        if (canvas) {
            canvas.width = canvas.width + 1;
            setTimeout(function () { canvas.width = canvas.width - 1; }, 10);
        }

    });

    unityContext.on("Loaded", () => {
        console.log("Loaded");
        updateModel(true);
        if (state.cart.preloader) {
            dispatch(closePreloader());
        }
        dispatch(setCamera(state.configuration.corner ? "OVERVIEW" : "LEFT"));
        unitySend("SetMobile");
    });

    const getModule = (obj: Module): UnityModule => {
        return { ...obj, cabinet: state.repo.basemodules.find(m => m.id === obj.base)?.isBaseModule ? obj.cabinet : obj.base };
    }

    const [lastConfiguration, setLastConfiguration] = React.useState<string>("");
    const updateModel = (force: boolean = false) => {
        const data: UnityList = {
            left: configuration.left.map(x => getModule(x)),
            corner: configuration.corner ? getModule(configuration.corner) : undefined,
            right: configuration.right.map(x => getModule(x))
        }
        const json = JSON.stringify(data);
        if (!force && json === lastConfiguration) return;
        setLastConfiguration(json);
        //console.log(json);
        unitySend("SetModel", json);
        takeScreenshot();
    }

    const [lastSelected, setLastSelected] = React.useState<string | undefined>("");
    const updateSelected = () => {
        if (configuration.selected === lastSelected) return;
        setLastSelected(configuration.selected);
        unitySend("Select", configuration.selected || "");
    }

    React.useEffect(() => {
        if (!state.cart.preloader) {
            updateModel();
            updateSelected();
        }
    }, [configuration]);

    const [lastCamera, setLastCamera] = React.useState<CAMERAORIENTATION>("OVERVIEW");
    React.useEffect(() => {
        if (lastCamera === state.cart.camera) return;
        setLastCamera(state.cart.camera);
        unitySend("SetCamera", state.cart.camera);
    }, [state.cart]);

    React.useEffect(() => {
        if (state.cart.page === "MODULES" && !state.cart.notified && configuration.left && configuration.left.length > 0) {
            setTimeout(() => {
                let id = configuration.left[0].id;
                unitySend("Select", id);
                unitySend("Hover", id);
            }, 500);
        }
    }, [state.cart.preloader, state.cart.notified, state.cart.page, dispatch]);
    const r = React.createRef<HTMLDivElement>();
    React.useEffect(() => {
        if (r.current) {
            r.current.addEventListener("touchmove", function (e) { e.preventDefault() });
        }
    }, []);

    return (
        <ConfiguratorStyle className={`configurator ${props.toggle ? "toggled" : ""}`} ref={r}>
            {state.cart.page === "SIZES" && state.cart.notified_sizes && !state.cart.notified_module_dismissed ? <div className="notification">
                <IconButton onClick={() => dispatch(changeSizeHideNotification("SIZES"))} className="close" icon="close" />
                <span className="notify--desktop">{tokens.PANELS_HELP_TEXT}</span>
                <span className="notify--mobile">{tokens.PANELS_HELP_TEXT_MOBILE}</span>
            </div> : null}
            {state.cart.page === "MODULES" && !state.cart.notified ? <div className="notification">
                <IconButton onClick={() => dispatch(changePageHideNotification("MODULES"))} className="close" icon="close" />{tokens.NOTIFICATION_MODULES}</div> : null}
            <SizeComponent width={size.width} width2={size.width2} height={size.height} depth={size.depth} tokens={tokens} />
            {state.repo.basemodules.find(m => m.isCorner) !== undefined ?
                <Pan tokens={tokens} configuration={configuration} currentCamera={state.cart.camera} /> : null}
            {state.cart.productLine === "FLEXLINE_HEAVY_DUTY" ? <HeavyLogo src={heavylogo}></HeavyLogo> : null}

            <Unity unityContext={unityContext} style={{ height: "calc(100vh - 70px)", width: "calc(100vw - 300px)" }} />
        </ConfiguratorStyle>
    );
}

export default Configurator;
