import * as React from "react";
import styled from "styled-components";
import { PrimaryColor } from "../../global.styles";
import Icon from "./Icon";

type IconSize = "small" | "large";
type IconColor = "primary" | "secondary";
const StyledLoader = styled.div`
    svg{
        animation: loopIcon 1s linear both infinite;
        fill: ${PrimaryColor};
    }
    @keyframes loopIcon{
        0%{
            transform: rotate(0);
        }
        100%{
            transform: rotate(360deg);
        }
    }
    &.small{
        svg{
            width: 16px;
            height: 16px;
        }
    }
    &.secondary{
        svg{
            fill: white;
        }
    }
`;

interface Props{
    size?: IconSize;
    color?: IconColor;
}

const Loader = (props:Props) =>
{

    return <StyledLoader className={`${props.size} ${props.color}`}>
            <Icon name="spinner" />
        </StyledLoader>;
}

export default Loader;