import { Product, RepoState, Dictionary } from 'typings/repo';
import { ConfigurationState } from 'typings/configuration';
import { getConfigurationProducts } from 'helpers/configurationHelper';
import { getProduct } from 'state/repo';

export const getPrice = (configuration: ConfigurationState, repo: RepoState, currency: string, lang: string, extra1?: Dictionary<number>, extra2?: Dictionary<number>) => 
{
    var parts = getConfigurationProducts(configuration, repo);
    var price = getPartsPrice(parts, repo, currency);
    if(extra1) price += getPartsPrice(extra1, repo, currency);
    if(extra2) price += getPartsPrice(extra2, repo, currency);
    return price.toLocaleString(lang);
}

function getPartsPrice(parts: Dictionary<number>, repo: RepoState, currency: string){
    return Object.keys(parts).reduce<number>((price, id) => { 
        const product = getProduct(repo, id);
        if(product == null) return price;
        return price + (product.prices[currency] ? product.prices[currency] * parts[id] : 0);
    } ,0);
}

export const getItemPrice = (product: Product | Product, currency: string, lang: string, amount: number = 1) => 
{
    return (product.prices[currency] ? (product.prices[currency]*amount).toLocaleString(lang) : 0) + ",-";
}

export const getCurrency = (cur: string, repo:RepoState) =>
{
    return repo.currency.find(c => c.currency === cur)?.format ?? '{0}';
}

export const getItemsCount = (repo:RepoState, products: Dictionary<number>, accessories?: Dictionary<number>, additional?: Dictionary<number>) => 
{
    let total = 0;
    if(products) Object.keys(products).map(key =>  total += products[key]);
    if(accessories) Object.keys(accessories).map(key =>  total += accessories[key]);
    if(additional) Object.keys(additional).map(key =>  total += additional[key]);    

    return total;
}