import styled from "styled-components";
import { PrimaryColor } from 'global.styles';

export const StyledButton = styled.button`
padding: 10px 30px;
padding-left: 10px;
background: ${PrimaryColor};
color: white;
border-radius: 30px;
border: none;
user-select: none;
cursor: pointer;
outline: none;

display: flex;
align-items: center;

svg{
    color: white;
    fill: white;
    margin-right: 10px;
    width: 22px;
    height: 22px;
}
&.large{
    svg{
        width: 32px;
        height: 32px;    
    }
}
&.alternate{
    background: transparent;
    color: black;
    svg{
        fill: black;
    }
    &.close{
        svg{
            stroke: white;
            stroke-width: 4px;
        }
    }
}
&.reverse{
    flex-direction: row-reverse;
    svg{
        margin-right: 0px;
        margin-left: 10px;    
    }
}
&.empty{
    padding: 10px 10px;
    padding-right: 0px;
}

&.close:not(.alternate){
    background: transparent;
    padding-top: 0;
    svg{
        fill: ${PrimaryColor};
        width: 20px;
        height: 20px;
        stroke: white;
        stroke-width: 4px;
        margin-right: 0;
    }
    border-radius: 0;
}

&.size-style{
    span:last-child{
        width: 100px;
    }
}
`;