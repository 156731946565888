import styled from "styled-components";
import { PrimaryColor } from 'global.styles';

export const StyledCheckbox = styled.div`
margin-top: 10px;
input{
    margin: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    &:after{
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid black;
        pointer-events: none;
    }
   
    &:checked{
        &:before{
            content: "";
            width: 5px;
            height: 11px;
            display: block;
            position: absolute;
            border-right: 3px solid ${PrimaryColor};
            border-bottom: 3px solid ${PrimaryColor};
            transform:  ;
            margin-left: 7px;
            margin-top: 3px;
            animation: .3s showMark both;

        }
        &:after{
            //background: teal;
        }
    }
}

span{
    color: red;
}

label{
    display: block;
    padding-left: 33px;
    margin-top: -23px;
    font-size: 14px;
    line-height: 18px;
}
@keyframes showMark{
    0%{
        transform: scale(0) rotate(25deg);
    }
    100%{
        transform: scale(1) rotate(45deg);
    }
}
`;