import styled from "styled-components";
import { PrimaryColor } from 'global.styles';


export const StyledMenu = styled.div`
position: relative;
height: 100%;
h4{
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    cursor: pointer;    
    user-select: none;
}
svg{
    width: 16px;
    height: 16px;
}
> div{
    width: auto;
    position: absolute;
    top: 100%;
    z-index: 99;
    background: white;
    padding: 10px 25px;
    box-sizing: border-box;
    left: 0;
    border: 1px solid rgba(0,0,0,.2);
    border-top: 0;
    animation: showMenu .3s linear;
    overflow: hidden;
    //max-height: 0;

    > *{
        cursor:pointer;
        &:hover{
            &:before{
                content: "";
                width: 2px;
                height: 16px;
                position: absolute;
                left: 0;
                z-index: 100;
                background: ${PrimaryColor};
                animation: showIndicator .5s both;
            }
        }
    }
}
> div > div{
    padding: 10px 13px;
}
&.bordered{
    border: 1px solid rgba(0,0,0,.2);
    border-top: 0;
}
&.header-menu{
    float: right;
    /*width: 300px;
    margin-left: auto;*/
    text-transform: uppercase;
    padding: 10px 30px 10px 10px;
    box-sizing: border-box;
    span{
        margin-right: 15px;
        margin-left: 10px;
        font-size: .88em;
    }
}
@keyframes showMenu{
    0%{
        opacity: 0;
        max-height: 0;
    }
    100%{
        opacity: 1;
        max-height: 300px;
    }
}
&.left{
    h4{
        justify-content: flex-start;
    }
    > div{
        left: 0;
    }
}
&.right{
    h4{
        justify-content: flex-end;
    }
    > div{
        left: auto;
        right: 0;
    }
}
&.white{
    h4{
        color: white;
    }
    svg{
        fill: white;
    }
}
&.reversed{
    h4{
        flex-direction: row-reverse;
       
    }
}
@keyframes showIndicator{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
`;