import * as React from "react";
import {StyledCheckbox } from "./Checkbox.style";
interface Props{

    checked?: boolean;    
    required?: boolean;
    children?: React.ReactNode;
}

const Checkbox:React.FC<Props> = (props) =>
{

    const [checked, updateChecked] = React.useState(props.checked ? props.checked : false);


    return <StyledCheckbox>
        <input id="c0" name="c0" type="checkbox" required={props.required ? true: false} defaultChecked={checked} onChange={() => updateChecked(!checked)} />
        <label htmlFor="c0"> {props.required ? <span>*</span> : ""} {props.children}</label>
    </StyledCheckbox>
}


export default Checkbox;