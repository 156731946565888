import { Action, Dispatch } from 'redux';
import { Dictionary } from '../../typings/repo';
import { Module, ConfigurationState, Template  } from 'typings/configuration';
import { Side, PartType } from 'typings/globals';
import { templateToConfiguration } from 'state/templates';
import { api } from "../../api/Api";
import { setCartAccessoryAdditional, saveConfigurationAction } from 'state/cart/actions';
import { validateEmail } from 'utils/utils';
import { sendShareMail } from 'components/billing/CheckoutMail.old';
import { setLanguage } from '../repo/actions';

export const replaceAt = (module: Module, side: Side, index: number = 0) => ({ type: "REPLACE_AT", module, side, index });
interface ReplaceAtAction extends Action<"REPLACE_AT">
{
    index: number;
    side: Side;
    module: Module;
}

export const setPart = (part_type: PartType, moduleId: string, model?: string) => ({ type: "SET_PART", part_type, model, moduleId });
interface SetPartAction extends Action<"SET_PART">
{
    moduleId: string;
    model?: string;
    part_type: PartType;
}

export const setAllParts = (part_type: PartType, model?: string, cornerModel?: string) => ({ type: "SET_ALL_PARTS", part_type, model, cornerModel });
interface SetAllPartAction extends Action<"SET_ALL_PARTS">
{
    cornerModel?: string;
    model?: string;
    part_type: PartType;
}

export const saveConfiguration = (project: string, email: string, configuration: ConfigurationState, accessories?: Dictionary<number>, additional?: Dictionary<number>) => async (dispatch: Dispatch) =>
{
    try
    {
        dispatch(saveConfigurationAction(undefined));
        let storageId = await api.configurationApi.saveConfiguration({project, configuration, accessories, additional});
        dispatch(saveConfigurationAction(storageId));
        if(validateEmail(email))
        {
            sendShareMail(email, storageId, "SAVE");
        }
    }
    catch (ex)
    {
    }
};

export const loadConfiguration = (lang: string, storageId: string) => async (dispatch: Dispatch) =>
{
    try
    {
        let template = await api.configurationApi.loadConfiguration(storageId);
        if(template){
            const configAction: SetConfigurationAction = {
                type: "SET_CONFIGURATION",
                state: template.configuration
            };
            // dispatch(setLanguage(lang, template.project === "hd"));
            dispatch({ type: template.project});
            dispatch(configAction);
            dispatch(setCartAccessoryAdditional(template.accessories,template.additional));
        }
    }
    catch (ex)
    {
    }
};

export const setToTemplate = (template: Template) => setConfiguration(templateToConfiguration(template))
export const setConfiguration = (state: ConfigurationState) => ({ type: "SET_CONFIGURATION", state });
interface SetConfigurationAction extends Action<"SET_CONFIGURATION">
{
    state: ConfigurationState;
}

export const selectModule = (moduleId?: string) => ({ type: "SELECT_MODULE", moduleId });
interface SelectModuleAction extends Action<"SELECT_MODULE">
{
    moduleId?: string;
}

export type ConfigurationAction = ReplaceAtAction | SetConfigurationAction | SetPartAction | SelectModuleAction | SetAllPartAction