import * as React from "react";
import { ToggleStyle } from './Toggle.style';

interface Props{
    title?: string;
    checked?: boolean;
    onChange?: (e:boolean) => void;
    id?: string;
    disabled?: boolean;
}

const Toggle = (props:Props) =>
{
    const change = (e:React.SyntheticEvent<HTMLInputElement>) =>
    {
        if(props.onChange)
        {
            props.onChange(e.currentTarget.checked);
        }
    }
    const id = "toggle-" + props.id;
    return (
        <ToggleStyle className={props.disabled ? "disabled" : ""}>
            <div>   
                <span>{props.title} </span>
                <input id={id} key={id} name={id} type="checkbox" onChange={change} checked={props.checked} disabled={props.disabled}/>
                <label htmlFor={id}></label>
            </div>
        </ToggleStyle>
    );
}

export default Toggle;