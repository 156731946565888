import * as React from "react";
import { PopupStyle } from "./Popup.style";
import IconButton from "../elements/IconButton";

interface Props {
    show: boolean;
    onClick?: () => void;
    style?: React.CSSProperties;
    children?: React.ReactNode;
};



const Popup: React.FC<Props> = (props) => {
    const toggle = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    return props.show ?
        <PopupStyle>
            <div style={props.style ? props.style : undefined}>
                {props.onClick ? <IconButton className="close" icon="close" onClick={toggle} title="" /> : null}
                {props.children}
            </div>
        </PopupStyle>
        : null;


}

export default Popup;