import styled from "styled-components";
import { PrimaryColor, BorderColor } from 'global.styles';

export const StyledPage = styled.div`
width: 100vw;
height: 100vh;
display: flex;
animation: fadeIn .5s both;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
//background-size: cover;
background-size: 100% auto;
background-position: center;
background-repeat: no-repeat;

@media(hover:none) and (max-width: 768px)
{
  overflow-y: scroll;
}
&.landingpage{
  @media(max-width: 562px){
    justify-content: flex-start;

    .large img{
      width: 30vh;
      margin-top: 20px;
    }
  }
}
.configurations{
  //display: flex;
  margin-top: 4vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media(min-width: 562px){
    &.small{
      grid-template-columns: 1fr 1fr 1fr;
    }  
    grid-gap: 10px;

  }
  button:not(.size){
    min-width: 160px;
    min-height: 160px;
    border-radius: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    span:last-child{
      font-size: 14px;
      line-height: 20px;
    }
    animation: fadeIn 1s both;
    //width: 100px; 
    //height: 100px;
    padding: 0;
    border: 2px solid ${BorderColor};
    border-radius: 0;
    color: white;
    margin: 0px;
    svg{
      fill: ${PrimaryColor};
      margin: 0;
      margin-bottom: 20px;
    }

    >span:first-child{
      display: block;
      height: 32px;
      margin-top: 53px;
    }
  }     
  
  @media(max-width: 562px){
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    button{
      //width: calc(100vw - 30px) !important;
      height: auto !important;
      min-width: unset !important;
      min-height: unset !important;
      padding: 20px 5px !important;
      flex-direction: column !important;
      margin-bottom: 10px !important;
      justify-content: center !important;
      svg{
        margin-bottom: 0px !important;
        margin-right: 10px !important;
      }
      > span:first-child{
        align-items: center !important;
        display: flex !important;
        margin-top: 0 !important;
      }
    }
    .size{
      left: calc(50% + 35px);
      padding: 0;
      width: 100%;
    }
  }               
}
@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.templates{
  //color: white;
  text-align: left;
  width: 388px;
  margin-top: 3vh;
  button:not(.size){
    color: grey;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    border-top: 1px solid grey;
    border-radius: 0;
    svg{
      fill: grey;
      margin-left: auto;
    }
    &:last-child{
      border-bottom: 1px solid grey;
      font-size: 16px;
    }   
  }
  &.left{
    h5{
      text-align: left !important;        
      font-size: 16px;
    }
    button  span:last-child{
      margin-right: auto;
    }
  }
  h4{
    justify-content: center;
  }

  h4 + div {
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    @media(max-width: 562px){
      transform: translateX(-50%) translateY(calc(-100% - 40px));
    }
  }
  @media(max-width: 562px){
    width: 100%;
    margin-top: 3vh;
    padding: 0px 25px;
    box-sizing: border-box;
  }
  h5{
    margin-top: 0;
    color: white;
    text-align: center;
  }
}

.intro{
  color: white;
  max-width: 592px;
  text-align: center;
  margin-top: 4vh;
  padding: 20px;
  white-space: pre-line;
  @media(max-width: 562px){
    margin: 0;
    font-size: 12px;
    padding: 10px;
  }
}

.contact{
  margin-top: 20px;
  margin-bottom: 20px;  
  svg{
    fill: ${PrimaryColor};
    margin: 0;
    margin-right: 10px;
  }
  a{
    display: flex;
    color: white;
    padding-right: 37px;
    font-size: 23px;
    align-items: center;
    font-weight: bold;
  }
  @media(max-width: 562px){
    margin: 0;
  }
}

a{
  padding: 10px;
  color: #929292;  
  text-decoration: none;
  font-size: 12px;
  &:hover{
    text-decoration: underline;
  }    
}
.terms{
    margin-top: 20px;
    @media(max-width: 562px){
      margin-top: 0;
    }
}

.size{
  position: absolute;
  left: 50%;
  right: 0;
  width: auto;
  border-radius: 0;
  bottom: 0px;
  transform: translate(-50%);
  background: none;
  span:first-child{
    margin-left: 12px;
    svg{
      width: 12px; 
      height: 12px;
    }
  }
}

`;