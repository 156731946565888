import * as React from "react";

import {ReactComponent as Cog} from "../../icons/cog.svg";
import {ReactComponent as Search} from "../../icons/search.svg";
import {ReactComponent as Globe} from "../../icons/earth.svg";
import {ReactComponent as Close} from "../../icons/cross.svg";
import {ReactComponent as Plus} from "../../icons/plus.svg";
import {ReactComponent as Share} from "../../icons/share2.svg";
import {ReactComponent as Save} from "../../icons/floppy-disk.svg";
import {ReactComponent as Dots} from "../../icons/dots.svg";
import {ReactComponent as ArrowLeft} from "../../icons/arrow-left.svg";
import {ReactComponent as ArrowRight} from "../../icons/arrow-right.svg";
import {ReactComponent as Upload} from "../../icons/upload.svg";
import {ReactComponent as Info} from "../../icons/info.svg";
import {ReactComponent as Currency} from "../../icons/database.svg";
import {ReactComponent as Cart} from "../../icons/cart.svg";
import {ReactComponent as Spinner} from "../../icons/spinner8.svg";
import {ReactComponent as DoubleArrow} from "../../icons/double-arrows.svg";
import {ReactComponent as ArrowDown} from "../../icons/arrow-down.svg";
import {ReactComponent as Crop } from "../../icons/crop.svg";
import {ReactComponent as Bulb } from "../../icons/lightbulb.svg";
import {ReactComponent as Phone } from "../../icons/phone.svg";
import {ReactComponent as Mail } from "../../icons/mail.svg";
import {ReactComponent as Type } from "../../icons/type.svg";
import {ReactComponent as Burger } from "../../icons/burger-menu.svg";

export type ICON_TYPE = "cog" | "search" | "globe" | "close" | "save" | "share" | "plus" | "dots" | "upload" | "arrow-left" | "arrow-right" | "arrow-down" |"double-arrows" | "info" | "currency" 
| "cart" | "spinner" | "crop" | "bulb" | "phone" | "mail" | "type" | "burger";  

interface Props{
    name: ICON_TYPE;
}


const RenderIcon = (props:Props) =>
{

    const getIcon = () =>
    {
        switch(props.name)
        {
            case "cog": 
                return <Cog />;
            case "search": 
                return <Search />;
            case "globe": 
                return <Globe />;
            case "close":
                return <Close />;
            case "plus":
                return <Plus />;
            case "save":
                return <Save />;
            case "share":
                return <Share />;
            case "dots":
                return <Dots />;
            case "upload":
                return <Upload />;    
            case "arrow-left":
                return <ArrowLeft />;     
            case "arrow-right":
                return <ArrowRight />;                
            case "double-arrows":
                return <DoubleArrow />;
            case "info":
                return <Info />;
            case "currency":
                return <Currency />;
            case "cart":
                return <Cart />;
            case "spinner":
                return <Spinner />;                
            case "arrow-down":
                return <ArrowDown />;           
            case "crop":
                return <Crop />;                                                
            case "bulb":
                return <Bulb />;                                                
            case "phone":
                return <Phone />;                                                
            case "mail":
                return <Mail />;                                                
            case "type":
                return <Type />;                                                
            case "burger":
                return <Burger />;                                                
            default: 
                return null;
        }
    }


    return getIcon();
}

export default RenderIcon;