import styled from "styled-components";


export const StyledText = styled.div`
position: relative;
padding-top: 20px;
label{
    position: absolute;
    left: 0;
    pointer-events: none;
    top: 50%;
    margin-top: 3px;
    margin-left: 10px;
    transition: all .1s linear;
    user-select: none;
    font-size: 14px;
    &.changed{
        top: 10%;
        margin-top: 0;
        font-size: 10px;
    }
    span{
        color: red;
    }

    &.required{
        color: red;   
    }
}

input{
    width: 100%;
    box-sizing: border-box;
    outline: none;
    &:focus{
        & + label{
            top: 10%;
            margin-top: 0;
            font-size: 10px;
        }
    }
    &[type=number]{
        padding: 10px 10px;
        border: 1px solid #84888C;
    }
    /* prevent zoom on ios input fields*/
    @media(max-width: 769px) and (hover: none){
        font-size: 16px !important;
    }
    
}

&.error input{
    border-color: red;
}
`;