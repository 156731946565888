import * as React from "react";
import styled from "styled-components";
import IconButton from "../elements/IconButton";
import {TrackEvent} from "../../helpers/Tracking";

const StyledCookie = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    background: rgba(255,255,255,.9);
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 99;
    > a{
        margin-left: auto;
        margin-right: auto;
        font-size: 10px;
    }
`;

interface Props{
    text?: string;
}

export const Cookie = (props:Props) =>
{
    const text:string = props.text ? props.text : "";
    const [didAccept, updateAccept] = React.useState(false);
    return (!didAccept ? <StyledCookie>
        <a href="https://flex1one.dk/profil/handelsbetingelser/" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{__html: text}} />
        <IconButton icon="close" className="alternate" onClick={() => {updateAccept(true); setCookie(); TrackEvent("COOKIE","dismissed");}}/>
    </StyledCookie> : null);
}


export const checkCookie = ():boolean =>
{
    if (document.cookie.split(';').filter((item) => item.includes('flex1one-configurator=1')).length) {
        return true;
    }
    return false;
}

export const setCookie = () =>
{
    if (document.cookie.replace(/(?:(?:^|.*;\s*)flex1one-configurator\s*=\s*([^;]*).*$)|^.*$/, "$1") !== "1") {
        
        document.cookie = `flex1one-configurator=1; expires=Fri, 31 Dec ${new Date().getFullYear() + 1} 23:59:59 GMT`;
    }
}