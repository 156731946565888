import React from 'react';
import { getImage } from './utils/utils';
import { RepoState, Product } from 'typings/repo';
import { getModuleInfo } from './state/repo';
import { Module } from 'typings/configuration';
import { useDispatch, useSelector } from 'react-redux';
import { selectModule } from './state/configuration/actions';
import { RootState } from './state/state';

const IMAGE_SIZE = 60;

const ProductLine = (product: Product) =>
{
    return (<><p>{product.title}</p>
        <img width={IMAGE_SIZE} height={IMAGE_SIZE} alt={product.id} src={getImage(product.imageUrl, "THUMBNAIL")} /></>);
}

interface ModuleInfoViewProps
{
    repo: RepoState;
    module: Module;
}

const ModuleInfoView = (props: ModuleInfoViewProps) =>
{
    const dispatch = useDispatch();
    const moduleInfo = getModuleInfo(props.repo, props.module);
    return <div style={{ float: "left", border: "1px solid teal" }} onClick={() => { dispatch(selectModule(undefined)); setTimeout(() => dispatch(selectModule(moduleInfo.id)), 100) }}>
        {moduleInfo.base ? (<ProductLine {...moduleInfo.base} />) : null}
        {moduleInfo.cupboard ? (<ProductLine {...moduleInfo.cupboard} />) : null}
        {moduleInfo.panel ? (<ProductLine {...moduleInfo.panel} />) : null}
        {moduleInfo.tabletop ? (<ProductLine {...moduleInfo.tabletop} />) : null}
        {moduleInfo.cabinet ? (<ProductLine {...moduleInfo.cabinet} />) : null}
    </div>
}
const Debug = () =>
{
    const state = useSelector((state: RootState) => state);
    const r = React.createRef<HTMLDivElement>();    
    React.useEffect(() => {
        if(r.current)
        {
            r.current.addEventListener("touchmove", function(e){ e.preventDefault()});
        }            
    },[]);
    
    return (<div className="configurator" ref={r} style={{  overflow: "auto", backgroundColor: "#CDCDCD", position:"relative" }}>
        <div style={{width: "calc(100vw - 300px)", height: "calc(100vh - 70px)"}}>
        <div style={{ display: "flex" }}>
            {state.configuration.left.map((e) => <ModuleInfoView key={e.id} repo={state.repo} module={e} />)}
        </div>
        <div style={{ display: "flex" }}>
            {state.configuration.corner ? <ModuleInfoView key={state.configuration.corner.id} repo={state.repo} module={state.configuration.corner} /> : null}
        </div>
        <div style={{ display: "flex" }}>
            {state.configuration.right.map((e) => <ModuleInfoView key={e.id} repo={state.repo} module={e} />)}
        </div></div>
    </div>);
}

export default Debug;