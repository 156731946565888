import styled from "styled-components";
import { PrimaryColor } from 'global.styles';

export const StyledLoader = styled.div`
position: fixed;
z-index: 99;
background: #373E44;
width: 100vw;
height: 100vh;
//transition: opacity .2s linear;
pointer-events: none;
div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4em;
    font-weight: bold;
    letter-spacing: 6px;
    color: ${PrimaryColor};
    animation: fadeInOut 2s linear both infinite;
    /*&:before{
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        background: white;
        animation: loopLogo 2s ease-in-out both infinite;
    }*/
}
/*@keyframes fadeInOut{
    0%{
        opacity: .2;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: .2;
    }
}
@keyframes loopLogo{
    0%{
        transform: translateX(0%);
    }
    30%{
        transform: translateX(100%);
    }
    70%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0%);
    }
}*/

&.active{
    opacity: 1;
}
&.in-active{
    opacity: 0;
}
`;