import repo from "../repository-data/grey/repository-grey-en.json";
import uuidv1  from "uuid/v1";
import { RepoAction } from './actions.js';
import { RepoState, Product, Tool, Dictionary } from 'typings/repo';
import { Module, ConfigurationState } from 'typings/configuration';
import { PartType } from 'typings/globals.js';
import { getConfigurationProducts } from 'helpers/configurationHelper';

const initialRepo: RepoState = { ...repo, allProducts: getAllProducts(repo) };

export enum Material {
    Laminate = 'Laminate',
    Metal = 'Metal',
    Wood = 'Wood',
    MetalHD = 'MetalHD',
    WoodHD = 'WoodHD',
    Multiplex = 'Multiplex'
}

const repoReducer = (state = initialRepo, action: RepoAction): RepoState =>
{
    switch (action.type)
    {
        case "SET_REPO":
            state = { ...action.repo, allProducts : getAllProducts(action.repo) };
            break;
        default:
            break;
    }
    return state;
}

export const searchProduct = (search: string, product: Product) => {
    return !search || `${product.id} ${product.title} ${product.subtitle}`.toLowerCase().indexOf(search.toLowerCase()) >= 0;
}

function getAllProducts(state: any) : Dictionary<Product> {
    return state.basecabinets
        .concat(state.basemodules)
        .concat(state.cupboards)
        .concat(state.extra)
        .concat(state.tabletop)
        .concat(state.toolboards)
        .concat(Object.values(state.step3 as Dictionary<Tool>).reduce<Product[]>((list, tool) => {list.push(...tool.items); return list; } ,[]))
        .concat(Object.values(state.step4 as Dictionary<Tool>).reduce<Product[]>((list, tool) => {list.push(...tool.items); return list; } ,[]))
        .reduce((dic: Dictionary<Product>, product: Product) => { dic[product.id] = product; return dic; },{});
}

export interface ModuleInfo {
    id: string;
    base?: Product;
    cupboard?: Product;
    cabinet?: Product;
    panel?: Product;
    tabletop?: Product;
    light: boolean;
}

export const getRandomModule = (repo: RepoState, configuration: ConfigurationState, corner: boolean, tabletop: Material, fullCabinet: boolean = false, canCart: boolean = false, canDouble: boolean = false, isLifted: boolean = false, lifter: string = "" ): Module =>
{
    return {
        id: uuidv1(),
        base: fullCabinet ? repo.basemodules.filter(m => m.isCorner === corner && m.prices['DKK'] !== 0 && (canDouble === m.isDouble || m.isDouble !== true))[0]?.id : repo.basemodules.filter(m => m.isCorner === corner && m.prices['DKK'] === 0 && (canDouble === m.isDouble || m.isDouble !== true))[0]?.id,
        cupboard: fullCabinet ? undefined : repo.cupboards.filter(c => c.isCorner === corner)[0].id,
        cabinet: fullCabinet ? undefined : corner ? repo.basecabinets.find(c => c.isCorner)?.id : getRandomCabinet(repo, configuration, false, canDouble),
        panel: !fullCabinet ? repo.toolboards.find(c => c.isCorner === corner)?.id : undefined,
        tabletop: fullCabinet ? undefined : tabletop,
        light: false,
        canCart: false,
        canFull: false,
        canCupboard: false,
        isLifted: isLifted,
        lifter: lifter
    };
}

export const getRandomCabinet = (repo: RepoState, configuration: ConfigurationState, includeCarts: boolean, canDouble: boolean) : string => {
    var parts = getConfigurationProducts(configuration, repo);
    
    var sortedParts = repo.basecabinets.filter(c => !c.isCorner && (!c.isCart || includeCarts) && (canDouble === c.isDouble || c.isDouble !== true)).map(c => c.id).sort((a: string, b: string) => (parts[a] || 0) - (parts[b] || 0));
    var least = parts[sortedParts[0]] || 0;
    sortedParts = sortedParts.filter(c => (parts[c] || 0) === least);
    
    return sortedParts[Math.floor(Math.random()*sortedParts.length)];
}

export const getRandomProduct = (repo: RepoState, corner: boolean, part_type: PartType) : Product =>
{
    const list = getProductList(repo, part_type).filter(p => (p.isCorner === true) === corner);
    return list[Math.floor(Math.random()*list.length)];
}

export const getProduct = (repo: RepoState, productId?: string) : Product | undefined =>
{
    if(!productId) return undefined;
    return repo.allProducts[productId];
}

const getProductList = (repo: RepoState, part_type: PartType) : Product[] => {
    switch(part_type){
        case "BASE": return repo.basemodules.filter(x => x.isLifter !== true);
        case "CABINET": return repo.basecabinets.filter(c => c.isCart !== true);
        case "CUPBOARD": return repo.cupboards;
        case "LIGHT": return repo.extra;
        case "PANEL": return repo.toolboards;
        case "TABLETOP": return repo.tabletop;
        case "LIFTER": return repo.basemodules.filter(x => x.isLifter === true);
        case "LIFTERMODEL": return repo.basemodules.filter(x => x.isLifter === true);
    }
}

export const getModuleInfo = (repo: RepoState, module: Module) : ModuleInfo =>
{
    return {
        id: module.id,
        base: getProduct(repo, module.base),
        cabinet: getProduct(repo, module.cabinet),
        cupboard: getProduct(repo, module.cupboard),
        //panel: getProduct(repo, module.panel),
        tabletop: getProduct(repo, module.tabletop),
        light: module.light,
    };
}

export const def = <T,Y>(obj: T | undefined, def: Y) : T | Y => {
    return obj ? obj : def;
}

export default repoReducer;