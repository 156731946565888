import * as React from "react";
import IconButton from "../elements/IconButton";
import Logo from "./Welcome/Logo";
import Icon from "../elements/Icon";
import Menu from "../elements/MenuDropDown";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "state/state";
import { setCurrency } from "state/cart/actions";
import { setLanguage } from "state/repo/actions";
import { getPrice } from "helpers/priceHelper";
import { StyledHeader, PriceStyle, Buttons } from "./Header.style";
import { TrackEvent } from "../../helpers/Tracking";
import { getLogo } from "../../utils/utils";

interface Props {
  showPopup: () => void;
  showSave: () => void;
  showProject: () => void;
  showShare: () => void;
  showReset: () => void;
  showProductLine: () => void;
  currentProductLine: (e: string) => void;
}

const Header = (props: Props) => {
  const [toggle, updateSetting] = React.useState(false);
  const state = useSelector((state: RootState) => state);
  const { configuration, cart, repo } = state;
  const tokens = repo.translations;
  const dispatch = useDispatch();

  const getCurrency = (cur: string) => {
    return repo.currency.find((c) => c.currency === cur)?.format ?? "{0}";
  };
  const r = React.createRef<HTMLElement>();
  React.useEffect(() => {
    if (r.current) {
      r.current.addEventListener("touchmove", function (e) {
        e.preventDefault();
      });
    }
  }, []);

  return (
    <StyledHeader className="header" ref={r}>
      <Logo
        className="white"
        src={getLogo(state.repo.langCode, state.repo.project)}
        onClick={() => {
          TrackEvent("NAVIGATION", "reset");
          props.showProject();
        }}
      />
      <PriceStyle className="header-price">
        <div>
          <div className="header-button" onClick={props.showPopup}></div>
          <div className="header-info">
            <p>{tokens.TOTAL_EX_MVA}</p>
            <strong>
              {getCurrency(cart.currency).replace(
                "{0}",
                getPrice(
                  configuration,
                  repo,
                  cart.currency,
                  state.repo.langCode,
                  state.cart.accessories,
                  state.cart.additional
                )
              )}
            </strong>
          </div>
        </div>
      </PriceStyle>
      <Buttons className={`${toggle ? "active" : ""}`}>
        <IconButton
          tooltip={tokens.NEW_PROJECT}
          className="alternate"
          icon="plus"
          onClick={() => {
            props.showProject();
            TrackEvent("NAVIGATION", "new-project");
          }}
          title={tokens.NEW_PROJECT}
        />
        <Menu
          className="header-menu reversed "
          align="left"
          icon="type"
          title={tokens.CHANGE_PRODUCT_LINE}
        >
          <p
            onClick={() => {
              TrackEvent(
                "NAVIGATION",
                "changed-product-line",
                tokens.PRODUCT_NAME
              );
              //if(state.cart.productLine === tokens.PRODUCT_NAME.replace(" ", "")) return;
              props.currentProductLine("FLEXLINE_GREY");
              props.showProductLine();
            }}
          >
            {tokens.PRODUCT_NAME}
          </p>
          <p
            onClick={() => {
              TrackEvent(
                "NAVIGATION",
                "changed-product-line",
                tokens.PRODUCT_NAME_BLACK
              );
              //if(state.cart.productLine === tokens.PRODUCT_NAME.replace(" ", "")) return;
              props.currentProductLine("FLEXLINE_BLACK");
              props.showProductLine();
            }}
          >
            {tokens.PRODUCT_NAME_BLACK}
          </p>
          <p
            onClick={() => {
              TrackEvent(
                "NAVIGATION",
                "changed-product-line",
                tokens.PRODUCT_NAME_HEAVY_DUTY
              );
              //if(state.cart.productLine === tokens.PRODUCT_NAME_HEAVY_DUTY.replace(" ", "")) return;
              props.currentProductLine("HEAVY_DUTY");
              props.showProductLine();
            }}
          >
            {tokens.PRODUCT_NAME_HEAVY_DUTY}
          </p>
        </Menu>

        <IconButton
          tooltip={tokens.SAVE}
          className="alternate"
          icon="save"
          onClick={() => {
            props.showSave();
            TrackEvent("NAVIGATION", "save");
          }}
          title={tokens.SAVE}
        />
        <Menu
          className="header-menu reversed"
          align="left"
          icon="globe"
          title={tokens.LANGUAGE}
        >
          {state.repo.languages.map((lang) => (
            <p
              key={lang.code}
              onClick={() => {
                dispatch(setLanguage(lang.code));
                dispatch(setCurrency(lang.defaultCurrency));
                TrackEvent("NAVIGATION", "set-language", lang.code);
              }}
            >
              {lang.name}
            </p>
          ))}
        </Menu>
        <Menu
          className="header-menu reversed"
          align="left"
          icon="currency"
          title={tokens.CURRENCY}
        >
          {state.repo.currency.map((cur) => (
            <p
              key={cur.currency}
              onClick={() => {
                dispatch(setCurrency(cur.currency));
                TrackEvent("NAVIGATION", "set-currency", cur.currency);
              }}
            >
              {cur.currency}
            </p>
          ))}
        </Menu>
        <IconButton
          tooltip={tokens.SHARE}
          className="alternate"
          icon="share"
          onClick={() => {
            props.showShare();
            TrackEvent("NAVIGATION", "share");
          }}
          title={tokens.SHARE}
        />
        <Menu
          className="header-menu reversed pr-0"
          align="left"
          icon="phone"
          title={tokens.CONTACT}
        >
          <IconButton
            className="alternate"
            icon="phone"
            type="link"
            onClick={(e) => {
              TrackEvent("NAVIGATION", "contact", "phone");
              window.location.href = "tel:" + tokens.INFO_PHONE;
            }}
            title={tokens.INFO_PHONE}
          />
          <IconButton
            className="alternate"
            icon="mail"
            type="link"
            onClick={(e) => {
              TrackEvent("NAVIGATION", "contact", "email");
              window.location.href = "mailto:" + tokens.INFO_MAIL;
            }}
            title={tokens.INFO_MAIL}
          />
        </Menu>
      </Buttons>
      <IconButton
        className="toggle-settings"
        icon={toggle ? "close" : "burger"}
        onClick={() => updateSetting(!toggle)}
      />
      {/*<Menu className="header-menu" align="right" icon="dots">
                <IconButton icon="plus" title="New project" className="alternate" onClick={() => alert("bøh")} />
                <IconButton icon="save" title="Save" className="alternate"  onClick={() => props.showSave()} />
                <IconButton icon="share" title="Share" className="alternate"  onClick={() => alert("bøh")} />
            </Menu>*/}
    </StyledHeader>
  );
};

export default Header;
