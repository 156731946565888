import { PAGES } from "../../typings/cart"
import { Product, Dictionary, ProductType, ProductLine } from '../../typings/repo';
import { CAMERAORIENTATION } from 'typings/globals';
import { StatusType } from '.';

export const setCurrency = (currencyCode: string) =>
{
    return {
        type: "CHANGE_CURRENCY",
        payload: currencyCode
    } 
}

export const changePage = (page: PAGES) =>
{
    return {
        type: "CHANGE_PAGE",
        payload: page
    } 
}

export const setCamera = (camera: CAMERAORIENTATION) =>
{
    return {
        type: "SET_CAMERA",
        payload: camera
    } 
}


export const changePageHideNotification = (page: PAGES) =>
{
    return {
        type: "CHANGE_PAGE_HIDE_NOTIFICATION",
        payload: page
    } 
}


export const changeModuleHideNotification = (page: PAGES) =>
{
    return {
        type: "CHANGE_NOTIFICATION_MODULE",
        payload: page,
        show: false,
        dismissed: true
    } 
}

export const changeSizeHideNotification = (page: PAGES) =>
{
    return {
        type: "CHANGE_NOTIFICATION_SIZES",
        payload: page,
        show: false,
        dismissed: true
    } 
}


export const changeModuleShowNotification = (page: PAGES) =>
{
    return {
        type: "CHANGE_NOTIFICATION_MODULE",
        payload: page,
        show: true,
        dismissed: false
    } 
}


export const showPreview = (url: string) =>
{
    return {
        type: "SHOW_PREVIEW",
        payload: url
    } 
}

export const closePreview = () =>
{
    return {
        type: "CLOSE_PREVIEW"
    } 
}

export const showSidebarPreview = (product: Product | Product, productType?: ProductType) =>
{
    return {
        type: "SHOW_SIDEBAR_PREVIEW",
        sidebar: product,
        productType: productType
    } 
}

export const updateSidebarPreview = (product: Product | Product) =>
{
    return {
        type: "UPDATE_SIDEBAR_PREVIEW",
        sidebar: product
    } 
}

export const closeSidebarPreview = () =>
{
    return {
        type: "CLOSE_SIDEBAR_PREVIEW"
    } 
}

export const closePreloader = () =>
{
    return {
        type: "CLOSE_PRELOADER"
    } 
}


export const addCartImage = (base64: string) => 
{
    return {
        type: "ADD_CART_IMAGE",
        payload: base64
    }
}

export const updateCartAccessory = (id:string, amount: number) =>
{
    return {
        type: "UPDATE_ACCESSORY",
        amount: amount,
        id: id
    } 
}

export const updateCartAdditional = (id:string, amount: number) =>
{
    return {
        type: "UPDATE_ADDITIONAL",
        amount: amount,
        id: id
    } 
}

export const setCartAccessoryAdditional = (accessories?: Dictionary<number>, additional?: Dictionary<number>) =>
{
    return {
        type: "SET_ACCESSORY_ADDITIONAL",
        accessories,
        additional
    } 
}

export const saveConfigurationAction = (storageId?: string) =>
{
    return {
        type: "SAVE_CONFIGURATION",
        id: storageId
    } 
}


export const setConstraint = (width: number, depth: number) =>
{
    return {
        type: "SET_CONSTRAINT",
        constraint: {width: width, depth: depth}
    } 
}


export const setStatus = (status: StatusType) =>
{
    return {
        type: "SET_STATUS",
        status: status
    } 
}


export const setProductLine = (line: ProductLine) =>
{    
    return {
        type: "SET_PRODUCT_LINE",
        productLine: line
    } 
}
