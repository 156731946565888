import styled from "styled-components";

export const CartItemHeader = styled.h4`
    padding: 8px 30px;
    background: lightgrey;
    margin-top: 0;
    margin-bottom: 0;
    font-size: .9em;
    font-weight: normal;
    &.header--status{
        display: flex;
        span:first-child{
            margin-right: auto;
        }
        span.total-amount{
            min-width: 90px;
            text-align: right;
            margin-left: 10px;
        }
    }
`;

export const CartItemStyle = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    margin: 5px 0;
    .desc{
        margin-right: auto;
        h6{
            margin: 0;
            margin-bottom: 8px;
        }
        h4{
            margin: 0;
            margin-bottom: 10px
        }
        p{
            margin: 0;
            font-size: 14px;
        }
    }

    .price{
        margin-left: 10px;
        font-size: .9em;
        min-width: 90px;
        text-align: right;
    }
    .amount{
        font-size: .9em;
    }
    &:last-child{
        border-bottom :none;
    }    
    .cart-total-amount{
        padding: 10px 10px;
        border-top: 1px solid rgba(0,0,0,.05);
        width: 100%;
        text-align: right;
        span{
            display: block;
            font-size: 12px;
        }
        strong{
            font-size: 20px;
            margin-top: 5px;
            display: block;
        }
    }
`;

export const SidebarPreviewHeader = styled.div`
    font-size: .9em;
    > div:nth-child(1){
        display: flex;
        border-bottom: 1px solid rgba(0,0,0,.1);
        > div{
            width: 100%;
            padding: 10px 20px;
            > div{
                h6, h4{
                    margin: 5px 0;
                    //color: teal;
                    
                }                
            }                  
        }
        > button{
            border-left: 1px solid rgba(0,0,0,.5);
            border-radius: 0;
            padding-left: 18px;
            padding-right: 10px;
            opacity: .2;
        }          
    }
    > div:nth-child(2){
        padding: 10px 20px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        background: rgba(0,0,0,.03);
    }
    span, strong{
        font-size: .9em;
        line-height: 1.4em;
        &.bullet{
            margin-right: 5px;
            font-size: 1.4em;
        }
    }
    strong{
        margin-right: 10px;
    }

   
`;

export const SidebarPreviewContainer = styled.div`
    padding: 10px;
    text-align: center;
    background: white;
    margin: 20px;
    border: 1px solid lightgrey;
    min-height: 300px;

    img{
        cursor: zoom-in;
    }
`;