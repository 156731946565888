import { useSelector } from "react-redux";
import { RootState } from './state/state';
import React from 'react';
import { Helmet } from "react-helmet";

const Head: React.FC = () =>
{
    const state = useSelector((state: RootState) => state);
    const tokens = state.repo.translations;

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{tokens.PAGE_TITLE}</title>
        </Helmet>
    );
}

export default Head;