import styled from "styled-components";
import { BackgroundColor } from 'global.styles';

export const StyledContent = styled.div`
position: absolute;
width: calc(100vw - 300px);
height: calc(100vh - 70px);
left: 300px;
top: 70px;
background: ${BackgroundColor};
z-index: 3;
color: black;
text-align: left;
animation: .1s showContent ease-in both;
animation-delay: .35s;
transform-origin: top left;
overflow-y: auto;
//-webkit-overflow-scrolling: auto;
&.large:after,&.medium:after,&.small:after{
    content: "";
    position: fixed;
    background: rgba(0,0,0,.5);
    height: calc(100vh - 70px);
    width: 100vw;
    right: 0px;
    top: 70px;
    pointer-events: none;
}
&.large{
    width: calc(80vw - 300px); 
    &:after{
        width: 20vw;           
    }
}
&.medium{
    width: calc(50vw - 300px);        
    &:after{
        width: 50vw;
    }
}
&.small{
    width: calc(30vw - 300px);        
    &:after{
        width: 70vw;
    }
}

@keyframes showContent{
    0%{
        opacity: 0;
        //transform: scaleX(.9);
    }
    100%{
        opacity: 1;
        //transform: scaleX(1);
    }
}

@media(max-width: 769px){
    width: 100% !important;
    left: 0;
    top: 172px;
    display: none;
    &:after{
        display:none;
    }
    &.tabbed{
        display: block;
    }
    .cart-config-image{
        display: none;
    }
    
}
.toggle-btn{
    padding-left: 30px;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    user-select: none;
    span{
        text-decoration: underline;
    }
}


`;