import userReducer, { UserState } from "./user";
import cartReducer, { CartState } from "./cart";
import repoReducer from "./repo";
import configurationState from "./configuration";
import templateReducer from "./templates";
import { TemplateState, ConfigurationState } from "../typings/configuration";
import { RepoState } from "../typings/repo";
import { CombinedState, combineReducers, Reducer } from "redux";

export default function allReducers(state: { user: UserState; cart: CartState; repo: RepoState; templates: TemplateState; configuration: ConfigurationState; }, action: any)
{
    // always return a new object for the root state
    return {
        user: userReducer(state?.user, action),
        cart: cartReducer(state?.cart, action),
        repo: repoReducer(state?.repo, action),
        templates: templateReducer(state?.templates, action),
        configuration: configurationState(state?.configuration, state?.repo, action)
    }
}

const allReducers2 = combineReducers({
    //modules: productReducer,
    user: userReducer,
    cart: cartReducer,
    repo: repoReducer,
    templates: templateReducer
});

//export default allReducers;
