import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCabinets, getToolboards, getCupboards, getModules } from "../../../services/repo";
import IconButton from "../../elements/IconButton";
import { RootState } from "state/state";
import { showPreview } from "state/cart/actions";
import { getImage } from "../../../utils/utils";
import { Product, RepoState } from 'typings/repo';
import { replaceAt, setPart } from 'state/configuration/actions';
import { getModule } from 'state/configuration';
import { PartType } from 'typings/globals';
import DetailList from './DetailList';
import { ConfigurationState } from "typings/configuration";
import { XLCart } from "helpers/configurationHelper";

interface Props
{
    currentModule?: Product;
}

export const IsNeighborCabinetNotBase = (repo: RepoState, configuration: ConfigurationState, selected: string) => {
    let index = configuration.left.findIndex(x => x.id == selected);
    let configurationSide = configuration.left;
    if(index === -1) {
        index = configuration.right.findIndex(x => x.id == selected);
        configurationSide = configuration.right;
    } 
    let base = repo.basemodules.find(x => x.isBaseModule);
    if(index !== -1) {
        if(index > 0 ) {
            const element = configurationSide[index -1];
            if(element.base === base?.id) 
            {   
            
                return true;
            }
        } 
        if(index < configurationSide.length -1) {
            const element = configurationSide[index + 1];
            if( element.base === base?.id) {
                
                return true;
            }
        }
    }
    return false;
}

export const IsNeighborCabinetEmpty = (repo: RepoState, configuration: ConfigurationState, selected: string, remove: boolean = false, checkBaseCabinet: boolean = false) : number => {
    let index = configuration.left.findIndex(x => x.id === selected);
    let configurationSide = configuration.left;
    if(index === -1) {
        index = configuration.right.findIndex(x => x.id === selected);
        configurationSide = configuration.right;
    } 
    let base = repo.basemodules.find(x => x.isBaseModule);
    if(index !== -1) {
        if(index > 0 ) {
            const element = configurationSide[index -1];
            let isBaseCabinet = repo.basemodules.find(x => x.id === element.base);
            if(element.cabinet === undefined && (!checkBaseCabinet || !isBaseCabinet)) 
            {   
                if(remove && element.base === base?.id) {
                    configurationSide.splice(index-1,1);
                }
                return index -1;
            }
        } 
        if(index < configurationSide.length -1) {
            const element = configurationSide[index + 1];
            let isBaseCabinet = repo.basemodules.find(x => x.id === element.base);
            if(element.cabinet === undefined && (!checkBaseCabinet || !isBaseCabinet)) {
                if(remove && element.base === base?.id ) {
                    configurationSide.splice(index+1,1);
                }
                return index +1;
            }
        }
    }
    return -1;
}

export const canEmptyCabinet = (repo: RepoState, configuration: ConfigurationState, selected: string, laminate: boolean) => {
    let index = configuration.left.findIndex(x => x.id === selected);
    let configurationSide = configuration.left;
    let check = -1;
    if(index === -1) {
        index = configuration.right.findIndex(x => x.id === selected);
        configurationSide = configuration.right;
    } 
    if(!laminate) {
        check = IsNeighborCabinetEmpty(repo, configuration, selected, false, false);

    } 
    else {
        check = IsNeighborCabinetEmptyLaminate(configuration, selected, false);
    }

    if (check === -1) {
        if(index > 1 ) {
            const element = configurationSide[index -2];
            let isBaseCabinet = repo.basemodules.find(x => x.id === element.base)?.isBaseModule;
            if (element.cabinet === undefined && isBaseCabinet) {
                check = index-2;
            }
            
        } 
        if(check === -1) {
            if(index < configurationSide.length -2) {
                const element = configurationSide[index + 2];
                let isBaseCabinet = repo.basemodules.find(x => x.id === element.base)?.isBaseModule;
                if (element.cabinet === undefined && isBaseCabinet) {
                    check = index +2;
                }
            }
        }
    }
    
    return check; 
}
    

export const IsNeighborCabinetEmptyLaminate = (configuration: ConfigurationState, selected: string, remove: boolean = false) : number => {
    let index = configuration.left.findIndex(x => x.id === selected);
    let configurationSide = configuration.left;
    if(index === -1) {  
        index = configuration.right.findIndex(x => x.id === selected);
        configurationSide = configuration.right;
    } 
    if(index !== -1) {
        if(index > 1 ) {
            const element = configurationSide[index -1];
            if(element?.cabinet === undefined) {
                if(remove) {
                    configurationSide.splice(index-1,1);
                }
                const neighborElement = configurationSide[index - 2] 
                if(neighborElement?.cabinet === undefined) {
                    if(remove) {
                        configurationSide.splice(index-2,1);
                    }
                    return index - 2;
                } else if(index < configurationSide.length - 1){
                    const otherNeighborElement = configurationSide[index + 1];
                    if(otherNeighborElement?.cabinet === undefined) {
                        if(remove) {
                            configurationSide.splice(index+1,1);
                        }
                        return index+1;
                    }
                }
                
            }
        } 
        if(index < configurationSide.length -2) {
            const element = configurationSide[index + 1];
            if(element?.cabinet === undefined) {
                if(remove) {
                    configurationSide.splice(index+1,1);
                }
                const neighborElement = configurationSide[index +2] 
                if(neighborElement?.cabinet === undefined) {
                    if(remove) {
                        configurationSide.splice(index+2,1);
                    }
                    return index +2;
                } else if(index > 1){
                    const otherNeighborElement = configurationSide[index - 1];
                    if(otherNeighborElement?.cabinet === undefined) {
                        if(remove) {
                            configurationSide.splice(index-1,1);
                        }
                        return index-1;
                    }
                }
            }
        }
    }
    return -1;
}

const Details = (props: Props) =>
{
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);

    const selected = getModule(state.configuration, state.configuration.selected);
    const isCorner = state.configuration.corner?.id === state.configuration.selected;
    const tokens = state.repo.translations;
    const modules = getModules(state.repo, isCorner, selected?.canFull || false);

    const canXLCabinet = selected?.cabinet === "0244-TSB1300" || selected?.cabinet === "0144-TGC1307WB"  || (selected?.cabinet === undefined && IsNeighborCabinetEmpty(state.repo, state.configuration, state.configuration.selected ?? "") !== -1);
    let canEmpty = true;

    if(canEmptyCabinet(state.repo, state.configuration, state.configuration.selected ?? "", selected?.tabletop === "Laminate") !== -1) {
        canEmpty = false;
    }
    
    const cabinets = getCabinets(state.repo, isCorner, selected?.canCart || false, canXLCabinet, state.configuration.defaultLifter);
    const cupboards = selected?.canCupboard ? getCupboards(state.repo, isCorner) : [];
    const panels = getToolboards(state.repo, isCorner, state.configuration.defaultLifter);
    const isFullCabinet = !state.repo.basemodules.find(m => m.id === selected?.base)?.isBaseModule;
    if (selected == null) return null;

    const onSetPart = (type: PartType, partId?: string) =>
    {
        if (selected) dispatch(setPart(type, selected.id, partId));

        if(partId == XLCart ) {
            //remove empty cabinet -> if it exists.        
               
            let idx = IsNeighborCabinetEmpty(state.repo, state.configuration, state.configuration.selected ?? "", true);

        }
    }

    const onShowPreview = (url?: string) =>
    {
        if (url) dispatch(showPreview(getImage(url, "LARGE")));
    }

    return (
        <div className="product-details" style={{ padding: "30px" }}>
            {/*<StyledDropdown>
                <h5>{tokens.TYPE}</h5>
                <div className={`module-dropdown ${dropdown ? "active" : ""}`}>
                    <div onClick={() => toggleDropdown(!dropdown)}><span>{state.repo.basemodules.filter(x => x.id === selected.base)[0].title}</span><Icon name="arrow-down" /></div>
                    {modules.map((x, i) => {
                    return <Radio key={"md" + i} checked={selected.base === x.id ? true : false} 
                            group="group-md-0" id={"md" + i} value={x.id ? x.id: ""} onChange={(e) => {
                                dispatch(setPart("BASE", selected.id, x.id));
                                toggleDropdown(false)
                                }}>
                        <div>
                            <img alt={x.id} onClick={() => onShowPreview(x.imageUrl)} src={getImage(x.imageUrl, "THUMBNAIL")} />
                            <span><small>{x.id}</small><br/>
                                {x.title} 
                                </span>
                        </div>
                    </Radio>
                    })}
                </div>
                <IconButton className="alternate" icon="close" onClick={() => dispatch(selectModule(undefined))} /><br />
                </StyledDropdown>*/}
            
        {!isFullCabinet ? <div>
            <DetailList title={tokens.CUPBOARD}  list={cupboards} checkedId={selected?.cupboard} type={"CUPBOARD"} onSetPart={onSetPart} onShowPreview={onShowPreview} noProduct={tokens.NO_CUPBOARD} />
            <DetailList title={tokens.PANEL} list={panels} checkedId={selected?.panel} type={"PANEL"} onSetPart={onSetPart} onShowPreview={onShowPreview} noProduct={tokens.NO_PANEL} />
            <DetailList title={tokens.CABINET} list={cabinets} checkedId={selected?.cabinet} type={"CABINET"} onSetPart={onSetPart} onShowPreview={onShowPreview} noProduct={canEmpty && selected.canCart && state.repo.project !== "hd" ? tokens.NO_CABINET : undefined} />
            </div> : null}
            {!isCorner ? <DetailList title={tokens.TYPE} list={modules} checkedId={selected?.base} type={"BASE"} onSetPart={onSetPart} onShowPreview={onShowPreview} /> : null}
        </div>
    );
}

export default Details;
