import * as React from "react";
import styled from "styled-components";
import { SecondaryBackgroundColor, BackgroundColor  } from "../../../global.styles";

interface Props{
    changedTab?: (e:number) => void;
    items?: string[];
    current?: number;
}

const StyledTabs = styled.div`
    background: ${SecondaryBackgroundColor};
    display: flex;
    height: 35px;
    padding: 10px 10px 0 10px;
    > div{
        background: ${BackgroundColor};
        padding: 15px 20px;
        margin: 0 5px;
        display: flex;
        align-items: center;
        opacity: .5;
        cursor: pointer;
        transition: all .1s linear;
        &.active{
            opacity: 1;
            background: ${BackgroundColor};
        }
        user-select: none;
    }
    
`;

const Tabs = (props:Props) =>
{
    const setTab = (e:number) => (evt:React.SyntheticEvent<HTMLDivElement>) =>
    {
        if(props.changedTab)
        {
            props.changedTab(e);
        }
    }
    const r = React.createRef<HTMLDivElement>();    
    React.useEffect(() => {
        
        if(r.current)
        {
            r.current.addEventListener("touchmove", function(e){ e.preventDefault()});
        }       
        return () => {
            if(r.current)
            {
                r.current.removeEventListener("touchmove", function(e){ e.preventDefault()});                
            }
        }
    });

    return (
        <StyledTabs className="tabs" ref={r}>
            {props.items ? props.items.map((e, index) => <div key={index} onClick={setTab(index)} className={props.current === index ? "active" : ""}>{e}</div>) : null}
        </StyledTabs>
    );
}


export default Tabs;