import * as React from "react";
import bg from "images/background.jpg";
import bg2 from "images/background-heavy.jpg";
import bg3 from "images/background-black-tint.jpg";
import Logo from "./Logo";
import IconButton from "components/elements/IconButton";
import Icon from "components/elements/Icon";
import Language from "components/layout/Controls/Language";
import { StyledPage } from "./LandingPage.style";
import { RootState } from "state/state";
import { useSelector, useDispatch } from "react-redux";
import { setCurrency } from "state/cart/actions";
import { setLanguage } from "state/repo/actions";
import { setToTemplate } from "state/configuration/actions";
import { setProductLine } from "state/cart/actions";
import Popup from "components/dialogue/Popup";
import template1 from "images/template1.png";
import template2 from "images/template2.png";
import template3 from "images/template3.png";
import template4 from "images/template4.png";
import template5 from "images/template5.png";
import templatehd1 from "images/template-hd1.png";
import templatehd2 from "images/template-hd2.png";
import templatehd3 from "images/template-hd3.png";
import templatehd4 from "images/template-hd4.png";
import templateBlack2040 from "images/template-black-2040.png";
import templateBlack2955 from "images/template-black-2955.png";
import templateBlack5230 from "images/template-black-5230.png";
import { TrackEvent } from "helpers/Tracking";
import { setDefaultModules } from "helpers/configurationHelper";
import { getLogo } from "utils/utils";

interface Props {
  onNavClick: () => void;
  onSizeClick: () => void;
}

const LandingPage = (props: Props) => {
  const state = useSelector((state: RootState) => state);
  const tokens = state.repo.translations;
  const [templates, updateTemplates] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (window.location.href.indexOf("/HeavyDuty") !== -1) {
      dispatch(setProductLine("FLEXLINE_HEAVY_DUTY"));
      dispatch(setLanguage(state.repo.langCode));
      dispatch({ type: "hd" });
    } else if (window.location.href.indexOf("/FLEXLINE-BLACK") !== -1) {
      dispatch(setProductLine("FLEXLINE_BLACK"));
      dispatch(setLanguage(state.repo.langCode));
      dispatch({ type: "black" });
    } else if (window.location.href.indexOf("/FLEXLINE-GREY") !== -1) {
      dispatch(setProductLine("FLEXLINE_GREY"));
      dispatch(setLanguage(state.repo.langCode));
      dispatch({ type: "grey" });
    }
  }, [dispatch, state.repo.langCode, window.location.pathname]);

  React.useEffect(() => {
    window.onpopstate = () => {
      dispatch(setProductLine(""));
    };
  }, [dispatch]);

  const getIntro = () => {
    if (state.cart.productLine === "FLEXLINE_GREY") {
      const text = tokens.FRONTPAGE_INTRO_FLEXLINE.split("\n").filter(
        (line) => line !== ""
      );
      return text.map((line, i) => <p key={i}>{line}</p>);
    }
    if (state.cart.productLine === "FLEXLINE_BLACK") {
      const text = tokens.FRONTPAGE_INTRO_FLEXLINE_BLACK.split("\n").filter(
        (line) => line !== ""
      );
      return text.map((line, i) => <p key={i}>{line}</p>);
    }
    if (state.cart.productLine === "FLEXLINE_HEAVY_DUTY") {
      const text = tokens.FRONTPAGE_INTRO_HEAVY.split("\n").filter(
        (line) => line !== ""
      );
      return text.map((line, i) => <p key={i}>{line}</p>);
    }
    return tokens.FRONTPAGE_INTRO;
  };

  const getBackgroundImageUrl = () => {
    if (state.repo.project == "Grey") {
      return bg;
    } else if (state.repo.project === "black") {
      return bg3;
    } else if (state.repo.project === "hd") {
      return bg2;
    } else {
      return bg;
    }
  };

  // const handleBackClick = () => {
  //   window.history.back();
  //   // window.history.pushState('data', "", '/');
  // }

  return (
    <StyledPage
      className="landingpage"
      style={{
        backgroundImage: `url(${getBackgroundImageUrl()})`,
      }}
    >
      <Logo
        className="large white font-bold"
        src={getLogo(state.repo.langCode, state.repo.project)}
      />
      <div className="intro">{getIntro()}</div>
      {state.cart.productLine === "" ? (
        <div className="configurations small">
          <IconButton
            className="alternate"
            onClick={() => {
              dispatch(setProductLine("FLEXLINE_GREY"));
              dispatch(setLanguage(state.repo.langCode));
              dispatch({ type: "grey" });
              //window.location.pathname = '/FLEXLINE';
              window.history.pushState("data", "", "/FLEXLINE-GREY");
              TrackEvent("FRONTPAGE", "set-product-line", "FLEXLINE-GREY");
            }}
            icon="plus"
            title={tokens.PRODUCT_NAME}
          />
          <IconButton
            className="alternate"
            onClick={() => {
              dispatch(setProductLine("FLEXLINE_HEAVY_DUTY"));
              dispatch(setLanguage(state.repo.langCode));
              dispatch({ type: "hd" });
              //window.location.pathname = '/HeavyDuty';
              window.history.pushState("data", "", "/HeavyDuty");
              TrackEvent(
                "FRONTPAGE",
                "set-product-line",
                "FLEXLINE_HEAVY_DUTY"
              );
            }}
            icon="plus"
            title={tokens.PRODUCT_NAME_HEAVY_DUTY}
          />
          <IconButton
            className="alternate"
            onClick={() => {
              dispatch(setProductLine("FLEXLINE_BLACK"));
              dispatch(setLanguage(state.repo.langCode));
              dispatch({ type: "black" });
              window.history.pushState("data", "", "/FLEXLINE-BLACK");
              TrackEvent("FRONTPAGE", "set-product-line", "FLEXLINE_BLACK");
            }}
            icon="plus"
            title="FLEXLINE BLACK"
          />
        </div>
      ) : (
        <div className="configurations">
          <IconButton
            className="alternate"
            onClick={() => {
              dispatch(setDefaultModules(state.repo));
              props.onNavClick();
              TrackEvent("FRONTPAGE", "new-configuration");
            }}
            icon="plus"
            title={tokens.NEW_CONFIGURATION}
          />
          <IconButton
            className="alternate size-style"
            onClick={() => {
              props.onSizeClick();
              TrackEvent("FRONTPAGE", "add-constraint");
            }}
            tooltip={tokens.CONSTRAINT_TEXT}
            icon="crop"
            title={tokens.CONSTRAINT}
          />
          <IconButton
            className="alternate size-style"
            onClick={() => {
              updateTemplates(true);
              TrackEvent("FRONTPAGE", "templates");
            }}
            tooltip={tokens.TEMPLATES}
            icon="bulb"
            title={tokens.TEMPLATES}
          />
        </div>
      )}

      {/* <div onClick={handleBackClick}>
      Til forsiden
      </div> */}

      <div className="templates">
        <Language
          languages={state.repo.languages}
          tokens={tokens}
          current={state.repo.langCode}
          changeLanguage={(e) => {
            dispatch(setLanguage(e.code));
            dispatch(setCurrency(e.defaultCurrency));
            TrackEvent("FRONTPAGE", "set-language", e.code);
          }}
        />
      </div>
      {/*<div className="templates left">
          <h5>{tokens.TEMPLATES}</h5>
          <IconButton className="alternate reverse" title={tokens.TEMPLATE_NAME_1} icon="arrow-right" onClick={() => { updateTemplates(true); TrackEvent("FRONTPAGE","templates"); }} />
      </div>*/}
      <div className="contact">
        <a href={`tel:${tokens.INFO_PHONE}`}>
          <Icon name="phone" /> {tokens.INFO_PHONE}
        </a>
      </div>
      <div className="terms">
        <a
          href="https://flex1one.dk/profil/handelsbetingelser/"
          onClick={() =>
            TrackEvent("FRONTPAGE", "external-link", "privacy-policy")
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {tokens.PRIVACY_POLICY}
        </a>
      </div>

      <Popup
        show={templates}
        onClick={() => updateTemplates(!templates)}
        style={{ maxWidth: "920px" }}
      >
        <h2>{tokens.TEMPLATES}</h2>
        <div>
          <div className="template-popup">
            {Object.keys(state.templates.templates).map((key, index) => {
              let templateImage = "";
              //TODO width comes from template name, should probably comes from a better place so this is just temp.
              //image is just from index so far until we know more.
              const s = key.split(" ");
              const w = s[s.length - 1];
              const d = w.split("/");
              const txt = d.length > 1 ? `${d[0]}x${d[1]}` : w;
              switch (state.cart.productLine) {
                case "FLEXLINE_GREY":
                  switch (index) {
                    case 0:
                      templateImage = template1;
                      break;
                    case 1:
                      templateImage = template2;
                      break;
                    case 2:
                      templateImage = template3;
                      break;
                    case 3:
                      templateImage = template4;
                      break;
                    case 4:
                      templateImage = template5;
                      break;
                  }
                  break;
                case "FLEXLINE_BLACK":
                  switch (index) {
                    case 0:
                      templateImage = templateBlack2040;
                      break;
                    case 1:
                      templateImage = templateBlack2955;
                      break;
                    case 2:
                      templateImage = templateBlack5230;
                      break;
                  }
                  break;
                case "FLEXLINE_HEAVY_DUTY":
                  switch (index) {
                    case 0:
                      templateImage = templatehd1;
                      break;
                    case 1:
                      templateImage = templatehd2;
                      break;
                    case 2:
                      templateImage = templatehd3;
                      break;
                    case 3:
                      templateImage = templatehd4;
                      break;
                  }
                  break;
              }

              return (
                <div
                  key={index}
                  onClick={() => {
                    dispatch(setToTemplate(state.templates.templates[key]));
                    updateTemplates(false);
                    props.onNavClick();
                    TrackEvent("FRONTPAGE", "templates", key);
                  }}
                >
                  <div
                    style={{
                      minHeight: "145px",
                      alignItems: "flex-end",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      width={key === "FLEXLINE 2225/2905" ? "175px" : "150px"}
                      alt={key}
                      src={templateImage}
                    />
                  </div>
                  <br />
                  <div>{key}</div>
                  <br />
                  <div className="template-width">
                    <Icon name="double-arrows" />
                    {txt} {tokens.UNITS}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Popup>
    </StyledPage>
  );
};

export default LandingPage;
