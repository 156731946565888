import styled from "styled-components";
import { BorderColor , BackgroundColor, PrimaryColor} from 'global.styles';

export const ToggleStyle = styled.div`
    
h3{
    margin: 0;
}
input{
    display: none;
}
label{
    background: ${BorderColor};
    border-radius: 20px;
    display: inline-block;
    position: relative;
    padding: 3px;
    width: 32px;
    user-select: none;
    cursor: pointer;
    transition: all .1s linear;

    &:after{
        content: "";
        display: block;
        border-radius: 20px;
        background:${BackgroundColor};
        width: 14px;
        height:14px;
        transition: all .5s linear;
    }
}
input:checked + label{
    background: ${PrimaryColor};
}

input:checked + label:after{
    margin-left: auto;

}
> div{
    display: flex;
    align-items: center;
}
span{
    margin-right: auto;
}
padding: 20px 25px;
//border-bottom: 1px solid rgba(0,0,0,.1);

&.disabled{
    opacity: .5;
}
`;