import * as React from "react";
import { StyledText } from './Textfield.style';

interface Props{
    title?: string;
    value? : string;
    required?: boolean;
    type?: string;
    min?: number;
    max?: number;
    onChange?: (e:string) => void;
}

const NumberField = (props:Props) =>
{
    const [changed, updateChange] = React.useState(props.value ? true : false);
    const [error, updateError] = React.useState(false);
    const changeInput = (e:React.SyntheticEvent<HTMLInputElement>) =>
    {
        if(e.currentTarget.value.length > 0)
        {
            updateChange(true);
        }
        else
        {
            updateChange(false);
        }
        if(props.onChange)
        {
            if(props.min && props.max)
            {
                const val = parseInt(e.currentTarget.value);
                if(val < props.min)
                {
                    props.onChange(props.min.toString());
                    updateError(true);
                }
                else if(val > props.max)
                {
                    props.onChange(props.max.toString());
                    updateError(true);
                }
                else
                {
                    props.onChange(e.currentTarget.value); 
                    updateError(false);
                }
            }
            else
            {
                props.onChange(e.currentTarget.value);    
            }            
        }
    }

    return (
    <StyledText className={error ? "error" : ""}>
        <input required={props.required ? true : false} min={props.min} max={props.max} 
        name="t0" type={props.type ? props.type : "text"}  defaultValue={props.value ? props.value : ""} onChange={changeInput} />
        <label htmlFor="t0" className={`${changed ? "changed" : "" }`}>{props.title} {props.required ? <span>*</span> : ""}</label>
    </StyledText>
    );
}

export default NumberField;