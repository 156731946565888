//import nodemailer from 'nodemailer';
//import * as React from 'react';
//import {BillingDocument} from "./BillingDocument";
import './MailHTMLStyle.css';
import { Cart } from '../../typings/cart';
import { ConfigurationState } from 'typings/configuration';
//import ReactPDF from '@react-pdf/renderer';
import { siteUrl, shareUrl, GetProductLineURl } from '../../utils/utils';

import { getPrice, getItemPrice } from 'helpers/priceHelper';
import { RepoState } from 'typings/repo';
import { getProduct } from 'state/repo';
import { getConfigurationProducts } from 'helpers/configurationHelper';

//import router from 'tobedefined';

interface Props
{
    configuration: ConfigurationState, //Order,
    repo: RepoState,
    cart: Cart
}

/*let smtpTransport = nodemailer.createTransport({
    service: 'localhost',
    auth: {
        user: '',
        pass: ''
    }
});*/


/*let smtpTransport = nodemailer.createTransport({
    service: 'mail.smtp2go.com',
    auth: {
        user: 'flex1one-configurator',
        pass: 'aHczeXc3NDUzNnUw'
    }
});*/

const getCurrency = (cur: string, repo:RepoState) =>
{
    return repo.currency.find(c => c.currency === cur)?.format ?? '{0}';
}

export const setMailOptionsAndSend = (props: Props) =>
{
    //const stream = ReactPDF.renderToStream(<BillingDocument cart={props.cart} order={props.order} customer={props.cart.customer} />)
   
    /*
    var mailOptions = {
        from: 'flex-test@heyday.dk',
        to: props.cart.customer.email,
        subject: 'Flex1One Configuration Request For ',
        html:`<html><body>
                <div className="right">
                    <img src="/src/images/logo.png" alt="Flex1One Logo" />
                </div>
                <div>
                    <h3 style="" className="header3">Good day ${props.cart.customer.firstname + ' ' + props.cart.customer.lastname}</h3>
                </div>
                <div className="config">
                    <img src="data:image/png;base64, ${props.cart.configimg}" alt="Configuration Preview"/>
                </div>
                <div>
                    <h3 className="header3">Your Flex1One Configuration</h3>
                    <table>
                        <tr>
                            <td>Reference:</td>
                            <td>Link:</td>
                            <td>Code:</td>
                            <td>Date:</td>
                        </tr>
                        <tr>
                            {
                                <td>{props.cart.ref}</td>
                                <td><a href={props.order.url}>Open configuration in browser.</a></td>
                                <td>Not sure what is.</td>
                                <td>{props.order.date}</td>
                            }
                        </tr>
                    </table>
                </div>
                <div>
                    <h3 className="header3">List price (without VAT)</h3>
                    <p>Total:</p>
                    <p>(Detailed order summary in attached PDF)</p>
                </div>
                <div className="customerinfo">
                    <h3 className="header3">Your information:</h3>
                    <p>${props.cart.customer.firstname + ' ' + props.cart.customer.lastname}</p>
                    <p>${props.cart.customer.address}</p>
                    <p>E-mail: ${props.cart.customer.email}</p>
                    <p>Phone: ${props.cart.customer.phone}</p>
                </div>
            </body></html>`,
        attachments: [
            {
                filename: props.order.ordernumber + '.pdf',
                path: __dirname + '/'+ props.order.ordernumber + '.pdf',
                cid: props.order.ordernumber+'.pdf',
                content: '' // Might want to do a buffer instead of storing all billing documents on server.
            }
        ]
    }*/
    //console.log(mailOptions);
    /*smtpTransport.sendMail(mailOptions, function(error, info){
        if(error){
            console.error('Error sending mail with billing document, errorcode: ', error);
        }
        else{
            console.log('Sent email: ', info.response);
        }
    })*/

}

export const sendShareMail = (email: string, key: string, type: "SHARE" | "SAVE") =>
{
    const send = {
        id: key,
        email: email,
        type: type
    }
    fetch(`${siteUrl}/Mail/SaveMail`, {
        method: 'POST',
        //mode: 'cors',
        //cache: 'no-cache',
        //credentials: 'same-origin', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(send)
      })
    .then(response => response.json())
    .then(data =>
    {
        if(data === "success")
        {
            return "success";
        }
        return "error";
    });
}
 
export const sendMail = (props:Props) =>
{
    var style = "font-family: Arial;";
    var tableStyle = style;
    var tableStyleRight = style + "text-align: right; padding-right: 20px;";
    var cart = props.cart;
    var customer = cart.customer;
    var trans = props.repo.translations;
    const html = `<body>
        <div className="right">
            <img src="http://stage.flex1onekonfigurator.heyday.dk/logo.png" alt="Flex1One Logo" />
        </div>
        <div>
            <h3 style="${style}" >${trans.DEAR} ${customer.firstname + ' ' + customer.lastname}</h3>
        </div>
        <div >
            <img width="200" src="{0}" alt="Configuration Preview"/>
        </div>
        <div>
            <h3 className="header3">${trans.YOUR_FLEXLINE}</h3>
            <table>
                <tr>
                    <td>${trans.REFERENCE_NR}:</td>
                    <td>${trans.LINK}:</td>
                    <td>${trans.DATE}:</td>
                </tr>
                <tr>                    
                    <td>${cart.id}</td>
                    <td style="${style}"><a href="${shareUrl}${GetProductLineURl()}${cart.id}?lang=${props.repo.langCode}&currency=${cart.currency}">${trans.OPEN_CONFIG}</a></td>
                    <td style="${style}">${cart.date.getDate()}/${cart.date.getMonth()+1}/${cart.date.getFullYear()}</td>                    
                </tr>
            </table>
        </div>
        <div>
            <h3 style="${style}" className="header3">${trans.TOTAL_EX_MVA}</h3>
            <p style="${style}">${trans.TOTAL}: ${getCurrency(cart.currency, props.repo).replace('{0}', getPrice(props.configuration, props.repo, cart.currency, props.repo.langCode, cart.accessories, cart.additional))}</p>
            <p style="${style}">(${trans.DETAILED_PDF})</p>
        </div>
        ${props.cart.customer.flexlineuser ? `
      
        <div className="customerinfo">
            <h3 style="${style}">${trans.FLEXLINE_PARTNER}</h3>
            <p style="${style}">${trans.SELLER_NAME}: ${customer.seller}</p>
            <p style="${style}">${trans.ORDER}: ${customer.order}</p>
            <p style="${style}">${trans.DEALER}: ${customer.dealer}</p>
        </div>
        
        ` : ""}
  
        <div className="customerinfo">
            <h3 style="${style}" className="header3">${trans.YOUR_INFORMATION}:</h3>
            <p style="${style}">${customer.firstname + ' ' + customer.lastname}</p>
            <p style="${style}">${customer.address}</p>
            <p style="${style}">${customer.zip} ${customer.city} 
            <br/>${customer.country} 
            </p>
            <p style="${style}">${trans.EMAIL}: ${customer.email}</p>
            <p style="${style}">${trans.PHONE}: ${customer.phone}</p>
            <p style="${style}">${trans.COMMENT}: ${customer.comment}</p>
        </div>
        
    </body>`;
    
    const products = getConfigurationProducts(props.configuration, props.repo);
    const accessories = cart.accessories;
    const additional = cart.additional;
    let items = "";
    Object.keys(products).map((key, i) => {
        const product = getProduct(props.repo, key);
        if (!product) return null;
        items += `<tr>
        <td style="${tableStyle}">${product.id}</td>
        <td style="${tableStyle}">${product.title ? product.title : ""}</td>
        <td style="${tableStyleRight}">${products[key]}</td>
        <td style="${tableStyleRight}">${getItemPrice(product, cart.currency, props.repo.langCode, products[key])}</td>            
        </tr>`;
    });
   if(accessories)
   {
       Object.keys(accessories).map((key, i) => {
        const product = getProduct(props.repo, key);
        const val = accessories ? accessories[key] : 0;
        if (!product) return null;
        if (val === 0) return null; 
        items += `<tr>
        <td style="${tableStyle}">${product.id}</td>
        <td style="${tableStyle}">${product.title ? product.title : ""}</td>
        <td style="${tableStyleRight}">${val}</td>
        <td style="${tableStyleRight}">${getItemPrice(product, cart.currency, props.repo.langCode, products[key])}</td>            
        </tr>`;
        })
    }   
    if(additional)
    {
        Object.keys(additional).map((key, i) => {
            const product = getProduct(props.repo, key);
            const val = additional ? additional[key] : 0;
            if (!product) return null;
            if (val === 0) return null; 
            items += `<tr>
            <td style="${tableStyle}">${product.id}</td>
            <td style="${tableStyle}">${product.title ? product.title : ""}</td>
            <td style="${tableStyleRight}">${val}</td>
            <td style="${tableStyleRight}">${getItemPrice(product, cart.currency, props.repo.langCode, products[key])}</td>            
            </tr>`;
        })
    }
    
    const receipt = `<body style="padding: 20px;">
    <div className="right">
        <img src="http://stage.flex1onekonfigurator.heyday.dk/logo.png" alt="Flex1One Logo" />
    </div>
    <div>
        <h3 style="${style}" className="header3">${trans.DEAR} ${customer.firstname + ' ' + customer.lastname}</h3>
    </div>
    <div className="config">
        <img width="200" src="{0}" alt="Configuration Preview"/>
    </div>
    <div>
        <h3 style="${style}">${trans.YOUR_FLEXLINE}</h3>
        <div style="${style}">                    
        Ref nr: ${cart.id}<br/>
        Date:  ${cart.date.getDate()}/${cart.date.getMonth()+1}/${cart.date.getFullYear()}                  
        </div>

        <table width="850px">
            <tr>
                <td style="${tableStyle}">${trans.PRODUCT_ID}:</td>
                <td style="${tableStyle}">${trans.DESCRIPTION}:</td>
                <td style="${tableStyleRight}">${trans.AMOUNT}:</td>
                <td style="${tableStyleRight}">${trans.SUB_TOTAL}:</td>
            </tr>
            ${items}
        </table>
    </div>
    <div>
        <h3 style="${style}" className="header3">${trans.TOTAL_EX_MVA}</h3>
        <p style="${style}">${trans.TOTAL}: ${getCurrency(cart.currency, props.repo).replace('{0}', getPrice(props.configuration, props.repo, cart.currency, props.repo.langCode))}</p>
    </div>
    ${props.cart.customer.flexlineuser ? `
      
    <div className="customerinfo">
        <h3 style="${style}">${trans.FLEXLINE_PARTNER}</h3>
        <p style="${style}">${trans.SELLER_NAME}: ${customer.seller}</p>
        <p style="${style}">${trans.ORDER}: ${customer.order}</p>
        <p style="${style}">${trans.DEALER}: ${customer.dealer}</p>
    </div>
    
    ` : ""}
    <div className="customerinfo">
        <h3 style="${style}" className="header3">${trans.YOUR_INFORMATION}:</h3>
        <p style="${style}">${customer.firstname + ' ' + customer.lastname}</p>
        <p style="${style}">${customer.address}</p>
        <p style="${style}">${customer.zip} ${customer.city} 
        <br/>${customer.country} 
        </p>
        <p style="${style}">${trans.EMAIL}: ${customer.email}</p>
        <p style="${style}">${trans.PHONE}: ${customer.phone}</p>
    </div>
</body>`;

    const order = {
        id: props.cart.id,
        email: customer.email + ',' + "salg@FLEX1ONE.dk",
        html: html,
        receipt: receipt,
        subject: trans.FLEXLINE,
        image: cart.configimg 
    }
    fetch(`${siteUrl}/Mail/SendMail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(order)
      })
    .then(response => response.json())
    .then(data =>
    {
        if(data === "success")
        {
            return "success";
        }
        return "error";
    });
    return "error0";
}
