//import nodemailer from 'nodemailer';
//import * as React from 'react';
//import {BillingDocument} from "./BillingDocument";
import "./MailHTMLStyle.css";
import { Cart } from "../../typings/cart";
import { ConfigurationState } from "typings/configuration";
//import ReactPDF from '@react-pdf/renderer';
import { siteUrl, shareUrl, getLogo, GetProductLineURl } from "../../utils/utils";

import { getPrice, getItemPrice } from "helpers/priceHelper";
import { RepoState, Dictionary, Product } from "typings/repo";
import { getProduct } from "state/repo";
import { getConfigurationProducts } from "helpers/configurationHelper";
import { Order } from "typings/email";
import { getProductLine } from "utils/utils";


//import router from 'tobedefined';

interface Props {
  configuration: ConfigurationState; //Order,
  repo: RepoState;
  cart: Cart;
}

const getCurrency = (cur: string, repo: RepoState) => {
  return repo.currency.find((c) => c.currency === cur)?.format ?? "{0}";
};

export const sendShareMail = (
  props: Props,
  email: string,
  sender?: string,
  senderEmail?: string,
  message?: string
) => {
  var cart = props.cart;
  var trans = props.repo.translations;

  const sendOrderFlexSpy = {
    id: props.cart.id,
    email: "math@flex1one.dk,mial@flex1one.dk,jnie@flex1one.dk",
    html: getShareEmail(props, email, sender, senderEmail, message, true),
    subject: "GEMT " + trans.FLEXLINE,
    image: cart.configimg,
    bcc: null,
  };

  const sendOrder = {
    id: props.cart.id,
    email: senderEmail ? email + "," + senderEmail : email,
    html: getShareEmail(props, email, sender, senderEmail, message, false),
    subject: trans.FLEXLINE,
    image: cart.configimg,
    bcc: null,
  };

  postMail(sendOrder);
  postMail(sendOrderFlexSpy);
};

export const sendMail = (props: Props) => {
  var cart = props.cart;
  var customer = cart.customer;
  var trans = props.repo.translations;

  const customerOrder = {
    id: props.cart.id,
    email: customer.dealerEmail ? customer.dealerEmail : customer.email,
    bcc: "marketing@flex1one.dk",
    html: getCustomerEmail(props),
    subject: trans.FLEXLINE,
    image: cart.configimg,
  };

  const internalOrder = {
    id: props.cart.id,
    email: "salg@FLEX1ONE.dk",
    bcc: "marketing@flex1one.dk",
    html: getInternalHtml(props),
    subject: trans.FLEXLINE,
    image: cart.configimg,
  };

  postMail(customerOrder);
  postMail(internalOrder);
};

const postMail = (order: Order) => {
  fetch(`${siteUrl}/Mail/SendMail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(order),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data === "success") {
        return "success";
      }
      return "error";
    });
  return "error0";
};

const getShareLogo = (langCode: string) => {
  let productLine = getProductLine();
  if (langCode && productLine) {
    return `flexline_${productLine}_logo_${langCode}.png`;
  }
};

const getCustomerEmail = (props: Props) => {
  var style = "font-family: Tahoma, Helvetica, sans-serif;";
  var cart = props.cart;
  var customer = cart.customer;
  var trans = props.repo.translations;
  var customerName = customer.seller
    ? customer.seller
    : customer.firstname + " " + customer.lastname;

  let productLine = getProductLine();
  const getProductNameTrans = () => {
    if (productLine === "grey") {
      return trans.PRODUCT_NAME;
    } else if (productLine === "hd") {
      return trans.PRODUCT_NAME_HEAVY_DUTY;
    } else if (productLine === "black") {
      return trans.PRODUCT_NAME_BLACK;
    }
  };

  const getProductLinkTrans = () => {
    if (productLine === "grey") {
      return trans.OPEN_LINK;
    } else if (productLine === "hd") {
      return trans.OPEN_LINK_HD;
    } else if (productLine === "black") {
      return trans.OPEN_LINK_BLACK;
    }
  };

  const productNameTrans = getProductNameTrans();
  const productLinkTrans = getProductLinkTrans();

  const html = `<body>
    <div>
        <img width="400" src="{0}" alt="Configuration Preview"/>
    </div>
    <p style="${style} width: 400px; text-align: left; font-size: 12px; color: grey;" >${
    trans.YOUR_FLEXLINE
  }</p>
    <p style="${style} font-size: 12px;" ><span style="margin-right: 60px;">${
    trans.REFERENCE_NR
  }: ${cart.id}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <span>${trans.DATE}: ${cart.date.getDate()}/${
    cart.date.getMonth() + 1
  }/${cart.date.getFullYear()}</span></p>
    <div>
        <h3 style="${style} font-weight: bold;" >${
    trans.DEAR
  } ${customerName}</h3>
    </div>
    <div>
    <p  style="${style} font-size: 14px;">${
    customer.dealer ? trans.DEALER_THANKS : trans.REQUEST_THANKS
  }</p>
    </div>
    <div>
        <p style="${style} font-size: 14px;"> ${
    trans.OPEN_CONFIG
  } <a style="font-weight: bold; text-transform:uppercase;color: black;" href="${shareUrl}${GetProductLineURl()}/${
    cart.id
  }?lang=${props.repo.langCode}&currency=${
    cart.currency
  }">${productLinkTrans}</a></p>
    </div>
    <div style="${style} font-weight: bold;">
        <img src="${shareUrl}/${getShareLogo(props.repo.langCode)
        }" alt="${productNameTrans} BY AHCON - ${trans.TAG_LINE}" width="200" />
    </div>
</body>`;

  return html;
};

const getShareEmail = (
  props: Props,
  email?: string,
  senderName?: string,
  senderEmail?: string,
  message?: string,
  copy?: boolean
) => {
  var style = "font-family: Tahoma, Helvetica, sans-serif;";
  var cart = props.cart;
  var customer = cart.customer;
  var trans = props.repo.translations;

  var hasSender = senderEmail !== undefined && senderEmail.length > 0;
  let productLine = getProductLine();
  const getProductNameTrans = () => {
    let trans = props.repo.translations;
    if (productLine === "grey") {
      return trans.PRODUCT_NAME;
    } else if (productLine === "hd") {
      return trans.PRODUCT_NAME_HEAVY_DUTY;
    } else if (productLine === "black") {
      return trans.PRODUCT_NAME_BLACK;
    }
  };

  const getProductLinkTrans = () => {
    if (productLine === "grey") {
      return trans.OPEN_LINK;
    } else if (productLine === "hd") {
      return trans.OPEN_LINK_HD;
    } else if (productLine === "black") {
      return trans.OPEN_LINK_BLACK;
    }
  };

  const productNameTrans = getProductNameTrans();
  const productLinkTrans = getProductLinkTrans();

  const html = `<body>
    ${
      copy
        ? `<div style="${style}"><p>GEMT AF : ${senderEmail ?? email}</p></div>`
        : ""
    }
    <div>
        <img width="400" src="{0}" alt="Configuration Preview"/>
    </div>
    ${
      !hasSender
        ? `<p style="${style} width: 400px; text-align: left; font-size: 12px; color: grey;" >${trans.YOUR_FLEXLINE}</p>`
        : ""
    }
    ${
      hasSender
        ? `<p style="${style} width: 400px; text-align: left; font-size: 12px; color: grey;" >${trans.SHARE_FLEXLINE_TITLE}</p>`
        : ""
    }

    ${
      !hasSender
        ? `<div>
        <h3 style="${style}" >${trans.FLEXLINE}</h3>
    </div>`
        : ""
    }
    ${
      !hasSender
        ? `<div>
        <p style="${style} font-size: 14px;" >${trans.SHARE_THANKS}</p>
    </div>`
        : ""
    }
    ${
      hasSender
        ? `<div>
        <p style="${style}">${trans.SHARE_GREET}</p>    
        <p style="${style}">${senderName} har delt en FLEXLINE konfiguration med dig.</p><br/>        
        <p style="${style}">${message}</p><br/>
        <p style="${style}" font-size: 14px;" >${trans.SHARE_OPEN_CONFIG} <a style="text-transform: uppercase; font-weight: bold; color:black;" href="${shareUrl}${GetProductLineURl()}/${cart.id}?lang=${props.repo.langCode}&currency=${cart.currency}">${trans.SHARE_OPEN_LINK}</a></p>
        <p style="${style}">Denne konfiguration blev delt af ${senderEmail}</p>
        
    </div>`
        : ""
    }
    ${
      !hasSender
        ? `<p style="${style} font-size: 14px;">${trans.OPEN_CONFIG} <a style="text-transform: uppercase; font-weight: bold; color:black;" href="${shareUrl}${GetProductLineURl()}/${cart.id}?lang=${props.repo.langCode}&currency=${cart.currency}">${productLinkTrans}</a></p>`
        : ""
    }
   
    <div style="${style} font-weight: bold;">
        <img src="${shareUrl}/${getShareLogo(props.repo.langCode)
        }" alt="${productNameTrans} BY AHCON - ${trans.TAG_LINE}" width="200" />
    </div>
</body>`;

  return html;
};

const getInternalHtml = (props: Props) => {
  var style = "font-family: Tahoma, Helvetica, sans-serif;";
  var cart = props.cart;
  var customer = cart.customer;
  var trans = props.repo.translations;

  const html = `<body>
    <div >
        <img width="400" src="{0}" alt="Configuration Preview"/>
    </div>
    <p style="${style} width: 400px; text-align: left; font-size: 12px; color: grey;" >${
    trans.YOUR_FLEXLINE
  }</p>

    <div>
        <p style="${style} font-size: 12px;"><span style="margin-right: 60px;">Reference nr.: ${
    cart.id
  }</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Dato: ${cart.date.getDate()}/${
    cart.date.getMonth() + 1
  }/${cart.date.getFullYear()}</span></p>
    </div>
    <div>
        <p style="${style}"> Husk at sende en ordrebekræftelse til kunden. </p>
    </div>

    <p><a style="${style} text-transform: uppercase; font-weight: bold; color:black;" href="${shareUrl}${GetProductLineURl()}/${
    cart.id
  }?lang=${props.repo.langCode}&currency=${
    cart.currency
  }">SE FLEXLINE ${GetProductLineURl()} KONFIGURATIONEN</a></p>

    <div>
        <h3 style="${style}" className="header3">Stykliste</h3>
        ${itemsToHtml(props)}
    </div>

    <div>
    ${
      props.cart.customer.dealerEmail
        ? `
    <div className="customerinfo" style="width: 50%; float:left;">
        <h3 style="${style}">FLEXLINE forhandler information</h3>
        <p style="${style}">${customer.dealerFirmname}</p>
        <p style="${style}">${trans.SELLER_NAME}: ${customer.seller}</p>
        <p style="${style}">${customer.dealerAddress}</p>
        <p style="${style}">${customer.dealerDepartment}</p>
        <p style="${style}">${customer.dealerZip} ${customer.dealerCity}
        <br/>${customer.dealerCountry}
        </p>
        <p style="${style}">${trans.EMAIL}: ${customer.dealerEmail}</p>
        <p style="${style}">${trans.PHONE}: ${customer.dealerPhone}</p>
        <p style="${style}">${trans.COMMENT}: ${customer.dealerComment}</p>

        <p style="${style}">Type: ${customer.order}</p>
    </div>

    `
        : ""
    }

    <div className="customerinfo"  style="width: 50%; float:left;">
        <h3 style="${style}" className="header3">Kundeinformation:</h3>
        <p style="${style}">${customer.firmname}</p>
        <p style="${style}">${customer.firstname + " " + customer.lastname}</p>
        <p style="${style}">${customer.address}</p>
        <p style="${style}">${customer.zip} ${customer.city}
        <br/>${customer.country}
        </p>
        <p style="${style}">${trans.EMAIL}: ${customer.email}</p>
        <p style="${style}">${trans.PHONE}: ${customer.phone}</p>
        <p style="${style}">${trans.COMMENT}: ${customer.comment}</p>
        <p style="${style}">${trans.DEALER}: ${customer.dealer}</p>
    </div>
    </div>
    ${
      customer.delivery &&
      `<h3 style="${style}">Leveres til: ${customer.delivery} </h3>`
    }


</body>`;
  return html;
};

const itemsToHtml = (props: Props) => {
  var style = "font-family: Tahoma, Helvetica, sans-serif;";
  var cart = props.cart;
  const products = getConfigurationProducts(props.configuration, props.repo);
  const accessories: Dictionary<number> = cart.accessories;
  const additional: Dictionary<number> = cart.additional;
  let items = "";
  if (products) {
    items += loopThroughProductElements(
      products,
      props.repo,
      cart,
      props.repo.langCode
    );
  }
  if (accessories) {
    items += loopThroughProductElements(
      accessories,
      props.repo,
      cart,
      props.repo.langCode
    );
  }
  if (additional) {
    items += loopThroughProductElements(
      additional,
      props.repo,
      cart,
      props.repo.langCode
    );
  }

  return `<table style="width: 90%; border-collapse: collapse;">
    <tr>
        <td style="${style} font-size: 14px;"><strong>Varenr:</strong></td>
        <td style="${style} font-size: 14px;"><strong>Varenavn:</strong></td>
        <td style="${style} font-size: 14px; text-align: right;"><strong>Antal:</strong></td>
        <td style="${style} font-size: 14px; text-align: right; padding-left: 20px;"><strong>Pris:</strong></td>
    </tr>
    ${items}
    <tr>
        <td colspan="4" style="text-align: right;"><br/>
            <span style="${style} >${
    props.repo.translations.TOTAL_EX_MVA
  }</span><br/>
            <strong style="${style}">${
    props.repo.translations.TOTAL
  }: ${getCurrency(cart.currency, props.repo).replace(
    "{0}",
    getPrice(
      props.configuration,
      props.repo,
      cart.currency,
      props.repo.langCode,
      cart.accessories,
      cart.additional
    )
  )}</strong>
        </td>
    </tr>
    </table>`;
};

const loopThroughProductElements = (
  dictionary: any,
  repo: any,
  cart: Cart,
  langCode: string
) => {
  let items = "";
  Object.keys(dictionary).map((key, i) => {
    const product = getProduct(repo, key);
    const quantity = dictionary ? dictionary[key] : 0;
    if (!product) return null;
    if (quantity === 0) return null;
    items += writeSingleItem(product, cart.currency, langCode, quantity);
  });

  return items;
};

const writeSingleItem = (
  product: Product,
  currency: string,
  langCode: string,
  quantity: number
) => {
  var style =
    "font-family: Tahoma, Helvetica, sans-serif; border-bottom: 1px solid black; padding: 5px 0;";
  var tableStyle = style;
  var tableStyleRight = style + "text-align: right; ";
  const item = `
    <tr>
        <td style="${tableStyle} font-size: 14px;">${product.id}</td>
        <td style="${tableStyle} font-size: 14px;">${
    product.title ? product.title : ""
  }</td>
        <td style="${tableStyleRight}  font-size: 14px;">${quantity}</td>
        <td style="${tableStyleRight}  font-size: 14px; padding-left: 20px;">${getItemPrice(
    product,
    currency,
    langCode,
    quantity
  )}</td>
    </tr>`;

  return item;
};

const flex1OneInformation = (trans: any, style: string) => {
  return `<div className="customerinfo">
        <p style="${style}">
        ${trans.INFO_FLEXONE}<br/>
        ${trans.INFO_ADDRESS}<br/>
        ${trans.INFO_CITY}<br/>
        ${trans.INFO_PHONE}<br/>
        ${trans.INFO_MAIL}</p>
    </div>`;
};
