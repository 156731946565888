import styled from "styled-components";
import {PrimaryColor, SecondaryColor} from 'global.styles';

export const StyledSidebarNav = styled.div`
height: 60px;
display: flex;
button{
    border-radius: 0;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    &:first-child{
        justify-content: center;
        padding: 10px;
        width: 70px;
        background: #5C656D;
        svg{
            fill: ${PrimaryColor};
            margin-right: 0;
        }
    }
    &:last-child{
        flex: 1;
    }
}

@media(max-width: 769px){
    display:none;
}
`;

export const StyledSidebar = styled.div`
@media(max-width: 769px){
    > div:nth-child(2) h2{
        margin-left: 20vw;
    }
    > div:nth-child(3) h2{
        margin-left: 40vw;
    }
    > div:nth-child(4) h2{
        margin-left: 60vw;
    }
    > div:nth-child(5) h2{
        margin-left: 80vw;
    }
    height: 58px;
    &.active{
        height: auto;
    }

}
&.toggled{
    //animation: none;
    //transform: translate(-100%);
    //display: none;
    left: -100%;
}
.constraint-notification{
    background: red;
    position: fixed;
    z-index: 98;
    color: white;
    text-align: center;
    top: 50%;
    padding: 20px;
    left: 50%;
    width: 20%;
    height: auto;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    animation .5s showConstraint both;
    transform: translate(-50%, -50%);
    line-height: 20px;
    @media(max-width: 769px){
        width: 90%;
    };
    flex-direction: column;
    p{
        margin: 0;
        line-height: 15px;
    }
    button{
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 0;
        svg{
            fill: white !important;
            stroke: transparent !important;
            width: 12px !important;
            height: 12px !important;
        }
    }

}

.product-details{
    position: relative;
    animation: showDetails .2s ease-out;
    > button{
        position: absolute;
        right: 10px;
        margin-top: -20px;
    }
}

.checkout--mobile{
    //width: 100%;
    display: none;
    /*position: fixed;
    top: calc(100vh - 104px);
    z-index: 2;
    left: 0;
    border-radius: 0;
    display: none;
    animation: showCheckout .5s ease-in;*/
    color: white;
    background: #F6AD30;
    padding: 10px 20px;
    text-align: center;
    margin-top: 10px;
}


//hide sidebar when loading
&:before{
    content: "";
    position: absolute;
    width: 300px;
    height: 100%;
    background: white;
    left: 0;
    top: 0;
    z-index: 6;
    animation: hideLoadOverlay 1s both;
}

.terms-holder{
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    > div{
        margin-top: 0;
    }
    a{
        font-size: 14px;
        color: black;
    }
}

.cart-separator{
    border: none;
    height: 1px;
    background: ${PrimaryColor};
    margin: 15px 0;
}

@keyframes hideLoadOverlay{
    0%{
        display: block;
        left: 0;
    }
    99%{
        display: block;
        left: 0;
    }
    100%{
        left: -100%;
        visibility: hidden;
    }

}

@keyframes showConstraint{
    0%{
        opacity: 0;
        transform: scale(.9) translate(-50%, -50%);
    }
    100%{
        opacity: 1;
        transform: scale(1) translate(-50%, -50%);
    }
}

@keyframes showCheckout{
    0%{
        top: calc(100vh);
    }
    100%{
        top: calc(100vh - 104px);
    }
}

@media(max-width: 769px){
    .checkout--mobile{
        display: block;
    }
}

@keyframes showDetails{
    0%{
        opacity: 0;
        margin-top: 100px;
    }
    100%{
        opacity: 1;
        margin-top: 0px;
    }
}
`;

export const SidebarToggle = styled.div`
    position: absolute;
    top: 70px;
    left: 300px;
    background: transparent;
    border-radius: unset;
    border-left: 28px solid #5C656D;
    border-top: 28px solid  #5C656D;
    border-right: 28px solid transparent;
    border-bottom: 28px solid transparent;
    animation: .3s slideToggleIn ease-out both;
    animation-delay: .8s;
    z-index: 1;
    display: none;
    button.empty{
        background: transparent;
        border-radius: unset;
        height: 27px;
        width: 27px;
        position: absolute;
        top: -23px;
        left: -22px;
        padding: 0;
        svg{
            transition: transform .2s ease-in-out;
            width: 17px;
            height: 17px;
        }
    }

    @keyframes slideToggleIn{
        0%{
            opacity: 0;
            top: 17px;
        }
        10%{
            opacity: 1;
        }
        100%{
            top: 70px;
        }
    }
    &.active{
        left: 0;
        button span svg{
            transform: rotate(180deg);
        }
    }

    @media(min-width: 769px){
        &:not(.mobile-toggle){
            display: block;
        }
    }
`;

export const HideContent = styled.div`
    position: absolute;
    top: 70px;
    //background: teal;
    width: calc(100% - 300px);
    z-index: 2;
    left: 300px;
    height: calc(100vh - 70px);
`;