import { RepoState, Product, Tool } from '../typings/repo';

export const getBaseCabinet = (repo: RepoState, id:string):Product =>
{
    /* apply ruleset so check if product can be chosen */
    const cab = repo.basecabinets.filter(x => x.id === id);
    return cab[0];
}

export const getModules = (repo: RepoState, corner:boolean, includeFull: boolean):Product[] =>
{   
    /* apply ruleset so check if product can be chosen */
    return repo.basemodules.filter(x => (corner === (x.isCorner || false)) && ((!corner ? includeFull ? true : x.id === "0144-GM" : true) && !x.isLifter));
}

export const getCabinets = (repo: RepoState, corner:boolean, includeCarts: boolean, includeCartsXL: boolean = false, isLifted: boolean = false):Product[] =>
{   
    /* apply ruleset so check if product can be chosen */
    return repo.basecabinets.filter(x => corner === x.isCorner && (includeCarts || !x.isCart) && (includeCartsXL || x.id !== "0144-TGC1307WB") && (!isLifted || x.id !== "0244-TSB1300"))
}

export const getCupboards = (repo: RepoState, corner?:boolean):Product[] =>
{   
    /* apply ruleset so check if product can be chosen */
    return repo.cupboards.filter(x => corner ? x.isCorner : !x.isCorner)
}

export const getTabletop = (repo: RepoState, corner?:boolean):Product[] =>
{   
    /* apply ruleset so check if product can be chosen */
    return repo.tabletop.filter(x => corner ? x.isCorner : !x.isCorner)
}

export const getToolboards = (repo: RepoState, corner: boolean = false, isLifted: boolean = false):Product[] =>
{   
    /* apply ruleset so check if product can be chosen */
    
    return repo.toolboards.filter(x => (corner ? x.isCorner : !x.isCorner) &&  (isLifted ? (x.id !== "0147-WGB1324K-B" && x.id !== "0144-WGB1324K" ) : true))
}

export interface Group {
    id: string;
    title: string;
    items: Product[]
}

export const getStep3 = (repo: RepoState):Group[] =>
{   
    /* apply ruleset so check if product can be chosen */
    return Object.keys(repo.step3).map(k => ({id:k,...repo.step3[k]}));
}

export const getStep3Item = (repo: RepoState, id?:string):Product | undefined =>
{
    var groups = Object.values<Tool>(repo.step3);
    for(var value in groups) {
        var tools = Object.values<Product>(groups[value].items);
        for(var item in tools) {
            if(tools[item].id === id) return tools[item];
        }
    }
    return undefined;
}

export const getStep4 = (repo: RepoState):Group[] =>
{   
    /* apply ruleset so check if product can be chosen */
    let additional = [];
    const arr = Object.values(repo.step4);
    const keys = Object.keys(repo.step4);
    for(var i in arr)
    {
        const obj = {
            id: keys[i],
            ...arr[i]
        }
        additional.push(obj);
    }    
    return additional as Group[];
}

export const getStep3List = (repo: RepoState, id: string):Group[] =>
{   
    /* apply ruleset so check if product can be chosen */
    let accessories = [];
    const arr = Object.values(repo.step3);
    const keys = Object.keys(repo.step3);
    for(var i in arr)
    {
        const obj = {
            id: keys[i],
            ...arr[i]
        }
        accessories.push(obj);
    }
    return accessories as Group[];
}


