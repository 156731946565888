import styled from "styled-components";
import { PrimaryColor } from 'global.styles';


export const StyledPreview = styled.div`
animation: showPreview .3s both;
position: fixed;
left: 0;
top: 0;
width: 100vw;
height: 100vh;
z-index: 99;
background: rgba(0,0,0,.9);
> button{
    margin-left: auto;
    margin-right: 10px;
    margin-top: 10px;
}

.close{
    background: none;
}
svg{
    stroke: rgba(0,0,0,.9) !important;
    stroke-width: 4px;
    fill: ${PrimaryColor};
}


>div{
    animation: showPreviewImage .3s both;

    position: absolute;
    top: calc(50% - 45vh);
    left: calc(50% - 45vw);
    transform: translate(-50%,-50%);
    width: 90vw;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

    > img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

@keyframes showPreviewImage{
    0%{
        opacity: 0;
        transform: scale(.5);
    }
    100%{
        opacity: 1;            
        transform: scale(1);
    }
}

@keyframes showPreview{
    0%{
        opacity: 0;  
    }
    100%{
        opacity: 1;  
    }
}
`;