import * as React from "react";

import styled from "styled-components";
import { PrimaryColor } from "../../global.styles";
const StyledButton = styled.button`
    padding: 10px 30px;
    background: ${PrimaryColor};
    color: white;
    border-radius: 30px;
    border: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    &:disabled{
        opacity: .5;
    }
`;


export const StyledLink = styled.a`
    padding: 10px 30px;
    background: ${PrimaryColor};
    color: white;
    border-radius: 30px;
    border: none;
    text-decoration: none;    
`;

interface Props{
    children?: React.ReactChildren;
    title?: string | JSX.Element;
    onClick: () => void;
    className? : string;
    disabled?: boolean;
}

const Button = (props:Props) =>
{
    return (
        <StyledButton className={props.className} disabled={props.disabled ? true : false} onClick={props.onClick}>{props.title}</StyledButton>
    );
}

export default Button;