import styled from "styled-components";
import { PrimaryColor, SecondaryColor } from 'global.styles';

export const StyledHeader = styled.header`
.logo{
    width: 300px;
    text-align: center;
    cursor: pointer;
    h5, h6{
        margin-left: 0;
    }
}
.toggle-settings{
    display: none;
}  
  
 
&:before{
    content: "";
    width: 300px;
    height: 50px;
    background: ${SecondaryColor};
    position: absolute;
    left: 0;
    z-index: -1;
    height: 70px;
}
@media(max-width: 769px){
    .logo{
        width: calc(50% - 22px);
        margin-left: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${SecondaryColor};
        img{
            max-width: 66%;
            height: auto;
            object-fit: cover;
            padding: 10px;
            box-sizing: border-box;
            margin-right: 22px;
        }
    }   
    
    .toggle-settings{
        display: block;
        height: 70px;
        border-radius: 0;
        position: absolute;
        left: 0;
        background: transparent;
    }     
    &:before{
        width: 50%;
    }

    .header-price{
        width: calc(50% - 20px);
        .header-info{
            margin-left: 0;
            padding: 19px;
            width: 100%;
            p{
            }
            strong{
                display: block;
                font-size: 17px;
                text-align: left;
            }
        }

        & + .active{
            display: block;
            height: calc(100vh - 70px);
            width: 90%;
            padding: 20px 0px 0px 20px;

            &:after{
                content: "";
                display: block;
                background: linear-gradient(-90deg,rgba(0,0,0,0) 0%,rgba(0,0,0,.12) 100%);
                height: 100%;
                position: absolute;
                z-index: 4;
                top: 0px;
                right: -8px;
                left: auto;               
                width: 8px;

            }
            > div{
                float: none;
                height: auto;
                h4{

                    span{
                        margin-right: auto !important;
                    }
                    
                }
                &.header-menu{
                    > div{
                        position: relative;
                        border: none;
                        p{
                            text-align: left;
                            font-size: 14px;
                        }                       
                    }
                }
            }    
            
            & + .toggle-settings{
                background: ${PrimaryColor};
            }
        }
    }

    button span:last-child{
        font-size: 14px;
    }

    .header-menu{
        height: 40px;
        h4{
            padding: 0;
        }        
    }  
}     

.header-menu{
    > div{
        button, a{
            padding-left: 0;
            padding-right: 0;
            span{
                margin: 0;
                font-size: 1em;
            }
        }
    }
}

@media(max-width: 1024px){
    .header-menu{
        height: 40px;
        h4{
            padding: 0;
        }
    }
}

@media(min-width: 769px) and (max-width: 1024px){
    .header-menu{
        h4 + div{
            margin-top: 14px;
        }
    }
}
&:after{
    content: "";
    display: block;
    background: linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,.12) 100%);
    height: 8px;
    position: absolute;
    z-index: 4;
    top: 70px;
    right: 0;
    left: 0px;
    animation: .5s showShadow both;
    animation-delay: .5s;
}

@keyframes showShadow{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.pr-0{
    padding-right: 0;
}
`;

export const PriceStyle = styled.div`

.header-button{
    line-height: 6px;
}

.header-button + div{
    border-left: 1px solid rgba(0,0,0,.1);
    margin-left: 20px;
    padding: 15px;
    padding-left: 20px;
    box-sizing: border-box;
    p{
        margin: 0;
        font-size: 13px;
            }
    strong{
        font-size: 26px;
        span{
            font-size: 9px;
            margin-right: 3px;
        }
    }
}
.header-info{
    p{
        display: flex;
        align-items: center;
        svg{
            margin-left: 5px;
            width: 16px;
            height: 16px;
            cursor: pointer;
            &:hover{
                & + .header-info__tooltip{
                    opacity: 1;
                }
            }
        }            
    }
}

.header-info__tooltip{
    display: block;
    position: absolute;
    right: 10px;
    top: 35px;
    background: white;
    padding: 15px;
    z-index: 99;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,.4);
    opacity: 0;
    transition: all .4s linear;
}
`;

export const Buttons = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
height: 100%;
button{
    text-transform: uppercase;
    font-weight: bold;
}
svg{
    fill: ${PrimaryColor} !important;
    width: 16px;
    height: 16px;
}
@media(max-width: 769px){
    display: none;
    width: 100%;
    position: absolute;        
    top: 70px;
    background: white;
    height: 57px;
   
    &.active{
        display: flex;
    }
    & + div{
        position: absolute;
        right: 0;
    }
}
@media(min-width: 769px) and (max-width: 1024px){
    span:nth-child(2), h4 span{
        display: none;
    }
}

@media(min-width: 1024px) and (max-width: 1300px){
    > div, > button{
        flex-direction: column;
        padding: 10px !important;
        svg{
            margin-right: 0;
        }    
        span:nth-child(2){
            margin-top: 5px;
        }    
        h4{
            flex-direction: column-reverse !important;
            justify-content: flex-end !important;
            padding: 10px;
            padding-top: 8px;
            span{
                margin: 0 !important;
                margin-top: 5px !important;
            }
        }
    }

   
}
`;
