import * as React from "react";
import { StyledText } from './Textfield.style';

interface Props{
    title?: string;
    value? : string;
    required?: boolean;
    type?: string;
    min?: number;
    pattern?: string;
    errorMessage?: string;
    id?: string;
    onChange?: (e:string) => void;
}

const TextField = (props:Props) =>
{
    const [changed, updateChange] = React.useState(props.value ? true : false);

    const changeInput = (e:React.SyntheticEvent<HTMLInputElement>) =>
    {
        if(e.currentTarget.value.length > 0)
        {
            updateChange(true);
        }
        else
        {
            updateChange(false);
        }
        if(props.onChange)
        {
            props.onChange(e.currentTarget.value);
        }
    }
  
    const getPattern = () =>
    {
        if(props.pattern)
        {
            return props.pattern;
        }
        switch(props.type)
        {
            case "email": 
            return "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$";
        }
    }
    const preventIosScrollJump = (e:React.FocusEvent<HTMLInputElement>) =>
    {
        if (matchMedia('(hover:none) and (orientation:portrait)').matches) {
            window?.scrollTo(0,0);
            document.querySelector(".sidebar")?.scrollTo(0,0)
        }
    }
    return (
    <StyledText>
        <input required={props.required ? true : false} 
        name={props.id ? props.id : "t0"} type={props.type ? props.type : "text"}  
        //defaultValue={props.value ? props.value : ""} 
        value={props.value}
        minLength={props.min}
        pattern={getPattern()}
        onChange={changeInput}
        onFocus={preventIosScrollJump}
        />
        <label htmlFor={props.id ? props.id : "t0"} className={`${changed && props.value !== "" ? "changed" : "" }`}>{props.title} {props.required ? <span>*</span> : ""}</label>
    </StyledText>
    );
}

export default TextField;